// @ts-nocheck
import {downloadCSV} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import {parseAsync} from 'json2csv';

const ChargesExport = (records) => {
    const dataForExport = records.map(record => {
        const {
            id, processId, processUuid, endLocationName, skuUuid,
            ...dataForExport
        } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field

        return dataForExport;
    });

    const fields = [
        {
            label: 'Charge Id',
            value: 'chargeId'
        }, {
            label: 'Organisation Id',
            value: 'organisationId'
        }, {
            label: 'Organisation Name',
            value: 'organisationName'
        }, {
            label: 'Applicable Date',
            value: 'applicableDate'
        }, {
            label: 'Unit Amount',
            value: 'unitAmount'
        }, {
            label: 'Quantity',
            value: 'quantity'
        }, {
            label: 'Total',
            value: 'total'
        }, {
            label: 'Fee Description',
            value: 'description'
        }, {
            label: 'Cycle Id',
            value: 'cycleId'
        }, {
            label: 'Cycle Start Date',
            value: 'cycleStartDate'
        }, {
            label: 'Cycle End Date',
            value: 'cycleEndDate'
        }, {
            label: 'Keg Serial Number',
            value: 'kegSerialNumber'
        }, {
            label: 'SKU',
            value: 'skuName'
        }, {
            label: 'Cycle End Location',
            value: 'cycleEndLocation'
        }, {
            label: 'Cycle End Role',
            value: 'cycleEndRole'
        }, {
            label: 'Cycle End Region',
            value: 'cycleEndRegion'
        }, {
            label: 'Order Id',
            value: 'orderId'
        }, {
            label: 'Active?',
            value: 'status'
        }];
    const opts = {fields};

    parseAsync(dataForExport, opts)
        .then(csv => {
            let today = new Date();
            downloadCSV(csv, 'Charges_' + today.toISOString().split('T')[0]); // download as 'posts.csv` file
        })
        .catch(err => console.error(err));

};

export default ChargesExport;
