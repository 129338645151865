// @ts-nocheck
import * as React from 'react';

import {
    Box, Button, Container, Typography
}
    from '@material-ui/core';

import LocationPackageList from "../../resources/locations/psg/LocationPackageList";
import ScrollToRightSignal from "../../components/gifs/ScrollToRightSignal";
import Link from "@material-ui/core/Link";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import Divider from "@material-ui/core/Divider";

const LocationPackages = () => {
    // const loading = useLoading();
    let locationPackageProps = {
        basePath: "/locations/psg",
        resource: "locations/psg"
    }

    return (
        <Container>
            <Box m={2}>
                <Typography variant={'h4'} style={{paddingBottom: '10px', paddingLeft: '10px'}}>Location Packages</Typography>
            </Box>
            <Box m={1}>
                <Divider />
            </Box>
            <Box m={1}>
                <ScrollToRightSignal />
            </Box>
            <Box p={2}>

                <LocationPackageList {...locationPackageProps} />

            </Box>
        </Container>
    );
};

export default LocationPackages;
