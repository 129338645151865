// @ts-nocheck
import {downloadCSV} from 'react-admin';
import jsonExport from 'jsonexport/dist';

const OrganisationListingExport = (records) => {

    const dataForExport = records.map(record => {

        const {
            contactFirstName, contactId, contactLastName, marketId, marketName, organisationId, organisationUuid,
            ...dataForExport
        } = record; // omit unecessary fields

        dataForExport.contactName = record.contactFirstName + '' + record.contactLastName; // add a field
        // dataForExport.kegSerialNumber = (record.kegs) ? record.kegs.serialNumber : null;

        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'id', 'organisationName', 'contactName', 'contactPhone', 'rented', 'holding'
        ], // order fields in the export
        rename: [
            'ID', 'Organisation Name', 'Contact Name', 'Contact Number', 'Rented', 'Holding'
        ]
    }, (err, csv) => {
        let today = new Date();
        downloadCSV(csv, 'OrganisationListing_' + today.toISOString().split('T')[0]); // download as 'posts.csv` file
    });

};

export default OrganisationListingExport;
