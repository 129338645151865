// @ts-nocheck
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const CountryPackageExport = (records) => {

    const dataForExport = records.map(record => {
        const {
            id, countryUuid,
            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field

        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'countryId', 'countryName', 'skuName', 'pricingApplicableFrom',
            'pricingApplicableTo', 'issueFee', 'fixedFee', 'dailyRentalFee', 'cleaningFee',	'palletFee',	'freightCapitalMetroFee',	'freightRegionalMetroFee',	'freightRegionalFee',
            'freightSuperRegionalFee',	'freightPickUpFee',	'interCountryFee',	'taproomVenueFee',	'taproomDistributorFee',	'taproomProducerFee',	'collectionRegionalFee',	'collectionSuperRegionalFee',
            'nonMystarFee',	'stopAtDistributorFee',	'expressFee',	'timedFee', 'selfDistributionFixedDiscount'
        ], // order fields in the export
        rename:[
            'Id', 'Name', 'Sku', 'Pricing Applicable From',
            'Pricing Applicable To', 'Issue Fee', 'Fixed Fee', 'Daily Rental Fee', 'Cleaning Fee',	'Pallet Fee',	'Freight Capital Metro Fee',	'Freight Regional Metro Fee',	'Freight Regional Fee',
            'Freight Super Regional Fee',	'Freight Pick Up Fee',	'Inter Country Fee',	'Taproom Venue Fee',	'Taproom Distributor Fee',	'Taproom Producer Fee',	'Collection Regional Fee',	'Collection Super Regional Fee',
            'Non-Mystar Fee',	'Stop At Distributor Fee',	'Express Fee',	'TimedFee', 'Self Distribution Fixed Discount'
        ]
    }, (err, csv) => {
        let today = new Date();
        downloadCSV(csv, 'CountryPackages_'+ today.toISOString().split('T')[0]); // download as 'posts.csv` file
    });

};

export default CountryPackageExport;
