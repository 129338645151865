// @ts-nocheck
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const LocationPackageExport = (records) => {

    const dataForExport = records.map(record => {
        const {
            id, countryId, countryUuid, locationUuid,	organisationUuid,
            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field

        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'organisationId', 'organisationName', 'skuName', 'countryName', 'state', 'isPsaCustomer', 'psaStartDate', 'psaEndDate', 'volumeStartingOffset', 'guaranteedSupply', 'currentDiscount',	'pricingApplicableFrom',
            'pricingApplicableTo', 'isCustomPackage', 'issueFee', 'fixedFee', 'dailyRentalFee', 'cleaningFee',	'palletFee',	'freightCapitalMetroFee',	'freightRegionalMetroFee',	'freightRegionalFee',
            'freightSuperRegionalFee',	'freightPickUpFee',	'interCountryFee',	'taproomVenueFee',	'taproomDistributorFee',	'taproomProducerFee',	'collectionRegionalFee',	'collectionSuperRegionalFee',
            'nonMystarFee',	'stopAtDistributorFee',	'expressFee',	'perKegFreight', 'selfDistributionFixedDiscount',	'timedFee'
        ], // order fields in the export
        rename:[
            'Id', 'Name', 'Sku', 'Country', 'State', 'Psa Customer', 'PSA Start Date', 'PSA End Date', 'Volume Starting Offset', 'Guaranteed Supply', 'Current % Discount',	'Pricing Applicable From',
            'Pricing Applicable To', 'Custom Package', 'Issue Fee', 'Fixed Fee', 'Daily Rental Fee', 'Cleaning Fee',	'Pallet Fee',	'Freight Capital Metro Fee',	'Freight Regional Metro Fee',	'Freight Regional Fee',
            'Freight Super Regional Fee',	'Freight Pick Up Fee',	'Inter Country Fee',	'Taproom Venue Fee',	'Taproom Distributor Fee',	'Taproom Producer Fee',	'Collection Regional Fee',	'Collection Super Regional Fee',
            'Non-Mystar Fee',	'Stop At Distributor Fee',	'Express Fee',	'Per Keg Freight', 'Self Distribution Fixed Discount', 	'TimedFee'
        ]
    }, (err, csv) => {
        let today = new Date();
        downloadCSV(csv, 'LocationPackages_'+ today.toISOString().split('T')[0]); // download as 'posts.csv` file
    });

};

export default LocationPackageExport;
