// @ts-nocheck
import * as React from 'react';
import {useEffect, useState, useContext} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {Box, Grid, Button, CircularProgress, Container, Paper, LinearProgress,
    Typography, Hidden, FormControlLabel, Checkbox}
    from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    Notification,
    SimpleForm,
    useNotify,
    useQuery,
    useGetList,
    TextInput,
    Toolbar,
    ReferenceInput,
    DataProviderContext,
    AutocompleteInput, ReferenceArrayInput, SelectInput, DateInput, SelectArrayInput, ChipField
} from 'react-admin';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import ErrorAlertBox from "../../components/alerts/ErrorAlertBox";
import EmptyAlertBox from "../../components/alerts/EmptyAlertBox";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Link from "@material-ui/core/Link";
import {
    UnauthorisedAccountingAccess,
    unauthorisedAccountingAccess,
    stopAtRoleChoices,
    highlightCurrentPsaDiscount,
    stopAtRoleDisplay,
    GetCountry,
    GetOrganisation, GetLocation, GetSku,
} from "../../utilities/Common";
import Divider from "@material-ui/core/Divider";
import {useFormState} from "react-final-form";
import EditIcon from "@material-ui/icons/Edit";
import EditPSAIcon from "@material-ui/icons/BorderColor";
import SaveIcon from "@material-ui/icons/Save";
import ViewIcon from "@material-ui/icons/Visibility";
import BackIcon from "@material-ui/icons/ArrowBack";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import RefreshIcon from "@material-ui/icons/Refresh";
import { UUIDv4 } from "uuid-v4-validator";

const useStyles = makeStyles(theme => ({
    formRoot: {
        // width: '100%',
        '& .MuiTextInput-root': {
            margin: theme.spacing(1),
            width: '22ch',
        },
    },
    inputTextField: {
        margin: '10px',
        width: '22ch'
    },
    dateFieldsHidden: {
        margin: '10px',
        width: '22ch',
        display: 'none'
    },
    inputTextFieldSmall: {
        margin: '10px',
        width: '16ch'
    },
    inputSelectField: {
        margin: '10px',
        width: '25ch'
    },
    inputBooleanField: {
        margin: '10px',
        width: '20ch'
    },
    chipField: {color: '#fff !important', backgroundColor: '#000 !important'},
    highlightCurrentPsaDiscountRow: {
        backgroundColor: '#FEDB00',
    },
}));



const PackageEdit = () => {
    let {level, uuid, sku} = useParams();

    sku = UUIDv4.validate(sku) ? sku : null;

    // console.log('Package Level', level);
    // console.log('UUID', uuid);
    // console.log('SKU', sku);
    const [edit, setEdit] = useState(false);
    const [editPsa, setEditPsa] = useState(false);
    const [showEndPackages, setShowEndPackages] = useState(true);
    const [endPackageSuccess, setEndPackageSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(new Date().toJSON());
    const [hasErrors, setHasErrors] = useState(false);
    const [submitErrors, setSubmitErrors] = useState(false);
    const [formValid, setFormValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);
    const [noData, setNoData] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successPsa, setSuccessPsa] = useState(false);
    const classes = useStyles();
    const notify = useNotify();
    const [tabValue, setTabValue] = useState(0);
    const [skuList, setSkuList] = useState(null);
    const [packageDetails, setPackageDetails] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [isUpdated, setIsUpdated] = useState(false);
    const [waivedTimedFee, setWaivedTimedFee] = useState(false);
    const [disablePerSku, setDisablePerSku] = useState(true);
    const [changeDailyRentalSetup, setChangeDailyRentalSetup] = useState(false);
    const [skus, setSkus] = useState([]);
    const [customPackages, setCustomPackages] = useState([]);
    const [hasTimedFees, setHasTimedFees] = useState(true);
    const [data, setData] = useState(null);
    const [fixedBased, setFixedBased] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [skuSelected, setSkuSelected] = useState(sku);
    const [checkWaivedTimeFee, setCheckWaivedTimeFee] = useState({});
    const [isStartDateHidden, setIsStartDateHidden] = useState(true);
    const [isEndDateHidden, setIsEndDateHidden] = useState(true);

    let countryUuid = null;
    let organisationUuid = null;
    let locationUuid = null;
    let skuUuid = null;

    const getCurrentLocalDate = () =>{
        const date = new Date().toLocaleDateString('en-GB');
        const dateArr = date.split('/');
        return (dateArr[2] + '-' + dateArr[1] + '-' + dateArr[0]);
    };

    const getPackageUrl = () => {
        let url = '';
        let uuidParameter = 'countryUuid';
        switch (level) {
            case 'countries':
                uuidParameter = 'countryUuid';
                break;
            case 'organisations':
                uuidParameter = 'organisationUuid';
                break;
            case 'locations':
                uuidParameter = 'locationUuid';
                break;
        }
        url = `${process.env.REACT_APP_KEGNOVA_API_URL}/packages/details?${uuidParameter}=${uuid}&checkedDate=${ getCurrentLocalDate() + ' 00:00:00'}`;
        if(sku){
            url = url + `&skuUuid=${sku}`;
        }
        return url;
    }

    const [packageUrl, setPackageUrl] = useState(getPackageUrl);
    const [skusUrl, setSetSkusUrl] = useState(`${process.env.REACT_APP_KEGNOVA_API_URL}/skus?_sort=name&_order=asc`);

    /** Get Package Details **/
    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const result = await axios.get(packageUrl, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')} });
                // console.log(result.data)
                setPackageDetails(result.data);
                setData({
                    issueFee: result.data.packageSetGroup.issueFee
                })
                setFixedBased( result.data.psa ? result.data.psa.fixedDiscount : false )
                setLoading(false);
            } catch (error) {
                // console.log(error.response)
                // notify('Error fetching package details', 'warning');
                setHasErrors(true);
                setErrorMessage('Retrieving package failed. ' + (error.response.length > 0) ? error.response.data.message : '');
                setLoading(false);
            }
        })();
    }, [packageUrl, reload]);

    useEffect(() => { // Get SKUs API
        (async () => {
            try {
                const result = await axios.get(skusUrl, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')} });
                setSkuList(result.data);
                setLoading(false);
            } catch (error) {
                setHasErrors(true);
                setErrorMessage('Retrieving SKU failed. ' + (error.response.length > 0) ? error.response.data.message : '');
                setLoading(false);
            }
        })();
    }, [skusUrl]);

    const HandlePackageErrorDisplay = ({error}) => {
        let severity = 'info';
        let title = 'Error';
        let errorMessage = error.message;
        // Handle error messages
        if(error && error.message.includes('No OrganisationPsaAgreementEntity entity found')) {
            title = 'Warning';
            severity = 'warning';
            errorMessage = 'No PSA Agreement found for this organisation';
        }

        return (
            <Alert severity={severity}>
                <AlertTitle variant={'h6'}> {title} </AlertTitle>
                { errorMessage }
            </Alert>
        )
    }

    const handleSubmitPSA = async (e, formState) => {
        e.preventDefault();
        let form = formState.values;
        let dateToday = (new Date()).toISOString().split('T')[0];
        // console.log('Form PSA Data ', form);

        switch (level) {
            case 'countries':
                countryUuid = uuid;
                break;
            case 'organisations':
                organisationUuid = uuid;
                break;
            case 'locations':
                locationUuid = uuid;
                break;
        }

        if(level !== 'organisations') {
            for(const i in packageDetails.packages) {
                if(packageDetails.packages[i].isOrganisationPackage) {
                    organisationUuid = packageDetails.packages[i].organisationUuid;
                }
            }
        }

        if(!form['psaStartDate']) {
            setFormValid(false);
            setErrorMessage('PSA Start Date required');
            setIsSubmitting(false);
            return false;
        }

        let payload = null;

        if(form['fixedOrVolume']){
            payload = {
                organisationUuid: organisationUuid,
                psaStartDate: form['psaStartDate'],
                psaEndDate: form['psaEndDate'] ? form['psaEndDate'] : null,
                guaranteedSupply: form['guaranteedSupply'] ? parseFloat(form['guaranteedSupply']) : null,
                volumeStartingOffset: form['volumeStartingOffset'] ? parseFloat(form['volumeStartingOffset']) : null,
                fixedDiscount: form['fixedDiscount'] ? parseFloat(form['fixedDiscount']) : 0,
            }
        } else {
            payload = {
                organisationUuid: organisationUuid,
                psaStartDate: form['psaStartDate'],
                psaEndDate: form['psaEndDate'] ? form['psaEndDate'] : null,
                guaranteedSupply: form['guaranteedSupply'] ? parseFloat(form['guaranteedSupply']) : null,
                volumeStartingOffset: form['volumeStartingOffset'] ? parseFloat(form['volumeStartingOffset']) : null,
            }
        }
        // console.log(payload);
        savePsa(payload);

    }

    const savePsa = async (payload) => {
        // setSuccessPsa(false);
        // setErrorMessage(false);
        setLoading(true);
        try {
            let url = `${process.env.REACT_APP_KEGNOVA_API_URL}/packages/psa`;
            const options = {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',
                    'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')}
            };
            const result = await axios.post(url, payload, options);
            setIsUpdated(true);
            setLoading(false);
            setSuccessPsa(true);
            setFormValid(true);
            setErrorMessage(null);
            setIsSubmitting(false);
            setSkus([]);
            // notify('billing.updatepackagesuccessful', 'info');
        } catch (error) {
            // notify('billing.updatepackagefailed', 'warning');
            setIsUpdated(false);
            setErrorMessage('Error updating PSA ' + (error.response.length > 0) ? error.response.data.message : '');
            setLoading(false);
            setIsSubmitting(false);
            setSkus([]);
        }
    }

    const handleSubmit = async (e, formState) => {
        e.preventDefault();
        setIsSubmitting(true);
        // notify('billing.updatingpackagedetails', 'info');
        let form = formState.values;
        let fees = [];
        let dateToday = (new Date()).toISOString().split('T')[0];
        // console.log('Form Data ', form);

        //Keep taproomFixedFee only when all sku are selected
        if(!disablePerSku && "TaproomFixedFee" in form){
            delete form['TaproomFixedFee'];
        }

        // Validation
        if((isSafari && isStartDateHidden) || (!isSafari && !form['startDate'])) {
            setFormValid(false);
            setErrorMessage('Start Date required');
            setIsSubmitting(false);
            return false;
        } else if(form['startDate'] < dateToday) {
            setFormValid(false);
            setErrorMessage('Start Date must be a current or future date');
            setIsSubmitting(false);
            return false;
        }

        if((form['endDate'] && !isEndDateHidden && isSafari) || (!isSafari && form['endDate'])) {
            if(form['endDate'] < dateToday || form['endDate'] < form['startDate']) {
                setFormValid(false);
                setErrorMessage('End Date must be a current or future date and must be greater than the Start Date');
                setIsSubmitting(false);
                return false;
            }
        }

        // if(!form['perKegFreight']){
        //     form['perKegFreight'] = false;
        // }

        for (const row in form) {

            if (row.includes("-")) continue;
            if (row.includes("startDate")) continue;
            if (row.includes("endDate")) continue;
            if (row.includes("perKegFreight")) continue;
            if (row.includes("isDepositApplied")) continue;
            if (row.includes("selfDistributionFixedDiscount")) continue;
            if (row.includes("psaStartDate")) continue;
            if (row.includes("psaEndDate")) continue;
            if (row.includes("volumeStartingOffset")) continue;
            if (row.includes("guaranteedSupply")) continue;
            if (row.includes("orderInvoicePaymentDays")) continue;
            if (row.includes("depositInvoicePaymentDays")) continue;
            if (row.includes("orderPsaInvoicePaymentDays")) continue;
            if (row.includes("monthlyInvoicePaymentDays")) continue;
            if (row.includes("monthlyPsaInvoicePaymentDays")) continue;
            if (row.includes("fixedOrVolume")) continue;
            if (row.includes("fixedDiscount")) continue;
            if (row.includes("dailyRentalFeeSettings")) continue;
            if (row.includes("DailyRentalFee")) continue;
            if (row.includes("dailyRentalFeeSettingsStopAtRole")) continue;
            if (row.includes("dailyRentalFeeSettingsMinimumDays")) continue;
            if (row.includes("dailyRentalFeeSettingsMinDaysRole")) continue;
            if (row.includes("dailyRentalFeeSettingsMaximumDays")) continue;
            if (row.includes("dailyRentalFeeSettingsMaxDaysRole")) continue;
            if (row.includes("skuUuid")) continue;

            if(row.includes("Freight") && !(row.includes("Minimum")) ) {

                if(row.includes("FreightFuelFee")) {
                    fees.push({
                        "name": row,
                        "amount": parseFloat(form[row])
                    });
                } else {

                    let minimumFee = row.charAt(0).toLowerCase() + row.slice(1);

                    fees.push({
                        "name": row,
                        "amount": parseFloat(form[row]),
                        "minimumFee": packageDetails.packageSetGroup[minimumFee+'Settings'] ? parseFloat(packageDetails.packageSetGroup[minimumFee+'Settings'].minimumFee) : 0
                    });
                }

            } else {

                if (row.includes("IssueFee")) {
                    fees.push({
                        "name": row,
                        "amount": parseFloat(form[row]),
                        "selfDistributionFixedDiscount": form['selfDistributionFixedDiscount'] ?  parseFloat(form['selfDistributionFixedDiscount']) : null
                    });
                } else {
                    if(form[row]){
                        fees.push({
                            "name": row,
                            "amount": parseFloat(form[row])
                        });
                    }
                }

            }

        }

        switch (level) {
            case 'countries':
                countryUuid = uuid;
                break;
            case 'organisations':
                organisationUuid = uuid;
                break;
            case 'locations':
                locationUuid = uuid;
                break;
        }

        let enteredTimedFees = [];
        const packageTimedFees = packageDetails.packageSetGroup.timedFeeSettings.timedFeeGroup ? packageDetails.packageSetGroup.timedFeeSettings.timedFeeGroup.timedFees : null;

        for (const tf in packageTimedFees) {
            enteredTimedFees.push({ timedFeeUuid: packageTimedFees[tf].uuid, amount: (form[packageTimedFees[tf].uuid]) ? parseFloat(form[packageTimedFees[tf].uuid]) : 0 })
        }
        let timedFeeAmount = (packageDetails.packageSetGroup.timedFee) ? packageDetails.packageSetGroup.timedFee : 0;
        if(hasTimedFees || waivedTimedFee){
            fees.push({
                "name": "TimedFee",
                "amount": waivedTimedFee ? 0 : parseFloat(timedFeeAmount),
                "stopRole": stopAtRoleDisplay(packageDetails.packageSetGroup.timedFeeSettings.stopAtRole),
                "timedFees": waivedTimedFee ? [] : enteredTimedFees,
                "timedFeeGroupUuid": packageDetails.packageSetGroup.timedFeeSettings.timedFeeGroup.uuid,
                "complimentary": waivedTimedFee
            });
        }

        if(form['DailyRentalFee']) {
            fees.push({
                "name": "DailyRentalFee",
                "amount": form['DailyRentalFee'] ? parseFloat(form['DailyRentalFee']) : 0
            });
        }

        let payload = {
            countryUuid: countryUuid,
            organisationUuid: organisationUuid,
            locationUuid: locationUuid,
            orderUuid: null,
            skuUuid: skuUuid,
            skus: skus,
            startDate: {
                date: form['startDate'],
                timezone_type: 3,
                timezone: localStorage.getItem('timezone')
            },
            endDate: null,
            fees: fees,
            psaDiscountGroupUuid: null,
            invoicePaymentDays: [
                { name: 'OrderInvoicePaymentDays', days: (form['orderInvoicePaymentDays']) ? parseInt(form['orderInvoicePaymentDays']) : null },
                { name: 'OrderPsaInvoicePaymentDays', days: (form['orderPsaInvoicePaymentDays']) ? parseInt(form['orderPsaInvoicePaymentDays']) : null },
                { name: 'DepositInvoicePaymentDays', days: (form['depositInvoicePaymentDays']) ? parseInt(form['depositInvoicePaymentDays']) : null },
                { name: 'MonthlyInvoicePaymentDays', days: (form['monthlyInvoicePaymentDays']) ? parseInt(form['monthlyInvoicePaymentDays']) : null },
                { name: 'MonthlyPsaInvoicePaymentDays', days: (form['monthlyPsaInvoicePaymentDays']) ? parseInt(form['monthlyPsaInvoicePaymentDays']) : null },
            ]
        };

        if ((form['endDate'] && !isEndDateHidden && isSafari) || (!isSafari && form['endDate'])) {
            payload = {...payload, endDate: { date: form['endDate'], timezone_type: 3, timezone: localStorage.getItem('timezone')}};
        }
        if (level === 'organisations' && form['psaStartDate']) {
            payload = {...payload, organisationPsaAgreement: { psaStartDate: (form['psaStartDate']) ? form['psaStartDate'] : null, psaEndDate: (form['psaEndDate']) ? form['psaEndDate'] : null,
                    fixedDiscount: (form['fixedDiscount']) ? parseFloat(form['fixedDiscount']) : 0,
                    guaranteedSupply: (form['guaranteedSupply']) ? parseInt(form['guaranteedSupply']) : 0,
                    volumeStartingOffset: (form['volumeStartingOffset']) ? parseInt(form['volumeStartingOffset']) : 0 } };
            }

        if (form['perKegFreight']) {
            payload = {...payload, perKegFreight: form['perKegFreight']};
        } else {
            payload = {...payload, perKegFreight: false};
        }

        if (form['isDepositApplied']) {
            payload = {...payload, isDepositApplied: form['isDepositApplied']};
        } else {
            payload = {...payload, isDepositApplied: false};
        }

        if(form['dailyRentalFeeSettingsStopAtRole'] && form['dailyRentalFeeSettingsMaximumDays']) {
            payload = {...payload, dailyRentalSettings: {
                    stopRole:  form['dailyRentalFeeSettingsStopAtRole'] ? form['dailyRentalFeeSettingsStopAtRole'] : null,
                    maximumDays: form['dailyRentalFeeSettingsMaximumDays'] ? parseFloat(form['dailyRentalFeeSettingsMaximumDays']) : 0,
                    minimumDays: form['dailyRentalFeeSettingsMinimumDays'] ? parseFloat(form['dailyRentalFeeSettingsMinimumDays']) : 0,
                    maxDaysRole: form['dailyRentalFeeSettingsMaxDaysRole'] ? form['dailyRentalFeeSettingsMaxDaysRole'] : null,
                    minDaysRole: form['dailyRentalFeeSettingsMinDaysRole'] ? form['dailyRentalFeeSettingsMinDaysRole'] : null,
                }};
        } else {
            payload = {...payload, dailyRentalSettings: null};
        }

        if (checkWaivedTimeFee) {
            payload = {...payload, waivedTimedFees: checkWaivedTimeFee};
        }

        savePackage(payload);
    }

    const savePackage = async (payload) => {
        // setSuccess(false);
        // setErrorMessage(false);
        setLoading(true);
        try {
            let url = `${process.env.REACT_APP_KEGNOVA_API_URL}/packages`;
            const options = {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',
                    'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')}
            };
            const result = await axios.post(url, payload, options);
            setIsUpdated(true);
            setLoading(false);
            setSuccess(true);
            setFormValid(true);
            setErrorMessage(null);
            setIsSubmitting(false);
            setSkus([]);
            // notify('billing.updatepackagesuccessful', 'info');
        } catch (error) {
            // notify('billing.updatepackagefailed', 'warning');
            setIsUpdated(false);
            setErrorMessage('Error updating package' + (error.response.length > 0) ? error.response.data.message : '');
            setLoading(false);
            setIsSubmitting(false);
            setSkus([]);
        }
    }

    const handleEndCustomPackageSubmit = async (e, formState, continueOn) => {
        e.preventDefault();
        setIsSubmitting(true);
        // console.log('endCustomPackage Form Data ', form);
        // console.log(customPackages);
        let payload = customPackages;

        if(continueOn) {
            await endCustomPackageAndContinue(payload);
        } else {
            await endCustomPackage(payload);
        }

    }

    const endCustomPackage = async (payload, continueOn = false) => {
        // console.log('endCustomPackage');
        setLoading(true);
        try {
            let url = `${process.env.REACT_APP_KEGNOVA_API_URL}/packages/end`;
            const options = {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',
                    'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')}
            };
            const result = await axios.post(url, payload, options);
            setLoading(false);
            setEndPackageSuccess(true);
            if(continueOn) {
                setShowEndPackages(false);
            }
            setTimeout(function(){ setEndPackageSuccess(false); }, 5000);
            setIsSubmitting(false);
        } catch (error) {
            setLoading(false);
            setEndPackageSuccess(false);
            setShowEndPackages(true);
            setErrorMessage('Error updating package' + (error.response.length > 0) ? error.response.data.message : '');
            setIsSubmitting(false);
        }
    }

    const endCustomPackageAndContinue = async (payload) => {
        // console.log('endCustomPackageAndContinue');
        await endCustomPackage(payload, true);
    }

    const validateForm = (values: FormValues) => {
        const errors: FormValues = {};
        // if (!values.username) {
        //     errors.username = translate('ra.validation.required');
        // }
        return errors;
    };


    const handleWaivedTimedFee = (event) => {
        let id = event.target.id;
        let name = event.target.name;
        let value = event.target.checked;
        const { checked } = event.target;
        setCheckWaivedTimeFee((values) => ({
            ...values,
            [id]: checked
        }));
        setWaivedTimedFee(value);
    };
    const handleChangeDailyRentalSetup = (event) => {
        let value = event.target.checked;
        setChangeDailyRentalSetup(value);
    };
    const handleSkuChange = (value) => {
        setEdit(false);
        setEditPsa(false);
        setSkuSelected(value);
        let url = getPackageUrl();
        if(value){
            setPackageUrl(url+ `&skuUuid=${value}`);
        } else {
            setPackageUrl(url);
        }
    };

    const handleSkuCheckboxChange = (element) => {
        // console.log(element.target.name, element.target.checked, element.target.value);

        let skuValue = element.target.value;
        let selectedSkus = [];
        selectedSkus = skus;

        if(element.target.name === 'skuAll' && element.target.checked){
            // setSkuState([]);
            setDisablePerSku(true);
            setSkus([]);
        } else {

            setDisablePerSku(false);

        }

    };

    const handleSkuSelectChange = (element) => {
        // console.log(element.target.value);
        setSkus(element.target.value);
    }

    const handleEndPackageCheckbox = (element, uuid) => {
        // console.log(uuid);
        // console.log(element.target.name, element.target.checked, element.target.value);
        let packages = customPackages;

        for (const i in packages) {
            if(packages[i].uuid == uuid) {
                if(element.target.name.includes("startDate")){
                    packages[i].startDate = element.target.value;
                }
                if(element.target.name.includes("endDate")){
                    packages[i].endDate = element.target.value;
                }
                if(element.target.name.includes("isDeleted")){
                    packages[i].isDeleted = element.target.checked;
                }
            }
        }
    };

    const handleTimedFeeChange = (element) => {
        // console.log(element.target.name, element.target.checked, element.target.value);
        if(element.target.value) {
            setHasTimedFees(true);
        } else {
            setHasTimedFees(true)
        }
    };

    const getStopAtRoleDisplay = (stopAtRole) => {
        let stopAtRoleDisplay = '-';
        switch (stopAtRole) {
            case 1:
                stopAtRoleDisplay = 'Warehouse'
                break;
            case 2:
                stopAtRoleDisplay = 'Producer'
                break;
            case 3:
                stopAtRoleDisplay = 'Distributor'
                break;
            case 4:
                stopAtRoleDisplay = 'Venue'
                break;

        }

        return stopAtRoleDisplay;
    }

    const EditPackageToolbar = ({classes, ...props}) => {
        const formState = useFormState();
        return(
            <Toolbar {...props} style={{margin: '0px', width: '95.75%'}}>
                <Box>
                    <Button variant={'contained'} onClick={(e)=>handleSubmit(e, formState)} disabled={isSubmitting}><SaveIcon />&nbsp; Save Package</Button>
                </Box>
            </Toolbar>
        )
    };

    const EditPSAToolbar = ({classes, ...props}) => {
        const formState = useFormState();
        return(
            <Toolbar {...props} style={{margin: '0px', width: '95.75%'}}>
                <Box>
                    <Button variant={'contained'} onClick={(e)=>handleSubmitPSA(e, formState)} disabled={isSubmitting}><SaveIcon />&nbsp; Edit PSA</Button>
                </Box>
            </Toolbar>
        )
    };
    const EndCustomPackageToolbar = ({classes, ...props}) => {
        const formState = useFormState();
        return(
            <Toolbar {...props} style={{margin: '0px', width: '95.75%'}}>
                <Box m={1} display={'flex'}>
                    <Box m={1}>
                        <Button variant={'contained'} onClick={(e)=>handleEndCustomPackageSubmit(e, formState, false)} disabled={isSubmitting} >Update</Button>
                    </Box>
                    <Box m={1}>
                        <Button variant={'contained'} onClick={(e)=>handleEndCustomPackageSubmit(e, formState, true)} disabled={isSubmitting} >Update and Continue</Button>
                    </Box>
                </Box>
            </Toolbar>
        )
    };

    const refreshPage = () => {
        setLoading(true);
        setReload((new Date()).toJSON());
        setTimeout(function(){ setLoading(false); }, 1000);
    }

    const getPackageLevelName = () => {
        let levelDisplay = '';
        if(level === 'countries') {
            levelDisplay = 'Country';
        }
        if(level === 'organisations') {
            levelDisplay = 'Organisation';
        }
        if(level === 'locations') {
            levelDisplay = 'Location';
        }
        return levelDisplay;
    }

    const GetPackageLevelTitle = () => {

        return (
            <Typography variant={'h4'}>
                { edit ? 'Edit ' : 'View '} {getPackageLevelName()} Package
            </Typography>
        )
    };

    const GetPsaDiscountEdit = ({uuid}) => {
        let organisationUuid = uuid;

        if(level !== 'organisations') {
            for(const i in packageDetails.packages) {
                if(packageDetails.packages[i].isOrganisationPackage) {
                    organisationUuid = packageDetails.packages[i].organisationUuid;
                }
            }
        }

        const dataProvider = useContext(DataProviderContext);
        const [data, setData] = useState();
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState();
        useEffect(() => {
            dataProvider.getOne('organisations', {id: `psa-agreement?organisationUuid=${organisationUuid}`})
                .then((response) => {
                    setData(response.data[0]);
                    setLoading(false);
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                })
        }, []);

        // const { data, loading, error } = useQuery({
        //     type: 'getOne',
        //     resource: 'organisations/psa-agreement',
        //     payload: { id: organisationUuid }
        // });

        if (loading) return <CircularProgress />;
        if (error) return <HandlePackageErrorDisplay error={error} />;
        if (!data) return null;

        return (
            <React.Fragment>
                <Box display={'flex'}>
                    <Box m={1}>
                        <Typography variant={'body1'}>PSA Customer?</Typography>
                    </Box>
                    <Box m={1}>
                        <Typography variant={'body1'} style={{fontWeight: 'bold', paddingRight: '20px'}}>{ data.PSACustomer }</Typography>
                    </Box>
                    <Box m={1}>
                        <Typography variant={'body1'}>Current Discount %</Typography>
                    </Box>
                    <Box m={1}>
                        <Typography variant={'body1'} style={{fontWeight: 'bold', paddingRight: '20px'}}><GetCurrentDiscountPercentage organisationUuid={uuid} skuUuid={sku} /></Typography>
                    </Box>
                </Box>
                <Box display={'flex'}>

                    <Box>
                        <DateInput source="psaStartDate" className={classes.inputTextField} label={'PSA Start Date'} defaultValue={data.psaStartDate} />
                    </Box>
                    <Box>
                        <DateInput source="psaEndDate" className={classes.inputTextField} label={'PSA End Date'} defaultValue={data.psaEndDate} />
                    </Box>

                </Box>
                <Box display={'flex'}>
                    <Box>
                        <SelectInput name="fixedOrVolume" label="Volume/Fixed" defaultValue={ !!(fixedBased) } choices={[
                            { id: false, name: 'Volume' },
                            { id: true, name: 'Fixed' }
                        ]} className={classes.inputTextField} onChange={handleFixedOrVolume} />
                    </Box>

                    { fixedBased ?
                        <Box>
                            <TextInput className={classes.inputTextField} name="fixedDiscount" label="Fixed Discount" type="number"
                                       InputLabelProps={{shrink: true,}} variant="filled" defaultValue={data.fixedDiscount}
                                       format={v => parseFloat(Math.abs(v))} />
                        </Box>
                        :
                        <React.Fragment>
                            <Box>
                                <TextInput className={classes.inputTextField} name="volumeStartingOffset" label="Contracted Volume" type="number"
                                           InputLabelProps={{shrink: true, min: 0}} variant="filled" defaultValue={ data.volumeStartingOffset }
                                           format={v => parseFloat(Math.abs(v))} />
                            </Box>
                            <Box>
                                <TextInput className={classes.inputTextField} name="guaranteedSupply" label="Guaranteed Supply" type="number"
                                           InputLabelProps={{shrink: true, min: 0}} variant="filled" defaultValue={ data.guaranteedSupply }
                                           format={v => parseFloat(Math.abs(v))} />
                            </Box>
                        </React.Fragment>
                    }

                </Box>
            </React.Fragment>
        )
    };

    const handleFixedOrVolume = (e) => {
        setFixedBased(e.target.value);
    }

    const GetPsaDiscountView = ({uuid}) => {
        let organisationUuid = uuid;

        if(level !== 'organisations') {
            for(const i in packageDetails.packages) {
                if(packageDetails.packages[i].isOrganisationPackage) {
                    organisationUuid = packageDetails.packages[i].organisationUuid;
                }
            }
        }

        const dataProvider = useContext(DataProviderContext);
        const [data, setData] = useState();
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState();
        useEffect(() => {
            dataProvider.getOne('organisations', {id: `psa-agreement?organisationUuid=${organisationUuid}`})
                .then((response) => {
                    setData(response.data[0]);
                    setLoading(false);
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                })
        }, []);

        // const { data, loading, error } = useQuery({
        //     type: 'getOne',
        //     resource: 'organisations',
        //     payload: { id: `psa-agreement?organisationUuid=${organisationUuid}` }
        // });

        if (loading) return <CircularProgress />;
        if (error) return  <HandlePackageErrorDisplay error={error} />;
        if (!data) return null;

        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={3}><Paper>
                        <Box m={1}><Typography variant={'caption'}>PSA Customer?</Typography></Box>
                        <Box m={1}><Typography>{ data.PSACustomer }</Typography></Box>
                    </Paper></Grid>
                    <Grid item xs={3}><Paper>
                        <Box m={1}><Typography variant={'caption'}>Fixed/Volume Based</Typography></Box>
                        <Box m={1}><Typography>{ data.psaDiscountUuid ? 'Fixed' : 'Volume' }</Typography></Box>
                    </Paper></Grid>
                    <Grid item xs={3}><Paper>
                        <Box m={1}><Typography variant={'caption'}>PSA Start Date</Typography></Box>
                        <Box m={1}><Typography>{new Date(data.psaStartDate).toLocaleDateString('en-GB')}</Typography></Box>
                    </Paper></Grid>
                    <Grid item xs={3}><Paper>
                        <Box m={1}><Typography variant={'caption'}>PSA End Date</Typography></Box>
                        <Box m={1}><Typography> { data.psaEndDate ? new Date(data.psaEndDate).toLocaleDateString('en-GB') : '-'}</Typography></Box>
                    </Paper></Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}><Paper>
                        <Box m={1}><Typography variant={'caption'}>Contracted Volume</Typography></Box>
                        <Box m={1}><Typography>{ data.volumeStartingOffset ? data.volumeStartingOffset : '-' }</Typography></Box>
                    </Paper></Grid>
                    <Grid item xs={3}><Paper>
                        <Box m={1}><Typography variant={'caption'}>Guaranteed Supply</Typography></Box>
                        <Box m={1}><Typography>{ data.guaranteedSupply ? data.guaranteedSupply : '-' }</Typography></Box>
                    </Paper></Grid>
                    <Grid item xs={3}><Paper>
                        <Box m={1}><Typography variant={'caption'}>Current Discount %</Typography></Box>
                        <Box m={1}><Typography> <GetCurrentDiscountPercentage organisationUuid={organisationUuid} skuUuid={sku} /> </Typography></Box>
                    </Paper></Grid>
                    <Grid item xs={3}><Paper>
                        <Box m={1}><Typography variant={'caption'}>Last Quarter Volume</Typography></Box>
                        <Box m={1}><Typography>{ data.lastQuarterVolume ? data.lastQuarterVolume : '-' }</Typography></Box>
                    </Paper></Grid>
                </Grid>
            </React.Fragment>
        )
    };

    const GetCurrentDiscountPercentage = ({organisationUuid, skuUuid}) => {

        const filter = skuUuid ? {organisationUuid: organisationUuid, skuUuid: skuUuid} : {organisationUuid: organisationUuid}

        const {data, ids, loading, error} = useGetList(
            `organisations/psg`,
            {page: 1, perPage: 25},
            {field: 'id', order: 'desc'},
            filter
        );

        if (loading) return <Box m={2}><LinearProgress /></Box>;
        if (error) return  "Error";
        if (!data) return null;
        const records = Object.values(data);
        const currentDiscount = records[0] ? records[0].currentDiscount : null;
        return currentDiscount ? currentDiscount : '-';
    }

    const GetEndCustomPackages = () => {

        let filter = { organisationUuid: uuid };
        if (level == 'locations'){
            filter = { locationUuid: uuid };
        }
        const { data, ids, loading, error } = useGetList(
            'packages',
            { page: 1, perPage: 25 },
            { field: 'id', order: 'desc' },
             filter
        );

        if (loading) { return <Box m={1}><LinearProgress /></Box>; }
        if (error) { return <p>ERROR</p>; }
        const records = Object.values(data);

        if(records.length == 0) {
            setShowEndPackages(false);
            return null;
        }

        let packages = [];
        for (const i in records) {
            packages.push({uuid: records[i].uuid,
                startDate: new Date(records[i].startDate).toISOString().split('T')[0],
                endDate: (records[i].endDate ? (new Date(new Date(records[i].endDate).getTime() + 60000).toISOString().split('T')[0]) : new Date(records[i].endDate).toISOString().split('T')[0]),
                isDeleted: false})
        }

        if (customPackages.length !== packages.length ) {
            setCustomPackages(packages);
        }

        return (
            <React.Fragment>
                <Box m={1}><Box m={3} /><Typography variant={'h5'}>End Custom Pricing Packages</Typography></Box><Box m={1}><Divider/></Box><Box m={2} />
                <Box m={1} display={'inline'}>
                    <SimpleForm onSubmit={handleEndCustomPackageSubmit} toolbar={<EndCustomPackageToolbar classes={classes}/>} >
                        <Box m={1} display={'inline'}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {(level == 'countries') &&
                                        <TableCell>
                                            <Typography variant={'subtitle1'}>Country</Typography>
                                        </TableCell>
                                        }
                                        {(level == 'organisations') &&
                                        <TableCell>
                                            <Typography variant={'subtitle1'}>Organisation</Typography>
                                        </TableCell>
                                        }
                                        {(level == 'locations') &&
                                        <TableCell>
                                            <Typography variant={'subtitle1'}>Location</Typography>
                                        </TableCell>
                                        }
                                        <TableCell>
                                            <Typography variant={'subtitle1'}>SKU</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={'subtitle1'}>Start Date</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={'subtitle1'}>End Date</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={'subtitle1'}>Delete?</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { records.length == 0 ?
                                        <TableRow>
                                            <TableCell>
                                                No custom packages.
                                            </TableCell>
                                        </TableRow>
                                        :
                                        records.map(
                                            item =>
                                                (
                                                    <TableRow key={item.id}>

                                                        {(level == 'countries') &&
                                                        <TableCell>
                                                            {item.countryUuid ?  <GetCountry uuid={item.countryUuid}/> : '-'}
                                                        </TableCell>
                                                        }

                                                        {(level == 'organisations') &&
                                                        <TableCell>
                                                            {item.organisationUuid ?  <GetOrganisation uuid={item.organisationUuid}/> : '-'}
                                                        </TableCell>
                                                        }

                                                        {(level == 'locations') &&
                                                        <TableCell>
                                                            { item.locationUuid ? <GetLocation uuid={item.locationUuid}/> : '-' }
                                                        </TableCell>
                                                        }

                                                        <TableCell >
                                                            { item.skuUuid ? <GetSku uuid={item.skuUuid} /> : 'ALL'}
                                                        </TableCell>
                                                        <TableCell>
                                                            <DateInput name={`startDate_${item.displayId}`} source="startDate" label={''} defaultValue={item.startDate} onChange={(e)=>handleEndPackageCheckbox(e, item.uuid)}
                                                                       InputProps={{
                                                                           readOnly: !canEditEndCustomPackageStartDate(item),
                                                                       }}/>
                                                        </TableCell>
                                                        <TableCell>
                                                            <DateInput name={`endDate_${item.displayId}`} source="endDate" label={''} defaultValue={item.endDate ? new Date(new Date(item.endDate).getTime() + 60000) : null} onChange={(e)=>handleEndPackageCheckbox(e, item.uuid)}
                                                                       InputProps={{
                                                                           readOnly: !canEditEndCustomPackageEndDate(item),
                                                                       }}/>
                                                        </TableCell>
                                                        <TableCell>
                                                            { canDeleteEndCustomPackage(item) ?
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            name={`isDeleted_${item.displayId}`}
                                                                            color="primary"
                                                                            onChange={(e)=>handleEndPackageCheckbox(e, item.uuid)}
                                                                        />
                                                                    }
                                                                    label={null}
                                                                />
                                                                : null
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </SimpleForm>
                </Box>
            </React.Fragment>
        );
    };
    const canEditEndCustomPackageStartDate = (item) => {
        let dateToday = (new Date()).toISOString().split('T')[0];
        let startDate = (new Date(item.startDate)).toISOString().split('T')[0];
        let endDate = item.endDate ? (new Date(item.endDate)).toISOString().split('T')[0] : null;
        if (startDate >= dateToday) {
            return true;
        }
        if ( startDate >= dateToday && endDate >= dateToday) {
            return true;
        }

        return false;
    }
    const canEditEndCustomPackageEndDate = (item) => {
        let dateToday = (new Date()).toISOString().split('T')[0];
        let startDate = (new Date(item.startDate)).toISOString().split('T')[0];
        let endDate = item.endDate ? (new Date(item.endDate)).toISOString().split('T')[0] : null;

        if (endDate === null) {
            return true;
        } else if (startDate >= dateToday) {
            return true;
        } else if (endDate >= dateToday) {
            return true;
        } else {
            return false;
        }

    }
    const canDeleteEndCustomPackage = (item) => {
        let dateToday = (new Date()).toISOString().split('T')[0];
        let startDate = (new Date(item.startDate)).toISOString().split('T')[0];
        let endDate = item.endDate ? (new Date(item.endDate)).toISOString().split('T')[0] : null;
        if ( (startDate >= dateToday && endDate >= dateToday) || ( startDate >= dateToday && endDate == null) ) {
            return true;
        }
        return false;
    }

    const GetSubHeading = ({uuid}) => {
        let title = '';
        let packageLevelValue = null;

        if (level==='countries') {
            title = 'Country';
            packageLevelValue = <GetCountry uuid={uuid} />
        } else if (level==='organisations') {
            title = 'Organisation';
            packageLevelValue =  <GetOrganisation uuid={uuid} />
        } else if (level==='locations'){
            title = 'Location';
            packageLevelValue =  <GetLocation uuid={uuid} />
        }

        return (
            <React.Fragment>
                <Typography variant={'body1'} style={{fontWeight: 'bold'}}>{ title } <DoubleArrowIcon  style={{fontSize: '12px', paddingLeft: '5px', paddingRight: '5px', margin: 0}} /> { packageLevelValue }</Typography>
            </React.Fragment>
        )

    }

    // Check if user is allowed to access this accounting page
    if ( unauthorisedAccountingAccess() ) {
        return (
            <UnauthorisedAccountingAccess />
        )
    }

    if (hasErrors) {
        return (
            <ErrorAlertBox errorMessage={errorMessage} />
        )
    }
    if (noData) {
        return (
            <EmptyAlertBox message={'Package details not found.'} />
        )
    }

    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

    const updateIsStartDateHidden = () =>{
        setIsStartDateHidden(false);
    };

    const updateIsEndDateHidden = () =>{
        setIsEndDateHidden(false);
    };

    if(!data && !packageDetails) {
        return (
            <Container>
                <Box p={2} component="div">
                    <LinearProgress />
                </Box>
            </Container>
        );
    }

    let packageUpdatedBy = "";
    let packageUpdatedOn = "";
    for(const j in packageDetails.packages) {
        if(j == 0) {
            packageUpdatedBy = packageDetails.packages[j].updatedByUserName;
            packageUpdatedOn = packageDetails.packages[j].modifiedAt;
        } else if(packageUpdatedOn <= packageDetails.packages[j].modifiedAt) {
            packageUpdatedBy = packageDetails.packages[j].updatedByUserName;
            packageUpdatedOn = packageDetails.packages[j].modifiedAt;
        }
    }

    return (
        <Container>
            <Box p={2}>
                <Box m={1}>
                    <GetPackageLevelTitle />
                </Box>
                <Box m={1}>
                    <Divider />
                </Box>
                { loading &&
                <Box m={1}>
                    <LinearProgress />
                </Box>
                }

                <Box display={'flex'} m={1}>
                    <Box flexGrow={1} >
                        <Box display={'flex'}>
                            <GetSubHeading uuid={uuid} />
                        </Box>
                    </Box>

                    <Box m={1}>
                        <Button variant={'contained'} onClick={refreshPage}><RefreshIcon /></Button> &nbsp;
                        <Link href={`/#/billing/packages/${level}`} style={{paddingRight: '10px'}}>
                            <Button variant={'contained'}><BackIcon /> &nbsp; Package List</Button>
                        </Link>
                        { !edit ? <Button variant={'contained'} onClick={()=>{ setEdit(true); setShowEndPackages(true); setEditPsa(false); }}><EditIcon /> &nbsp; Edit Package</Button> : <Button variant={'contained'} onClick={()=>{ setEdit(false); setEditPsa(false); setShowEndPackages(false); }}><ViewIcon /> &nbsp; View Package</Button> }
                        &nbsp; &nbsp;
                        { !editPsa ? <Button variant={'contained'} onClick={()=>{ setEditPsa(true); setEdit(false); setShowEndPackages(false); }}><EditPSAIcon /> &nbsp; Edit PSA </Button> : <Button variant={'contained'} onClick={()=>{ setEdit(false); setEditPsa(false); setShowEndPackages(false); }}><ViewIcon /> &nbsp; View Package</Button> }
                    </Box>
                </Box>

                {/**************************************** VIEW PACKAGE SECTION ****************************************/}

                { !loading && !edit && !(editPsa) &&
                    <Box m={1}>
                        <Box display={'inline'}>
                            <Paper>
                                <SimpleForm toolbar={false} style={{padding: '0px', margin: '0px'}}>
                                    <ReferenceInput source="skuUuid" reference="skus" label={'All SKUs'}
                                                    defaultValue={skuSelected}
                                                    onChange={handleSkuChange} style={{padding: '0px', margin: '0px'}} allowEmpty emptyText={'ALL'} resettable>
                                        <AutocompleteInput optionText="name" />
                                    </ReferenceInput>
                                </SimpleForm>
                            </Paper>
                        </Box>
                        <Box m={1}>
                            <Box m={3}/>
                                <Box display={'flex'} m={1}>
                                    <Box flexGrow={1} >
                                        <Typography variant={'h5'}>Pricing</Typography>
                                    </Box>
                                    <Box m={1}>
                                        {packageUpdatedBy &&
                                            (<Typography>Last Updated By : {packageUpdatedBy}</Typography>)
                                        }
                                        {packageUpdatedOn &&
                                            (<Typography>Last Updated On : {new Date(packageUpdatedOn).toLocaleString()}</Typography>)
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        <Box m={1}><Divider/></Box><Box m={2} />
                        <Grid container spacing={2} display={'flex'}>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Issue fee</Typography></Box>
                                <Box m={1}><Typography> { packageDetails.packageSetGroup.issueFee ? packageDetails.packageSetGroup.issueFee : '-'} </Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Self Distribution Fixed Discount</Typography></Box>
                                <Box m={1}><Typography> { packageDetails.packageSetGroup.selfDistributionFixedDiscount ? packageDetails.packageSetGroup.selfDistributionFixedDiscount : '-'} </Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Daily Rental Fee</Typography></Box>
                                <Box m={1}><Typography> { packageDetails.packageSetGroup.dailyRentalFee ? packageDetails.packageSetGroup.dailyRentalFee : '-'} </Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Cleaning Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.cleaningFee ? packageDetails.packageSetGroup.cleaningFee : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Cleaning Surcharge Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.cleaningSurchargeFee ? packageDetails.packageSetGroup.cleaningSurchargeFee : '-'}</Typography></Box>
                            </Paper></Grid>

                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Missed Scan Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.missedScanFee ? packageDetails.packageSetGroup.missedScanFee : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Taproom Producer Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.taproomProducerFee ? packageDetails.packageSetGroup.taproomProducerFee : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Taproom Venue Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.taproomVenueFee ? packageDetails.packageSetGroup.taproomVenueFee : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Taproom to Distributor Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.taproomDistributorFee ? packageDetails.packageSetGroup.taproomDistributorFee : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Freight Capital Metro Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.freightCapitalMetroFee ? packageDetails.packageSetGroup.freightCapitalMetroFee : '-'}</Typography></Box>
                            </Paper></Grid>


                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Freight Regional Metro Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.freightRegionalMetroFee ? packageDetails.packageSetGroup.freightRegionalMetroFee : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Freight Regional Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.freightRegionalFee ? packageDetails.packageSetGroup.freightRegionalFee : '-'}</Typography></Box>
                            </Paper></Grid>

                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Freight Super Regional Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.freightSuperRegionalFee ? packageDetails.packageSetGroup.freightSuperRegionalFee : '-' }</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Freight Pickup Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.freightPickUpFee ? packageDetails.packageSetGroup.freightPickUpFee : '-'}</Typography></Box>
                            </Paper></Grid>
                            {['organisations'].includes(level) &&
                                <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Freight Fee - Fuel Levy %</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.freightFuelFee ? packageDetails.packageSetGroup.freightFuelFee : '-'}</Typography></Box>
                                </Paper></Grid>
                            }
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Pallet Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.palletFee ? packageDetails.packageSetGroup.palletFee : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Next Day Delivery Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.expressFee ? packageDetails.packageSetGroup.expressFee : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Non Mystar Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.nonMystarFee ? packageDetails.packageSetGroup.nonMystarFee : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Stop At Distributor Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.stopAtDistributorFee ? packageDetails.packageSetGroup.stopAtDistributorFee : '-'}</Typography></Box>
                            </Paper></Grid>

                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Inter Country Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.interCountryFee ? packageDetails.packageSetGroup.interCountryFee : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Regional Collection Fees</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.collectionRegionalFee ? packageDetails.packageSetGroup.collectionRegionalFee : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Super Regional Collection Fees</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.collectionSuperRegionalFee ? packageDetails.packageSetGroup.collectionSuperRegionalFee : '-'}</Typography></Box>
                            </Paper></Grid>

                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Fixed Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.fixedFee ? packageDetails.packageSetGroup.fixedFee : '-'}</Typography></Box>
                            </Paper></Grid>

                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Freight Per Keg</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.perKegFreight ? 'Yes' : 'No'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Deposit Applied?</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.isDepositApplied ? 'Yes' : 'No'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Deposit Per Keg Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.depositFee ? packageDetails.packageSetGroup.depositFee : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Collection Rebate Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.collectionRebateFee ? packageDetails.packageSetGroup.collectionRebateFee : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Monthly Rental Fee</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.monthlyRentalFee ? packageDetails.packageSetGroup.monthlyRentalFee : '-'}</Typography></Box>
                            </Paper></Grid>
                            {!skuSelected && ['organisations', 'countries'].includes(level) &&
                                <Grid item xs={3}><Paper>
                                    <Box m={1}><Typography variant={'caption'}>Taproom Fixed Fee</Typography></Box>
                                    <Box
                                        m={1}><Typography>{packageDetails.packageSetGroup.taproomFixedFee ? packageDetails.packageSetGroup.taproomFixedFee : '-'}</Typography></Box>
                                </Paper></Grid>
                            }
                        </Grid>

                        <Box m={1}><Box m={3} /><Typography variant={'h5'}>Timed Fees</Typography></Box><Box m={1}><Divider/></Box><Box m={2} />
                        <Grid container spacing={2}>
                            { packageDetails.packageSetGroup.timedFeeSettings &&
                            packageDetails.packageSetGroup.timedFeeSettings.timedFeeGroup.timedFees.map((row, key) => (
                                <Grid item xs={3}><Paper>
                                    <Box m={1}><Typography variant={'caption'}>{`${row.billedAtDay} Days`}</Typography></Box>
                                    <Box m={1}><Typography>{ row.amount ? row.amount : '-'}</Typography></Box>
                                </Paper></Grid>
                            ))
                            }
                        </Grid>

                        <Box m={1}><Box m={3} /><Typography variant={'h5'}>Payment Terms</Typography></Box><Box m={1}><Divider/></Box><Box m={2} />
                        <Grid container spacing={2}>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Order Invoice - Non-PSA</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.orderInvoicePaymentDays ? packageDetails.packageSetGroup.orderInvoicePaymentDays : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Order Invoice - PSA</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.orderPsaInvoicePaymentDays ? packageDetails.packageSetGroup.orderPsaInvoicePaymentDays : '-'}</Typography></Box>
                            </Paper></Grid>

                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Deposit Invoice</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.depositInvoicePaymentDays ? packageDetails.packageSetGroup.depositInvoicePaymentDays : '-'}</Typography></Box>
                            </Paper></Grid>

                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Monthly Invoice - Non-PSA</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.monthlyInvoicePaymentDays ? packageDetails.packageSetGroup.monthlyInvoicePaymentDays : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Monthly Invoice - PSA</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.monthlyPsaInvoicePaymentDays ? packageDetails.packageSetGroup.monthlyPsaInvoicePaymentDays : '-'}</Typography></Box>
                            </Paper></Grid>
                        </Grid>

                        <Box m={1}><Box m={3} /><Typography variant={'h5'}>Daily Rental Setup</Typography></Box><Box m={1}><Divider/></Box><Box m={2} />
                        <Grid container spacing={2}>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Daily Rental Stop At</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.dailyRentalFeeSettings ? getStopAtRoleDisplay( packageDetails.packageSetGroup.dailyRentalFeeSettings.stopAtRole ) : '-'}&nbsp;</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Minimum Days</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.dailyRentalFeeSettings ? packageDetails.packageSetGroup.dailyRentalFeeSettings.minimumDays : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Minimum Days Applicable Role</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.dailyRentalFeeSettings ? getStopAtRoleDisplay(packageDetails.packageSetGroup.dailyRentalFeeSettings.minDaysRole) : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Maximum Days</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.dailyRentalFeeSettings ? packageDetails.packageSetGroup.dailyRentalFeeSettings.maximumDays : '-'}</Typography></Box>
                            </Paper></Grid>
                            <Grid item xs={3}><Paper>
                                <Box m={1}><Typography variant={'caption'}>Maximum Days Applicable Role</Typography></Box>
                                <Box m={1}><Typography>{ packageDetails.packageSetGroup.dailyRentalFeeSettings ? getStopAtRoleDisplay(packageDetails.packageSetGroup.dailyRentalFeeSettings.maxDaysRole) : '-'}</Typography></Box>
                            </Paper></Grid>
                        </Grid>

                        {(level == 'organisations') &&
                            <React.Fragment>
                                <Box m={1}><Box m={3}/><Typography variant={'h5'}>PSA Discounts</Typography></Box><Box m={1}><Divider/></Box><Box m={2} />
                                <GetPsaDiscountView uuid={uuid} />
                            </React.Fragment>
                        }

                    </Box>
                }


                {/**************************************** EDIT PACKAGE SECTION ****************************************/}

                { endPackageSuccess &&
                    <React.Fragment>
                        <Box m={1}></Box>
                        <Box display={'inline'}>
                            <Alert severity="success">
                                <AlertTitle variant={'h6'}> Success </AlertTitle>
                                {getPackageLevelName()} Custom Package ended successfully! <strong>Note</strong>: It might take a few seconds to reflect the changes.
                            </Alert>
                        </Box>
                    </React.Fragment>
                }

                { edit &&
                <Box>
                    { showEndPackages ?

                        <React.Fragment>
                            {!endPackageSuccess && errorMessage &&
                            <Box display={'inline'}>
                                <Alert severity="error">
                                    <AlertTitle variant={'h6'}> End Package Error </AlertTitle>
                                    {errorMessage}
                                </Alert>
                            </Box>
                            }
                            <GetEndCustomPackages/>
                        </React.Fragment>

                        :
                        <SimpleForm onSubmit={handleSubmit} toolbar={<EditPackageToolbar classes={classes}/>} >
                            {!formValid && isUpdated &&
                            <Box display={'inline'}>
                                <Alert severity="error">
                                    <AlertTitle variant={'h6'}> Validation Error </AlertTitle>
                                    {errorMessage}
                                </Alert>
                            </Box>
                            }
                            {!isUpdated && errorMessage &&
                            <Box display={'inline'}>
                                <Alert severity="error">
                                    <AlertTitle variant={'h6'}> Edit Package Error </AlertTitle>
                                    {errorMessage}
                                </Alert>
                            </Box>
                            }
                            {success &&
                            <Box display={'inline'}>
                                <Alert severity="success">
                                    <AlertTitle variant={'h6'}> Success </AlertTitle>
                                    {getPackageLevelName()} Package updated successfully! <strong>Note</strong>: It
                                    might take a few seconds to reflect the changes.
                                </Alert>
                            </Box>
                            }

                            <Box m={1} style={{width: '100%'}}>
                                <Paper>
                                    <Box display={'inline'}>
                                        <Box>
                                            {isSafari ?
                                                <div style={{display:'inline-flex'}}>
                                                    <DateInput source="startDate" className={isStartDateHidden ? classes.dateFieldsHidden : classes.inputTextField}
                                                               defaultValue={new Date().toISOString().slice(0, 10)}
                                                               label={'Start Date'}/>
                                                    <TextInput source="s" label={'End Date'}
                                                               defaultValue={"dd/mm/yyyy"}
                                                               onClick={updateIsStartDateHidden}
                                                               className={isStartDateHidden ? classes.inputTextField : classes.dateFieldsHidden}/>
                                                </div>
                                                :
                                                <DateInput source="startDate" className={classes.inputTextField}
                                                           label={'Start Date'}/>
                                            }
                                            {(level !== 'countries') &&
                                                <div style={{display:'inline-flex'}}>
                                                    {isSafari ?
                                                            <div style={{display:'inline-flex'}}>
                                                                <DateInput source="endDate" className={isEndDateHidden ? classes.dateFieldsHidden : classes.inputTextField}
                                                                           defaultValue={new Date().toISOString().slice(0, 10)}
                                                                           label={'End Date'} resettable />
                                                                <TextInput source="s" label={'End Date'}
                                                                           defaultValue={"dd/mm/yyyy"}
                                                                           onClick={updateIsEndDateHidden}
                                                                           className={isEndDateHidden ? classes.inputTextField : classes.dateFieldsHidden}/>
                                                            </div>
                                                            :
                                                            <DateInput source="endDate" className={classes.inputTextField} label={'End Date'} resettable />
                                                    }
                                                </div>
                                            }
                                        </Box>

                                    </Box>

                                    <Grid container spacing={1} style={{width: '100%'}}>
                                        <Grid item xs={2}>

                                            <div> <Typography variant={'caption'} style={{marginLeft: '10px'}}>Applicable for all
                                                SKUs</Typography></div>
                                            <FormControlLabel style={{marginLeft: '10px'}}
                                                              control={<Checkbox onChange={handleSkuCheckboxChange} name={'skuAll'} checked={disablePerSku}/>}
                                                              label={'ALL'} value={'skuAll'}
                                            />

                                        </Grid>
                                        {!disablePerSku && ['organisations', 'countries'].includes(level) &&
                                        <Grid item xs={10}>
                                            <div><Typography variant={'caption'} style={{marginLeft: '10px'}}>Applicable for
                                                specific SKUs</Typography></div>
                                            <ReferenceArrayInput label={''}
                                                                 source="skuUuid" reference="skus" sort={{name: 'asc'}}
                                                                 onChange={handleSkuSelectChange}
                                                                 className={classes.inputSelectField} style={{display: 'inline'}} >
                                                <SelectArrayInput>
                                                    <ChipField source="name" />
                                                </SelectArrayInput>
                                            </ReferenceArrayInput>
                                        </Grid>
                                        }
                                    </Grid>
                                </Paper>
                            </Box>

                            <Box display={'inline'}>

                                <Box m={1}><Box m={3}/><Typography variant={'h5'}>Add Custom
                                    Pricing</Typography></Box><Box m={1}><Divider/></Box><Box m={2}/>

                                <TextInput className={classes.inputTextField} name="IssueFee" label="Issue fee"
                                           type="number"
                                           defaultValue={packageDetails.packageSetGroup.issueFee ? packageDetails.packageSetGroup.issueFee : void 0}
                                           InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))}/>
                                <TextInput className={classes.inputTextField} name="selfDistributionFixedDiscount"
                                           label="Self Dist. Fixed Discount" type="number"
                                           defaultValue={packageDetails.packageSetGroup.selfDistributionFixedDiscount ? packageDetails.packageSetGroup.selfDistributionFixedDiscount : void 0}
                                           InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))}/>

                                <TextInput className={classes.inputTextField} name="DailyRentalFee"
                                           label="Daily Rental fee" type="number"
                                           defaultValue={packageDetails.packageSetGroup.dailyRentalFee ? packageDetails.packageSetGroup.dailyRentalFee : void 0}
                                           InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))}/>

                                <TextInput className={classes.inputTextField} name="CleaningFee" label="Cleaning fee"
                                           type="number"
                                           defaultValue={packageDetails.packageSetGroup.cleaningFee ? packageDetails.packageSetGroup.cleaningFee : void 0}
                                           InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))} />

                                <TextInput className={classes.inputTextField} name="CleaningSurchargeFee" label="Cleaning Surcharge fee"
                                           type="number"
                                           defaultValue={packageDetails.packageSetGroup.cleaningSurchargeFee ? packageDetails.packageSetGroup.cleaningSurchargeFee : 0}
                                           InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))} />

                                <TextInput className={classes.inputTextField} name="MissedScanFee" label="Missed Scan fee"
                                           type="number"
                                           defaultValue={packageDetails.packageSetGroup.missedScanFee ? packageDetails.packageSetGroup.missedScanFee : 0}
                                           InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))} />

                                <TextInput className={classes.inputTextField} name="TaproomProducerFee"
                                           defaultValue={packageDetails.packageSetGroup.taproomProducerFee ? packageDetails.packageSetGroup.taproomProducerFee : void 0}
                                           label="Taproom to Producer Fee" type="number" InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))}/>
                                <TextInput className={classes.inputTextField} name="TaproomVenueFee"
                                           defaultValue={packageDetails.packageSetGroup.taproomVenueFee ? packageDetails.packageSetGroup.taproomVenueFee : void 0}
                                           label="Taproom to Venue Fee" type="number" InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))}/>
                                <TextInput className={classes.inputTextField} name="TaproomDistributorFee"
                                           defaultValue={packageDetails.packageSetGroup.taproomDistributorFee ? packageDetails.packageSetGroup.taproomDistributorFee : void 0}
                                           label="Taproom to Distributor Fee" type="number" InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))}/>

                                <TextInput className={classes.inputTextField} name="FreightCapitalMetroFee"
                                           label="Freight Capital Metro Fee"
                                           type="number" InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))}/>
                                <Hidden xlDown={true}>
                                    <TextInput className={classes.inputTextField} name="FreightCapitalMetroFeeMinimum"
                                               defaultValue={packageDetails.packageSetGroup.freightCapitalMetroFee ? packageDetails.packageSetGroup.freightCapitalMetroFee : void 0}
                                               type="number" format={v => parseFloat(Math.abs(v))}/>

                                </Hidden>

                                <TextInput className={classes.inputTextField} name="FreightRegionalMetroFee"
                                           label="Freight Regional Metro Fee"
                                           type="number" InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))}/>
                                <Hidden xlDown={true}>
                                    <TextInput className={classes.inputTextField} name="FreightRegionalMetroFeeMinimum"
                                               type="number" format={v => parseFloat(Math.abs(v))} />
                                </Hidden>

                                <TextInput className={classes.inputTextField} name="FreightRegionalFee"
                                           label="Freight Regional Fee" type="number"
                                           defaultValue={packageDetails.packageSetGroup.freightRegionalMetroFee ? packageDetails.packageSetGroup.freightRegionalMetroFee : void 0}
                                           InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))}/>
                                <Hidden xlDown={true}>
                                    <TextInput className={classes.inputTextField} name="FreightRegionalFeeMinimum"
                                               type="number" format={v => parseFloat(Math.abs(v))}/>
                                </Hidden>

                                <TextInput className={classes.inputTextField} name="FreightSuperRegionalFee"
                                           label="Freight Super Regional Fee"
                                           defaultValue={packageDetails.packageSetGroup.freightSuperRegionalFee ? packageDetails.packageSetGroup.freightSuperRegionalFee : void 0}
                                           type="number" InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))}/>
                                <Hidden xlDown={true}>
                                    <TextInput className={classes.inputTextField} name="FreightSuperRegionalFeeMinimum"
                                               type="number" format={v => parseFloat(Math.abs(v))} />
                                </Hidden>

                                <TextInput className={classes.inputTextField} name="FreightPickUpFee"
                                           label="Freight Pickup Fee" type="number"
                                           defaultValue={packageDetails.packageSetGroup.freightPickUpFee ? packageDetails.packageSetGroup.freightPickUpFee : void 0}
                                           InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))}/>
                                <Hidden xlDown={true}>
                                    <TextInput className={classes.inputTextField} name="FreightPickUpFeeMinimum"
                                               type="number" format={v => parseFloat(Math.abs(v))}/>
                                </Hidden>

                                {['organisations'].includes(level) &&
                                    <TextInput className={classes.inputTextField} name="FreightFuelFee" label="Freight Fee - Fuel Levy %" type="number"
                                    type="number"
                                    defaultValue={packageDetails.packageSetGroup.freightFuelFee ? packageDetails.packageSetGroup.freightFuelFee : void 0}
                                    InputLabelProps={{shrink: true,}} variant="filled"
                                    format={v => parseFloat(Math.abs(v))} /> 
                                }

                                <TextInput className={classes.inputTextField} name="PalletFee" label="Pallet Fee"
                                           type="number"
                                           defaultValue={packageDetails.packageSetGroup.palletFee ? packageDetails.packageSetGroup.palletFee : void 0}
                                           InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))}/>
                                <TextInput className={classes.inputTextField} name="ExpressFee"
                                           label="Next Day Delivery Fee" type="number"
                                           defaultValue={packageDetails.packageSetGroup.expressFee ? packageDetails.packageSetGroup.expressFee : void 0}
                                           InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))}/>
                                <TextInput className={classes.inputTextField} name="NonMystarFee" label="Non Mystar Fee"
                                           type="number"
                                           defaultValue={packageDetails.packageSetGroup.nonMystarFee ? packageDetails.packageSetGroup.nonMystarFee : void 0}
                                           InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))} />
                                <TextInput className={classes.inputTextField} name="StopAtDistributorFee"
                                           label="Stop At Distributor Fee" type="number"
                                           defaultValue={packageDetails.packageSetGroup.stopAtDistributorFee ? packageDetails.packageSetGroup.stopAtDistributorFee : void 0}
                                           InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))} />
                                <TextInput className={classes.inputTextField} name="InterCountryFee"
                                           label="Inter Country Fee" type="number"
                                           defaultValue={packageDetails.packageSetGroup.interCountryFee ? packageDetails.packageSetGroup.interCountryFee : void 0}
                                           InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))} />
                                <TextInput className={classes.inputTextField} name="CollectionRegionalFee"
                                           label="Regional Collection Fees"
                                           defaultValue={packageDetails.packageSetGroup.collectionRegionalFee ? packageDetails.packageSetGroup.collectionRegionalFee : void 0}
                                           type="number" InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))} />
                                <TextInput className={classes.inputTextField} name="CollectionSuperRegionalFee"
                                           label="Super Regional Collection Fees"
                                           defaultValue={packageDetails.packageSetGroup.collectionSuperRegionalFee ? packageDetails.packageSetGroup.collectionSuperRegionalFee : void 0}
                                           type="number" InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))} />

                                <TextInput className={classes.inputTextField} name="FixedFee" label="Fixed fee"
                                           type="number"
                                           defaultValue={packageDetails.packageSetGroup.fixedFee ? packageDetails.packageSetGroup.fixedFee : void 0}
                                           InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))} />


                                <TextInput className={classes.inputTextField} name="TimedFee" label="Timed Fee"
                                           type="number"
                                           InputLabelProps={{shrink: true,}} variant="filled"
                                           style={{display: 'none'}} format={v => parseFloat(Math.abs(v))} />

                                <SelectInput source="perKegFreight"  label="Freight Per Keg" defaultValue={false} choices={[
                                    { id: false, name: 'No' },
                                    { id: true, name: 'Yes' }
                                ]} className={classes.inputTextField}  />

                                <SelectInput source="isDepositApplied"  label="Deposit Applied?" defaultValue={false} choices={[
                                    { id: false, name: 'No' },
                                    { id: true, name: 'Yes' }
                                ]} className={classes.inputTextField}  />

                                <TextInput className={classes.inputTextField} name="DepositFee" label="Deposit Per Keg Fee"
                                           type="number"
                                           defaultValue={packageDetails.packageSetGroup.depositFee ? packageDetails.packageSetGroup.depositFee : void 0}
                                           InputLabelProps={{shrink: true,}} variant="filled" format={v => parseFloat(Math.abs(v))} />

                                <TextInput className={classes.inputTextField} name="CollectionRebateFee" label="Collection Rebate Fee" type="number"
                                type="number"
                                defaultValue={packageDetails.packageSetGroup.collectionRebateFee ? packageDetails.packageSetGroup.collectionRebateFee : void 0}
                                InputLabelProps={{shrink: true,}} variant="filled"
                                format={v => parseFloat(Math.abs(v))} />

                                <TextInput className={classes.inputTextField} name="MonthlyRentalFee" label="Monthly Rental Fee" type="number"
                                type="number"
                                defaultValue={packageDetails.packageSetGroup.monthlyRentalFee ? packageDetails.packageSetGroup.monthlyRentalFee : void 0}
                                InputLabelProps={{shrink: true,}} variant="filled"
                                format={v => parseFloat(Math.abs(v))} />           

                                {disablePerSku && ['organisations', 'countries'].includes(level) &&
                                <TextInput className={classes.inputTextField} name="TaproomFixedFee" label="Taproom Fixed Fee"
                                           type="number"
                                           InputLabelProps={{shrink: true,}} variant="filled"
                                           format={v => parseFloat(Math.abs(v))} />
                                }

                                <Box m={1}><Box m={3}/><Typography variant={'h5'}>Timed Fees</Typography></Box><Box
                                m={1}><Divider/></Box><Box m={2}/>

                                <Box>

                                    {(level === 'countries' || level === 'organisations') ?
                                        <React.Fragment>
                                            {packageDetails.packageSetGroup.timedFeeSettings.timedFeeGroup.timedFees.map((row, key) => (
                                                <Box display="inline">
                                                    <TextInput label={`${row.billedAtDay} Days`} type="number"
                                                               className={classes.inputTextField}
                                                               InputLabelProps={{shrink: true,}} variant="filled"
                                                               name={row.uuid}
                                                               onChange={handleTimedFeeChange}
                                                               defaultValue={null} InputProps={{
                                                        readOnly: false,
                                                    }} format={v => parseFloat(Math.abs(v))} />
                                                </Box>
                                            ))
                                            }
                                        </React.Fragment>
                                        :
                                        <Box m={1}>
                                            {packageDetails.packageSetGroup.timedFeeSettings.timedFeeGroup.timedFees.map((row, key) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            id={`timedFee${row.billedAtDay}`}
                                                            checked={checkWaivedTimeFee[row.billedAtDay]}
                                                            onChange={handleWaivedTimedFee}
                                                            value={row.billedAtDay}
                                                            name={`waiveTimedFee`}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={`Waive ${row.billedAtDay} Days Timed Fees`}
                                                />
                                            ))
                                            }
                                        </Box>
                                    }

                                </Box>

                                {(level !== 'locations') &&
                                <React.Fragment>

                                    <Box m={1}><Box m={3}/><Typography variant={'h5'}>Payment Terms</Typography></Box><Box m={1}><Divider/></Box><Box m={2}/>
                                    <Box>

                                        <TextInput className={classes.inputTextField}
                                                   name="orderInvoicePaymentDays" label="Order Invoice - Non-PSA"
                                                   type="number" InputLabelProps={{shrink: true,}}
                                                   variant="filled" format={v => parseFloat(Math.abs(v))} />
                                        <TextInput className={classes.inputTextField}
                                                   name="orderPsaInvoicePaymentDays" label="Order Invoice - PSA"
                                                   type="number" InputLabelProps={{shrink: true,}}
                                                   variant="filled" format={v => parseFloat(Math.abs(v))} />
                                        <TextInput className={classes.inputTextField}
                                                   name="depositInvoicePaymentDays" label="Deposit Invoice"
                                                   type="number" InputLabelProps={{shrink: true,}}
                                                   variant="filled" format={v => parseFloat(Math.abs(v))} />
                                        <TextInput className={classes.inputTextField}
                                                   name="monthlyInvoicePaymentDays" label="Monthly Invoice - Non-PSA"
                                                   type="number" InputLabelProps={{shrink: true,}}
                                                   variant="filled" format={v => parseFloat(Math.abs(v))} />
                                        <TextInput className={classes.inputTextField}
                                                   name="monthlyPsaInvoicePaymentDays" label="Monthly Invoice - PSA"
                                                   type="number" InputLabelProps={{shrink: true,}}
                                                   variant="filled" format={v => parseFloat(Math.abs(v))} />
                                    </Box>

                                    <Box m={1}><Box m={3}/></Box><Box m={1}><Divider/></Box><Box m={2}/>
                                    <Box m={1}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={changeDailyRentalSetup}
                                                    onChange={handleChangeDailyRentalSetup}
                                                    color="primary"
                                                />
                                            }
                                            label="Change Daily Rental Setup"
                                        />
                                    </Box>

                                    { changeDailyRentalSetup &&
                                    <React.Fragment>
                                        <Box m={1}><Box m={3}/><Typography variant={'h5'}>Daily Rental Setup</Typography></Box><Box m={1}><Divider/></Box><Box m={2}/>
                                        <Box>

                                            <SelectInput className={classes.inputSelectField}
                                                         source="dailyRentalFeeSettingsStopAtRole"
                                                         label={'Daily Rental Stop At Role'} choices={stopAtRoleChoices}
                                                         allowEmpty resettable/>
                                            <TextInput className={classes.inputTextFieldSmall}
                                                       name="dailyRentalFeeSettingsMinimumDays" label="Minimum Days"
                                                       type="number" InputLabelProps={{shrink: true,}}
                                                       variant="filled" format={v => parseFloat(Math.abs(v))} />
                                            <SelectInput className={classes.inputSelectField}
                                                         source="dailyRentalFeeSettingsMinDaysRole"
                                                         label={'Min Days Stop At Role'} choices={stopAtRoleChoices}
                                                         allowEmpty resettable/>
                                            <TextInput className={classes.inputTextFieldSmall}
                                                       name="dailyRentalFeeSettingsMaximumDays" label="Maximum Days"
                                                       type="number" InputLabelProps={{shrink: true,}}
                                                       variant="filled" format={v => parseFloat(Math.abs(v))} />
                                            <SelectInput className={classes.inputSelectField}
                                                         source="dailyRentalFeeSettingsMaxDaysRole"
                                                         label={'Max Days Stop At Role'} choices={stopAtRoleChoices}
                                                         allowEmpty resettable/>
                                        </Box>
                                    </React.Fragment>
                                    }

                                </React.Fragment>
                                }

                            </Box>
                        </SimpleForm>
                    }
                </Box>
                }

                <Box m={5} />
                <Box m={1}><Divider/></Box>

                {(level !== 'countries') && editPsa &&
                <React.Fragment>
                    {!isUpdated && errorMessage &&
                    <Box display={'inline'}>
                        <Alert severity="error">
                            <AlertTitle variant={'h6'}> Edit PSA Error </AlertTitle>
                            {errorMessage}
                        </Alert>
                    </Box>
                    }
                    {successPsa &&
                    <Box display={'inline'}>
                        <Alert severity="success">
                            <AlertTitle variant={'h6'}> Success </AlertTitle>
                            {getPackageLevelName()} PSA updated successfully! <strong>Note</strong>: It
                            might take a few seconds to reflect the changes.
                        </Alert>
                    </Box>
                    }
                    <SimpleForm onSubmit={handleSubmitPSA} toolbar={<EditPSAToolbar classes={classes}/>} >
                        <Box m={1}><Box m={3}/><Typography variant={'h5'}>Edit PSA
                            Discounts</Typography></Box><Box m={1}><Divider/></Box><Box m={2}/>
                        <GetPsaDiscountEdit uuid={uuid}/>
                    </SimpleForm>
                </React.Fragment>
                }

                <Box m={2} />

                { !edit &&
                    <Box m={1}>
                        <Box m={1}><Box m={3} /><Typography variant={'h5'}>PSA Discount Bands</Typography></Box><Box m={1}><Divider/></Box><Box m={2} />
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <Box m={1}>
                                    <Table>
                                        <TableHead>
                                            <TableRow className={classes.tableRow}>
                                                <TableCell>Description</TableCell>
                                                <TableCell>Lower Bound</TableCell>
                                                <TableCell>Upper Bound</TableCell>
                                                <TableCell>Percentage</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                (packageDetails.packageSetGroup.psaDiscountGroup)
                                                    ?
                                                    packageDetails.packageSetGroup.psaDiscountGroup.psaDiscounts.map(
                                                        item => {
                                                            let currentPsaDiscount = packageDetails.rentalCounts  ? highlightCurrentPsaDiscount(packageDetails.rentalCounts.previousQuarter , item.lowerBound, item.upperBound) : false;
                                                            return (
                                                                <TableRow key={item.uuid}
                                                                          className={currentPsaDiscount
                                                                              ? classes.highlightCurrentPsaDiscountRow : null}>
                                                                    <TableCell>{item.description}</TableCell>
                                                                    <TableCell>{item.lowerBound}</TableCell>
                                                                    <TableCell>{item.upperBound}</TableCell>
                                                                    <TableCell>{item.percentage}</TableCell>
                                                                </TableRow>
                                                            )
                                                        }
                                                    )
                                                    :
                                                    <TableRow>
                                                        <TableCell colSpan={4}>No PSA Discounts</TableCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                }


                <Notification />
            </Box>
        </Container>
    );
};


export default PackageEdit;
