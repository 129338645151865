
export const getMarketColorCode = () => {
    let colorCode = '#525558';
    if (typeof window === 'undefined') return colorCode;

    const marketId = parseInt(localStorage.getItem('mId'));
    switch (marketId) {
        case 1:
            colorCode = '#496901';
            break;
        case 2:
            colorCode = '#607080';
            break;
        case 3:
            colorCode = '#7E0819';
            break;
        case 4:
            colorCode = '#201d56';
            break;
        default:
            colorCode = '#525558';
            break;
    }

    return colorCode;
}

// KegLease Enabled for Australia (1) and New Zealand (2)
export const kegLeaseOrderEnabled = () => {
    if (typeof window === 'undefined') return null;

    return (parseInt(localStorage.getItem('mId')) === 1);
}