// @ts-nocheck
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const DispatchesExport = (records) => {
    const dataForExport = records.map(record => {

        const {
            originLocationRole,	destinationLocationRole, createdAt,	actionedAt,	requestedAt,	originLocationId,	originOrganisationId,
            destinationLocationId,	destinationOrganisationId,	status,	context,	type,	isStarlight, originCountryId, destinationCountryId, isPromoteRequired,
            displayId,
            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field
        // dataForExport.kegSerialNumber = (record.kegs) ? record.kegs.serialNumber : null;

        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'id', 'contextText', 'statusText', 'totalKegs', 'originOrganisation', 'originLocation', 'originCountry', 'originLocationRoleText',
            'destinationOrganisation', 'destinationLocation', 'destinationCountry', 'destinationLocationRoleText', 'createdAtText', 'requestedAtText', 'actionedAtText', 'orderId',
            'typeText', 'isStarlightText', 'isPromotedText'
        ], // order fields in the export
        rename:[
            'Dispatch ID', 'Context', 'Status', 'Total Kegs', 'Origin Organisation', 'Origin Location', 'Origin Country', 'Origin Location Role ',
            'Destination Organisation', 'Destination Location', 'Destination Country', 'Destination Location Role', 'Created At', 'Due Date', 'Actioned At', 'Order ID',
            'Type', 'Starlight?', 'Is Promoted?'
        ]
    }, (err, csv) => {
        let today = new Date();
        downloadCSV(csv, 'Dispatches_'+ today.toISOString().split('T')[0]); // download as 'posts.csv` file
    });

};

export default DispatchesExport;
