import React from "react";
import styled, { keyframes } from 'styled-components';
import { fadeInLeft } from 'react-animations';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const scrollToRightSignalAnimation = keyframes`${fadeInLeft}`;

const ScrollToRightSignalWrapper = styled.div`
  animation: 1.5s ${scrollToRightSignalAnimation} 5;
`;

const ScrollToRightSignal = () => {

    return (
        <div style={{float: 'right'}}>
            <ScrollToRightSignalWrapper>
                <DoubleArrowIcon />
            </ScrollToRightSignalWrapper>
        </div>
    )
}

export default ScrollToRightSignal;
