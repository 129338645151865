import * as React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {Box, Button, Container, Typography, Grid, Chip, CircularProgress} from '@material-ui/core';
import Divider from "@material-ui/core/Divider";
import {useEffect, useState} from "react";
import axios from "axios";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Alert} from "@material-ui/lab";
import InfoIcon from '@material-ui/icons/Info';
import CollectionsList from "../../resources/collections/CollectionsList";

const useStyles = makeStyles({
    kegsAvailabilitySummaryHeader: {
        backgroundColor: '#e2e2e2',
        margin: 0,
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: 0,
        borderTopRightRadius: '5px',
        borderBottomRightRadius: 0,
        borderLeft: '2px solid #e2e2e2',
        borderTop: '2px solid #e2e2e2',
        borderRight: '2px solid #e2e2e2'
    },
    kegsAvailabilityAlertInfo: {
        padding: 0,
        paddingLeft: '10px',
        paddingRight: '10px',
        float: 'right'
    }
});

const Collections = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState(false);
    const [success, setSuccess] = useState(false);

    const collectionsProps = {
        basePath: "/collections",
        resource: "collections"
    }

    return (
        <Container>
            <Box m={2}>
                <Typography variant={'h4'}>Collections</Typography>
            </Box>

            <Box m={1}>
                <Divider />
            </Box>
            <Box m={1} display={'flex'}>
                <Box m={1} flexGrow={1}>

                </Box>
            </Box>
            <Box p={2}>

                <CollectionsList {...collectionsProps} />

            </Box>
        </Container>
    );
};

export default Collections;
