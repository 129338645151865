// @ts-nocheck
import * as React from 'react';
import {FC, useState} from 'react';
import {
    List,
    ListProps,
    Datagrid,
    TextField,
    FunctionField,
    DateField,
    NumberField,
    Filter,
    FilterProps, TextInput, SelectInput, useAuthenticated, useLoading, ReferenceInput, AutocompleteInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Container, LinearProgress} from "@material-ui/core";
import {applyCurrentFilter, formatFeeDisplay, getCountryUuid, setCurrentFilters} from "../../../utilities/Common";

import LocationPackageExport from "./LocationPackageExport";
import ListActions from "../../../components/list/ListActions";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
    links: {
        color: '#2d9edf'
    },
    hiddenOnSmallScreens: {
        display: 'table-cell',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    organisationFilter: {
        width: '270px'
    },
    cycleStatusFilter: {
        width: '50px'
    }
}));

const RecordRowClick = (id, basePath, record) => {
    // console.log(id, basePath, record);
    let url = `/billing/packages/locations/${record.locationUuid}`;
    if(record.skuUuid) {
        url = `/billing/packages/locations/${record.locationUuid}/${record.skuUuid}`;
    }
    return url;
};

const ListFilters = (props: Omit<FilterProps, 'children'>) => {
    const classes = useStyles();
    setCurrentFilters(props,
        ['organisationUuid', 'locationUuid', 'isCustomPackage', 'isPsaCustomer', 'countryUuid', 'skuName', 'state']);
    return (
        <Filter {...props} style={{float: 'left', paddingTop: '10px'}} >
            {/*<TextInput label={'Id'} source={'locationId'} alwaysOn resettable={true} style={{maxWidth: '100px'}} />*/}
            <ReferenceInput source="organisationUuid" reference="organisations" label={'Organisation'}
                            filter={{market: localStorage.getItem('mId'), isProducer: 1, isActive: 1}}
                            sort={{name: 'asc'}} alwaysOn allowEmpty={false} resettable
                            className={classes.organisationFilter} id={'locationPackageListOrganisationFilter'}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            {/*<TextInput label={'Location'} source={'locationName'} alwaysOn resettable={true} />*/}
            <ReferenceInput source="locationUuid" reference="locations" label={'Location'}
                            filter={{market: localStorage.getItem('mId'), isProducer: 1}}
                            sort={{name: 'asc'}}
                            alwaysOn resettable >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>

            <SelectInput source="isCustomPackage" label={'Custom Package?'} choices={[
                { id: 'Yes', name: 'Yes' },
                { id: 'No', name: 'No' }
            ]} alwaysOn resettable={true} style={{minWidth: '170px'}} />
            <SelectInput source="isPsaCustomer" label={'PSA Customer?'} choices={[
                { id: 'Yes', name: 'Yes' },
                { id: 'No', name: 'No' }
            ]} alwaysOn resettable={true}  />
            {/*<TextInput label={'Country'} source={'countryName'} resettable={true} />*/}
            <ReferenceInput source="countryUuid" reference="countries" label={'Country'}
                            filter={{marketId: localStorage.getItem('mId')}} sort={{id: 'asc'}} resettable>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            {/*<TextInput label={'Sku'} source={'skuName'} resettable={true} />*/}
            <ReferenceInput source="skuName" reference="skus" label={'SKU'} resettable >
                <AutocompleteInput optionValue={'name'} optionText="name" />
            </ReferenceInput>
            <TextInput label={'State'} source={'state'} resettable={true} />
        </Filter>
    )
}

const LocationPackageList: FC<ListProps> = props => {
    useAuthenticated();
    const loading = useLoading();
    const classes = useStyles();
    const [hasFilter, setHasFilter] = useState(false);
    let defaultFilterValues = {marketId: localStorage.getItem('mId'), organisationUuid: localStorage.getItem('oUuid') };
    defaultFilterValues = applyCurrentFilter(defaultFilterValues,
        ['organisationUuid', 'locationUuid', 'isCustomPackage', 'isPsaCustomer', 'countryUuid', 'skuName', 'state']);

    return (
        <React.Fragment>
            <Container style={{width: 'auto', overflowX: 'scroll', maxWidth: '100vw', padding: '8px'}}>
                { loading &&
                <Box>
                    <LinearProgress />
                </Box>
                }
                <Box m={1}></Box>
                <Alert severity="info" >
                    <AlertTitle>Select an Organisation to continue</AlertTitle>
                </Alert>
                <List
                    {...props}
                    bulkActionButtons={false}
                    actions={<ListActions />}
                    filters={<ListFilters />}
                    filter={{marketId: localStorage.getItem('mId')}}
                    filterDefaultValues={defaultFilterValues}
                    sort={{ field: 'id', order: 'desc' }}
                    exporter={LocationPackageExport}
                >
                    <Datagrid style={{ maxWidth: '1356px' }} rowClick={RecordRowClick}>
                        <TextField label={'Id'} source={'locationId'} />
                        <TextField label={'Location'} source={'locationName'} style={{paddingRight: '65px'}} />
                        <TextField label={'Organisation'} source={'organisationName'} style={{paddingRight: '65px'}} />
                        <TextField label={'Country'} source={'countryName'} />
                        <TextField label={'State'} source={'state'} />
                        <TextField label={'Custom Package?'} source={'isCustomPackage'} />
                        <TextField label={'PSA Customer?'} source={'isPsaCustomer'} />
                        <DateField label={'PSA Start Date'} source={'psaStartDate'} />
                        <DateField label={'PSA End Date'} source={'psaEndDate'} />
                        <NumberField label={'Contracted Volume'} source={'volumeStartingOffset'} />
                        <NumberField label={'Guaranteed Supply'} source={'guaranteedSupply'} />
                        <TextField label={'Current % Discount'} source={'currentDiscount'} />
                        <DateField label={'Pricing Applicable From'} source={'pricingApplicableFrom'} />
                        <DateField label={'Pricing Applicable To'} source={'pricingApplicableTo'} />
                        <TextField label={'Sku'} source={'skuName'} />

                        <FunctionField render={record => ( formatFeeDisplay(record.issueFee)) } label={'Issue Fee'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.fixedFee)) } label={'Fixed Fee'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.cleaningFee)) } label={'Cleaning Fee'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.selfDistributionFixedDiscount)) } label={'Self Dist Fixed Discount'} />
                        <TextField label={'Freight Per Keg?'} source={'perKegFreight'} />

                        <FunctionField render={record => ( formatFeeDisplay(record.freightCapitalMetroFee)) } label={'Freight Fee - Capital Metro'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.freightRegionalMetroFee)) } label={'Freight Fee - Regional Metro'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.freightRegionalFee)) } label={'Freight Fee - Regional'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.freightSuperRegionalFee)) } label={'Freight Fee - Super Regional'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.freightPickUpFee)) } label={'Freight-Pickup'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.expressFee)) } label={'Next Day Delivery Fee'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.palletFee)) } label={'Pallet Fee'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.nonMystarFee)) } label={'Non-Mystar Fee'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.taproomVenueFee)) } label={'Taproom to Venue'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.taproomDistributorFee)) } label={'Taproom to Distributor'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.taproomProducerFee)) } label={'Taproom to Producer'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.dailyRentalFee)) } label={'Daily Rental'} />
                        <TextField label={'Timed Fee'} source={'timedFee'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.collectionRegionalFee)) } label={'Collection - Regional'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.collectionSuperRegionalFee)) } label={'Collection - Super Regional'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.stopAtDistributorFee)) } label={'Stop at Distributor Fee'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.interCountryFee)) } label={'Intermarket Fee'} />
                    </Datagrid>
                </List>

            </Container>
        </React.Fragment>
    );
};

export default LocationPackageList;
