import React, {Fragment, useState} from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    FunctionField, Filter, ReferenceInput, AutocompleteInput, SelectInput, useLoading, DateInput, ReferenceArrayInput,
    SelectArrayInput, NumberInput,
    useAuthenticated, TextInput,
    Confirm,
    useListContext,
    useCreate, useRefresh
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
    CustomPagination, getMarketColorCode, OrderStatuses, OrderTypes, setCurrentFilters, applyCurrentFilter
} from "../../utilities/Common";

import {Container, LinearProgress, Box, CircularProgress, Button} from "@material-ui/core";
import OrdersExport from "./OrdersExport";
import OrdersListActions from "./OrdersListActions";
import Link from "@material-ui/core/Link";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';



export const styles = (theme => ({
    root: {},
    loading: {
        '& .MuiTable-root': {
            display: 'none'
        },
        '& .MuiTypography-body2': {
            display: 'none'
        },
        '& .MuiTablePagination-toolbar': {
            display: 'none'
        }

    },
    orderFilter: {
        width: '10em'
    },
    organisationFilter: {
        width: '18em'
    },
    skuFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    },
    typeFilter: {
        minWidth: '8em'
    },
    statusFilter: {
        minWidth: '6em'
    },
    stateFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    }
}));

const useStyles = makeStyles(styles);

const ListFilters = props => {
    const classes = useStyles();

    // setCurrentFilters(props,
    //     ['id', 'orderType[]', 'toOrganisationId', 'orderStatus[]', 'skuUuid[]',
    //         'createdAt_from_date', 'createdAt_to_date', 'requestedAt_from_date', 'requestedAt_to_date', 'actionedAt_from_date',
    //         'actionedAt_to_date', 'kegsRequestedFrom', 'kegsRequestedTo', 'isExpress', 'state',
    //         'producerLocation', 'origin', 'originLocation', 'shippingMethod'],
    //     'orders');

    return (
        <Filter {...props}>
            {/*<ReferenceInput source="id" reference="orders" label={'Order No.'}*/}
            {/*                className={classes.orderFilter}*/}
            {/*                filter={{market: localStorage.getItem('mId')}}*/}
            {/*                sort={{ field: 'orderId', order: 'desc' }}*/}
            {/*                sortDefaultValues={{ orderId: 'desc'}} alwaysOn resettable allowEmpty={false}>*/}
            {/*    <AutocompleteInput optionText="displayId" />*/}
            {/*</ReferenceInput>*/}
            <NumberInput source={'q'} label={'Order No.'} min={0} autoComplete={'off'} resettable alwaysOn allowEmpty={false} />
            <SelectArrayInput source="orderStatus" label={'Status'} choices={OrderStatuses} className={classes.statusFilter} resettable alwaysOn />
            <SelectArrayInput source="orderType" label={'Order Type'}
                              choices={OrderTypes} className={classes.typeFilter} resettable alwaysOn  />
            <ReferenceArrayInput source={'skuUuid[]'} reference="skus" label={'Keg Type'}
                                 className={classes.skuFilter} resettable alwaysOn >
                <SelectArrayInput source={'skuUuid[]'} optionText="name" />
            </ReferenceArrayInput>
            <TextInput label={'PO Number'} source={'poNumber'} resettable />
            <ReferenceInput source="toOrganisationId" reference="organisations"
                            filter={{market: localStorage.getItem('mId'), isProducer: 1, isActive: 1}}
                            sort={{ field: 'name', order: 'asc' }}
                            sortDefaultValues={{ field: 'name', order: 'asc' }} label={'Producer'}
                            className={classes.organisationFilter} alwaysOn resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <DateInput label={'Placed From'} source={'createdAt_from_date'} resettable />
            <DateInput label={'Placed To'} source={'createdAt_to_date'} resettable />
            <DateInput label={'Due Date From'} source={'requestedAt_from_date'} alwaysOn resettable />
            <DateInput label={'Due Date To'} source={'requestedAt_to_date'} alwaysOn resettable />
            <DateInput label={'Actioned From'} source={'actionedAt_from_date'} resettable />
            <DateInput label={'Actioned To'} source={'actionedAt_to_date'} resettable />
            <NumberInput label={'No. of Kegs From'} source={'kegsRequestedFrom'} resettable />
            <NumberInput label={'No. of Kegs To'} source={'kegsRequestedTo'} resettable />
            <SelectInput label={'Next Day Delivery'} source="isExpress" choices={[
                { id: null, name: 'All' },
                { id: 1, name: 'Yes' },
                { id: 0, name: 'No' },
            ]} />
            <ReferenceInput source="state" reference="locations/states" label={'State'} resettable className={classes.stateFilter}
                            filter={{market: localStorage.getItem('mId')}} >
                <SelectArrayInput optionText="stateName" resettable />
            </ReferenceInput>
            <ReferenceInput source="producerLocation" reference="locations"
                            filter={{market: localStorage.getItem('mId')}}
                            sort={{ field: 'name', order: 'asc' }}
                            sortDefaultValues={{ field: 'name', order: 'asc' }} label={'Producer Location'}
                            className={classes.organisationFilter} resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="origin" reference="organisations"
                            filter={{market: localStorage.getItem('mId')}}
                            label={'Origin'}
                            className={classes.organisationFilter} resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="originLocation" reference="locations"
                            filter={{market: localStorage.getItem('mId')}}
                            sort={{ field: 'name', order: 'asc' }}
                            sortDefaultValues={{ field: 'name', order: 'asc' }} label={'Origin Location'}
                            className={classes.organisationFilter} resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <SelectInput label={'Shipping Method'} source="shippingMethod" choices={[
                { id: null, name: 'All' },
                { id: 0, name: 'Deliver' },
                { id: 1, name: 'Customer Pick-up' },
                { id: 2, name: 'Freight Delivery' },
            ]} />

        </Filter>
    )
};

const ToOrganisation = ({record}) => {
    return(
        <React.Fragment>
            { record.toOrganisationName ?
                <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.toOrganisationId}/overview`}>
                    <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.toOrganisationName}</span>
                </Link>
                : null } <br/>
            { record.toLocationId ?
                <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.toOrganisationId}/locations/view/${record.toLocationId}`}>
                    <span style={{color: getMarketColorCode(localStorage.getItem('mId')) }}>{record.toLocationName}</span>
                </Link>
                : null }
        </React.Fragment>
    )
}
const FromOrganisation = ({record}) => {
    return(
        <React.Fragment>
            { record.fromOrganisationName ?
                <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.fromOrganisationId}/overview`}>
                    <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.fromOrganisationName}</span>
                </Link>
                 : 'Not Allocated' } <br/>
            { record.fromLocationId ?
                <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.fromOrganisationId}/locations/view/${record.fromLocationId}`}>
                    <span style={{color: getMarketColorCode(localStorage.getItem('mId')) }}>{record.fromLocationName}</span>
                </Link>
                 : null }
        </React.Fragment>
    )
}

const OrdersList = props => {
    useAuthenticated();
    const loading = useLoading();
    const classes = useStyles();
    const marketId = localStorage.getItem('mId');
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const state = { setErrorMessage: setErrorMessage,
                         setSuccessMessage: setSuccessMessage };

    let defaultFilterValues = { market: marketId, tz: Intl.DateTimeFormat().resolvedOptions().timeZone };

    const [open, setOpen] = useState(false);
    const [netSuiteIdToSend, setNetSuiteIdToSend] = useState(null);
    const [create] = useCreate();

    const refresh = useRefresh();
    const handleRefreshClick = () => {
        refresh();
    };

    const handleDialogClose = () =>{
        setNetSuiteIdToSend(null);
        setOpen(false);
    };

    const handleConfirm = async () => {
        try {
            let url = `${process.env.REACT_APP_KEGNOVA_API_URL}/invoices/draft/regenerate`;
            const options = {
                headers: {
                    'Accept': 'application/json', 'Content-Type': 'application/json',
                    'x-api-key': localStorage.getItem('apiKey')
                }
            };
            const payload = { invoice: netSuiteIdToSend };
            await axios.post(url, payload, options);
            setErrorMessage(null);
            setSuccessMessage("Invoice Regenerated successfully: The changes will be reflected in a few minutes")
        } catch (error) {
            setErrorMessage('Error regenerating the invoice ' + (error.response.length > 0) ? error.response.data.message : '');
            setSuccessMessage(null);
        }
        setNetSuiteIdToSend(null);
        setOpen(false);
    };

    const handleNetSuiteRegenerate = (netSuiteId) => {
        setNetSuiteIdToSend(netSuiteId);
        setOpen(true);
    };

    return (
        <Fragment>
            <Container maxWidth={false}>
                { loading && <LinearProgress /> }
                {errorMessage &&
                    <Box m={2}>
                        <React.Fragment>
                            <Box display={'inline'} style={{ width: '60%' }}>
                                <Collapse in={errorMessage}>
                                    <Alert severity="error"
                                           action={
                                               <IconButton
                                                   aria-label="close"
                                                   color="inherit"
                                                   size="small"
                                                   onClick={() => {
                                                       setErrorMessage(null);
                                                   }}
                                               >
                                                   <CloseIcon fontSize="inherit" />
                                               </IconButton>
                                           }
                                    >
                                        <AlertTitle> Error while regenerating invoice! </AlertTitle>
                                        <Box m={1}>
                                            {errorMessage.substring(0,100)}
                                        </Box>
                                    </Alert>
                                </Collapse>
                            </Box>
                        </React.Fragment>
                    </Box>
                }
                {successMessage &&
                <Box m={2}>
                    <React.Fragment>
                        <Box display={'inline'} style={{ width: '60%' }}>
                            <Collapse in={Boolean(successMessage)}>
                                <Alert severity="success"
                                       action={
                                           <IconButton
                                               aria-label="close"
                                               color="inherit"
                                               size="small"
                                               onClick={() => {
                                                   setSuccessMessage(null);
                                               }}
                                           >
                                               <CloseIcon fontSize="inherit" />
                                           </IconButton>
                                       }
                                >
                                    <AlertTitle> Success! </AlertTitle>
                                    <Box m={1}>
                                        {successMessage}
                                    </Box>
                                </Alert>
                            </Collapse>
                        </Box>
                    </React.Fragment>
                </Box>
                }
                { loading &&
                <Box justifyContent={'center'} justifyItems={'center'}  alignItems={'center'} alignContent={'center'}
                     style={{backgroundColor: '#1f2124', color: '#fff', padding:'5px', position: 'fixed',
                         bottom: '0', zIndex: 50, width: '90%'}}>
                    <Box p={1}><CircularProgress size={20} style={{color: '#fff'}} /> Please wait...</Box>
                </Box>
                }
                <List {...props}
                      actions={<OrdersListActions state={state} refreshData={handleRefreshClick} />}
                      filter={{ market: marketId, tz: localStorage.getItem('timezone') }}
                      filterDefaultValues={defaultFilterValues}
                      filters={ <ListFilters /> }
                      sort={{ field: 'orderId', order: 'desc' }}
                      perPage={10}
                      bulkActionButtons={false}
                      exporter={OrdersExport}
                      pagination={<CustomPagination />}
                >
                    <Datagrid
                        // empty={<Box m={3} textAlign={'center'}> <EmptyRecordIcon /> </Box>}
                    >
                        <FunctionField source={'orderId'} render={record => <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/orders/${record.orderId}/details`}>
                            <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.orderId}</span>
                        </Link> } label={'Order Number'} />
                        <FunctionField source={'orderStatus'} render={record => (record.orderStatusText) } label={'Order Status'} />
                        <FunctionField source={'orderType'} render={record => (record.orderTypeText) } label={'Order Type'} />
                        <NumberField source="kegsRequested" label={'No. of Kegs'} />
                        <FunctionField source={'skuId'} render={record => (record.skuName) } label={'Keg Type'} />
                        <FunctionField source={'shippingMethod'} render={record => (record.shippingMethodText) } label={'Shipping Method'} />
                        <FunctionField source={'isExpress'} render={record => (<span>{(record.isExpress) ? 'Yes' : 'No'}</span>) } label={'Next Day Delivery'} />
                        <TextField source="poNumber" label={'PO Number'} />
                        <FunctionField source={'toOrganisationName'} render={record => <ToOrganisation record={record} /> } label={'Producer'} />
                        <FunctionField source={'state'} render={record => (record.toLocationState) } label={'State'} />
                        <FunctionField source={'forecastType'} render={record => (record.forecastTypeText) } label={'Forecast Type'} />
                        <DateField source="createdAt" label={'Placed'} locales={(localStorage.getItem('mId') === '4') ? 'en-US' : 'en-GB'}
                                   options={{ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }}/>
                        <DateField source="requestedAt" label={'Due Date'} locales={(localStorage.getItem('mId') === '4') ? 'en-US' : 'en-GB'} />
                        <FunctionField source={'fromOrganisationName'} render={record => <FromOrganisation record={record} /> } label={'Origin'} />
                        <FunctionField source={'actionedAt'} render={record =>
                            record.actionedAt
                                ?

                                <DateField source="actionedAt" label={'Actioned'} locales={(localStorage.getItem('mId') === '4') ? 'en-US' : 'en-GB'}
                                           options={{ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }} />

                                :
                                null
                        } label={'Actioned'} />

                        {/* show only for completed order */}
                        <FunctionField label={'More Actions'} render={record =>
                            (record.orderStatus === 4) ?
                                <Link
                                    href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/orders/${record.orderId}/duplicate`}
                                    target="_blank"
                                    style={{textDecoration: 'none'}}>
                                    <Button variant={'contained'} size={'small'}>Duplicate Order</Button>
                                </Link>
                            : (!record.actionedAt && record.orderStatus === 6)
                                    ?   <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/orders/${record.orderId}/modify`}
                                                                                   style={{textDecoration: 'none'}}>
                                          <Button variant={'contained'} size={'small'}>Create Order</Button>
                                        </Link>
                                    : null
                        } />

                        <FunctionField render={record => (
                            <React.Fragment>
                                <>
                                {record.draftInvoice[1]  ?
                                    <div>
                                        <span> {
                                            (record.draftInvoice[0] ? (record.draftInvoice[0].invoiceTypeText + ': ' + record.draftInvoice[0].netsuiteDocNumber) : '')
                                        }</span>
                                        {record.draftInvoice[0] &&  record.draftInvoice[0].netsuiteDocNumber &&
                                            <Button variant={'contained'} size={'small'} onClick={() => handleNetSuiteRegenerate(record.draftInvoice[0].id)}>Regenerate</Button>}
                                        <br/>
                                        <span >{
                                            (record.draftInvoice[1] ? (record.draftInvoice[1].invoiceTypeText + ': ' + record.draftInvoice[1].netsuiteDocNumber) : '')
                                        }</span>
                                        {record.draftInvoice[1] && record.draftInvoice[1].netsuiteDocNumber &&
                                            <Button variant={'contained'} size={'small'}
                                                onClick={() => handleNetSuiteRegenerate(record.draftInvoice[1].id)}>Regenerate </Button>
                                        }
                                    </div>

                                    : record.draftInvoice[0] ?
                                        <div>
                                            <span>
                                                {(record.draftInvoice[0] ? (record.draftInvoice[0].invoiceTypeText + ': ' + record.draftInvoice[0].netsuiteDocNumber) : '')}
                                            </span>
                                            {record.draftInvoice[0] && record.draftInvoice[0].netsuiteDocNumber &&
                                                <Button variant={'contained'} size={'small'}
                                                        onClick={() => handleNetSuiteRegenerate(record.draftInvoice[0].id)}>Regenerate </Button>
                                            }
                                        </div>
                                        : ''
                                }
                                    <Dialog
                                        style={ { width: '80%', maxHeight: 435 } }
                                        maxWidth="xs"
                                        open={open}
                                        onClose={handleDialogClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {"Regenerate Invoice?"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                Do you wish to continue with Invoice Regeneration for the selected order?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleDialogClose}>Cancel</Button>
                                            <Button onClick={handleConfirm} autoFocus>
                                                Submit
                                            </Button>
                                        </DialogActions>
                                    </Dialog>

                                </>
                            </React.Fragment>
                        ) } label={'NetSuite DocNumber'} />

                    </Datagrid>
                </List>
            </Container>
        </Fragment>
    );

}

export default OrdersList;

