import React, {Fragment, useState} from 'react';
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    FunctionField,
    List,
    NumberField,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectArrayInput,
    SelectInput,
    useAuthenticated,
    useLoading
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";

import {CustomPagination, getMarketColorCode, OrderStatuses, KegTypes} from "../../utilities/Common";

import {Box, CircularProgress, Container, LinearProgress} from "@material-ui/core";
import CyclesExport from "../cycles/CyclesExport";
import CyclesListActions from "../cycles/CyclesListActions";
import Link from "@material-ui/core/Link";

export const styles = (theme => ({
    root: {},
    loading: {
        '& .MuiTable-root': {
            display: 'none'
        },
        '& .MuiTypography-body2': {
            display: 'none'
        },
        '& .MuiTablePagination-toolbar': {
            display: 'none'
        }

    },
    orderFilter: {
        width: '10em'
    },
    organisationFilter: {
        width: '18em'
    },
    skuFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    },
    typeFilter: {
        minWidth: '8em'
    },
    statusFilter: {
        minWidth: '6em'
    },
    stateFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    }
}));

const useStyles = makeStyles(styles);

const ListFilters = props => {
    const classes = useStyles();

    return (
        <Filter {...props}>
            <NumberInput source={'id'} label={'Cycle No.'} min={0} resettable alwaysOn/>

            <ReferenceInput source="organisationuuid" reference="organisations"
                // filter={{market: localStorage.getItem('mId'), isProducer: 1, isActive: 1}}
                            sort={{field: 'name', order: 'asc'}}
                            sortDefaultValues={{field: 'name', order: 'asc'}} label={'From'}
                            className={classes.organisationFilter} alwaysOn resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>

            <SelectInput source="kegType" label={'Keg Category'}
                              choices={KegTypes} className={classes.typeFilter} resettable alwaysOn  />


            <DateInput label={'Billing From'} source={'billingStartAt'} resettable alwaysOn />
            <DateInput label={'Billing To'} source={'billingStopAt'} resettable alwaysOn />

        </Filter>
    )
};

const ToOrganisation = ({record}) => {
    return (
        <React.Fragment>
            {record.toOrganisationName ?
                <Link
                    href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.toOrganisationId}/overview`}>
                    <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.toOrganisationName}</span>
                </Link>
                : null} <br/>
            {record.toLocationId ?
                <Link
                    href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.toOrganisationId}/locations/view/${record.toLocationId}`}>
                    <span
                        style={{color: getMarketColorCode(localStorage.getItem('mId'))}}>{record.toLocationName}</span>
                </Link>
                : null}
        </React.Fragment>
    )
};


const FromOrganisation = ({record}) => {
    return (
        <React.Fragment>
            {record.fromOrganisationName ?
                <Link
                    href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.fromOrganisationId}/overview`}>
                    <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.fromOrganisationName}</span>
                </Link>
                : 'Not Allocated'} <br/>
            {record.fromLocationId ?
                <Link
                    href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.fromOrganisationId}/locations/view/${record.fromLocationId}`}>
                    <span
                        style={{color: getMarketColorCode(localStorage.getItem('mId'))}}>{record.fromLocationName}</span>
                </Link>
                : null}
        </React.Fragment>
    )
};

const CyclesList = props => {
    useAuthenticated();
    const loading = useLoading();
    const classes = useStyles();
    const marketId = localStorage.getItem('mId');
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const state = {
        setErrorMessage: setErrorMessage,
        setSuccessMessage: setSuccessMessage
    };

    let dateToday = (new Date()).toISOString().split('T')[0];

    var d = new Date();
    d.setMonth(d.getMonth() - 12);
    let dateOneYearAgo = d.toISOString().split('T')[0];

    let defaultFilterValues = {market: marketId, billingStopAt : dateToday, billingStartAt : dateOneYearAgo};

    // @ts-ignore
    return (
        <Fragment>
            <Container maxWidth={false}>
                {loading && <LinearProgress/>}
                {loading &&
                <Box justifyContent={'center'} justifyItems={'center'} alignItems={'center'} alignContent={'center'}
                     style={{
                         backgroundColor: '#1f2124', color: '#fff', padding: '5px', position: 'fixed',
                         bottom: '0', zIndex: 50, width: '90%'
                     }}>
                    <Box p={1}><CircularProgress size={20} style={{color: '#fff'}}/> Please wait...</Box>
                </Box>
                }
                <List {...props}
                      actions={<CyclesListActions state={state}/>}
                    // filter={{}}
                      filterDefaultValues={defaultFilterValues}
                      filters={<ListFilters/>}
                      sort={{field: 'id', order: 'desc'}}
                      perPage={10}
                      bulkActionButtons={false}
                      exporter={CyclesExport}
                      pagination={<CustomPagination/>}
                >
                    <Datagrid>
                        <FunctionField source={'id'} render={record => <Link
                            href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/cycles/${record.id}/details`}>
                            <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.id}</span>
                        </Link>} label={'Cycle Id.'}/>
                        <FunctionField source={'status'} render={record => (record.kegSerialNumber)}
                                       label={'Kegs'}/>
                        <FunctionField source={'status'} render={record => (record.cycleStatus)}
                                       label={'Cycle Status'}/>               
                        <FunctionField source={'status'} render={record => (record.organisationName)}
                                       label={'Organisation'}/>
                        <FunctionField source={'status'} render={record => (record.orderId)}
                                       label={'Order id'}/>
                        <FunctionField source={'status'} render={record => (record.billingStartAt)}
                                       label={'Start'}/>               
                        <FunctionField source={'status'} render={record => (record.billingStopAt)}
                                       label={'End'}/>
                        <FunctionField source={'status'} render={record => (record.billingStopReason)}
                                       label={'Reason'}/>                                       
                        <FunctionField source={'status'} render={record => (record.age)}
                                       label={'Age'}/>
                        <FunctionField source={'status'} render={record => (record.kegType)}
                                       label={'Keg category'}/>
                    </Datagrid>
                </List>
            </Container>
        </Fragment>
    );

};

export default CyclesList;

