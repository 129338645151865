// @ts-nocheck
import * as React from 'react';
import { useAuthenticated } from 'react-admin';
import { version as uuidVersion } from 'uuid';
import { validate as uuidValidate } from 'uuid';
import {
    Box, Button,
    Container,
    Typography,
} from '@material-ui/core';

import {useParams} from 'react-router-dom';
import Divider from "@material-ui/core/Divider";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Link from "@material-ui/core/Link";
import PageviewIcon from "@material-ui/icons/Pageview";
import {GetCountry, GetOrganisation, ProcessStatusDisplay} from "../../utilities/Common";
import Table from "@material-ui/core/Table";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {makeStyles} from "@material-ui/core/styles";
import {useEffect, useState} from "react";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    tableHeadRadius: {
        borderRadius: 0
    },
    tableHeadRadiusLeft: {
        borderTopLeftRadius: '10px'
    },
    tableHeadRadiusRight: {
        borderTopRightRadius: '10px'
    }
}));

const OrganisationSettings = props => {
    useAuthenticated();
    const { organisationUuid } = useParams();
    const classes = useStyles();
    const [recurringCollectionsUrl, setRecurringCollectionsUrl] = useState(`${process.env.REACT_APP_KEGNOVA_API_URL}/recurring-collections?_end=100&_order=DESC&_sort=id&_start=0&organisationUuid=${organisationUuid}&isActive=1`);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const result = await axios.get(recurringCollectionsUrl, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Cache': 'no-cache',
                        'x-api-key': localStorage.getItem('apiKey')
                    }
                });
                setData(result.data);
                setLoading(false);
            } catch (error) {
                setErrorMessage('Retrieving Recurring Collections failed. ' + (error.response.length > 0) ? error.response.data.message : '');
                setLoading(false);
            }
        })();
    }, [recurringCollectionsUrl]);

    // if (!(uuidValidate(organisationUuid) && uuidVersion(organisationUuid) === 4)) {
    //     return (
    //         <Container>
    //             <Box m={5}>
    //                 <Alert severity="error" style={{ display: 'flex', width: '95%' }}>
    //                     <AlertTitle variant={'h6'}> Error </AlertTitle>
    //                     Invalid Organisation ID. Please check the URL address and try again.
    //                 </Alert>
    //             </Box>
    //         </Container>
    //     )
    // }


    // const loading = useLoading();

    const tableHeadings = [
        {id: 1, name: 'Start Date'},
        {id: 2, name: 'End Date'},
        {id: 3, name: 'Repeat Interval'},
        {id: 4, name: 'Weekly or Monthly'},
        {id: 5, name: 'Day of the Week'},
        {id: 6, name: 'Week of the Month'},
        {id: 7, name: 'Day of the Month'},
        {id: 8, name: 'Template'},
        {id: 9, name: 'Combined Collections?'},
        {id: 10, name: ''}

    ]

    const OrganisationSettingsTableHeader = ({item, index}) => {
        let tableHeaderClass = classes.tableHeadRadius;
        if (index === 0) {
            tableHeaderClass = classes.tableHeadRadiusLeft;
        } else if (index === tableHeadings.length - 1) {
            tableHeaderClass = classes.tableHeadRadiusRight;
        }

        return (
            <TableCell style={{backgroundColor: '#000', color: '#fff'}} className={tableHeaderClass}>
                <Typography variant={'subtitle1'}>{item.name}</Typography>
            </TableCell>
        )
    }

    return (
        <Container>
            <Box m={2}>
                <Typography variant={'h4'}>Organisation Settings</Typography>
            </Box>
            <Box m={1}>
                <Divider/>
            </Box>
            <Box m={2}>
                <Typography variant={'body1'} style={{fontWeight: 'bold'}}>{'Organisation'} <DoubleArrowIcon
                    style={{fontSize: '12px', paddingLeft: '5px', paddingRight: '5px', margin: 0}}/>
                    <Link color="inherit" style={{cursor:"pointer"}} onClick={() => {
                        window.location.href = process.env.REACT_APP_TRACKS_PORTAL_URL + `/#/organisations/${organisationUuid}/overview`;
                    }}>
                        <GetOrganisation uuid={organisationUuid}/>
                    </Link>
                </Typography>
            </Box>
            <Box m={1} display="flex" flexDirection="row-reverse">
                <Box m={1}>
                    <Link href={`/#/organisations/settings/${organisationUuid}/recurringcollections/add`}
                          underline='none' color={'inherit'}>
                        <Button variant={'contained'}> <AddCircleIcon style={{paddingRight: '5px'}}/> Add Recurring
                            Collections</Button>
                    </Link>
                </Box>
            </Box>
            <Box p={2}>

                <Table size={'small'}>
                    <TableHead>
                        <TableRow>
                            {tableHeadings.map(
                                (item, index) =>
                                    (
                                        <OrganisationSettingsTableHeader item={item} index={index}/>
                                    )
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map(
                            item =>
                                (
                                    <TableRow key={item.id}>
                                        <TableCell>
                                            {new Date(item.recurringCollectionStartAt).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell>
                                            {item.recurringCollectionEndAt ? new Date(item.recurringCollectionEndAt).toLocaleDateString() : 'N/A'}
                                        </TableCell>
                                        <TableCell>
                                            {item.separationCount}
                                        </TableCell>
                                        <TableCell>
                                            {item.typeText}
                                        </TableCell>
                                        <TableCell>
                                            {item.dayOfTheWeekText}
                                        </TableCell>
                                        <TableCell>
                                            {item.weekOfMonth}
                                        </TableCell>
                                        <TableCell>
                                            {item.dayOfMonth}
                                        </TableCell>
                                        <TableCell>
                                            {item.templateCollectionRequest}
                                        </TableCell>
                                        <TableCell>
                                            {item.combineCollectionsText}
                                        </TableCell>
                                        <TableCell>
                                            <Link
                                                href={`/#/organisations/settings/${organisationUuid}/recurringcollections/edit/${item.id}`}
                                                underline='none' color={'inherit'}><Button variant={'contained'}
                                                                                           style={{marginRight: '5px'}}><EditIcon
                                                style={{paddingRight: '5px'}}/>Edit</Button></Link>
                                            <Link
                                                href={`/#/organisations/settings/${organisationUuid}/recurringcollections/delete/${item.id}`}
                                                underline='none' color={'inherit'}><Button
                                                variant={'contained'}><DeleteIcon style={{paddingRight: '5px'}}/>Delete</Button></Link>
                                        </TableCell>

                                    </TableRow>
                                )
                        )}
                    </TableBody>
                </Table>


            </Box>
        </Container>
    );
};

export default OrganisationSettings;


