import React from 'react';
import MaterialTable from 'material-table';
import {defaultTableIcons, formatFeeDisplay} from "../../../utilities/Common";
import api from "../../../dataProvider/restApi";
import {Box, Container} from "@material-ui/core";

const OrganisationPSGList = props => {
    const tableRef = React.createRef();


    return (
        <Container>
            <Box p={2}>
                <MaterialTable
                    data={ query => api.get(`${process.env.REACT_APP_KEGNOVA_API_URL}/organisations/psg`, query) }
                    tableRef={tableRef}
                    icons={defaultTableIcons}
                    title="Organisations"
                    options={{
                        filtering: true,
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 10,
                        emptyRowsWhenPaging: true,
                        pageSizeOptions:[5,10,25,50,100,250]
                    }}
                    columns={
                        [
                        { title: 'Organisation Id', field: 'organisationId', defaultSort: 'desc' },
                        { title: 'Organisation Name', field: 'organisationName' },
                        { title: 'Country', field: 'countryName' },
                        { title: 'State', field: 'state' },
                        { title: 'Custom Package', field: 'isCustomPackage' },
                        { title: 'PSA Customer?', field: 'isPsaCustomer' },
                        { title: 'PSA Start Date', field: 'psaStartDate' },
                        { title: 'PSA End Date', field: 'psaEndDate' },
                        { title: 'Current % Discount', field: 'currentPDiscount' },
                        { title: 'Pricing Applicable From', field: 'pricingApplicableFrom' },
                        { title: 'Pricing Applicable To', field: 'pricingApplicableTo' },
                        { title: 'Sku', field: 'skuName' },
                        { title: 'Issue Fee', field: 'issueFee', render: rowData => formatFeeDisplay(rowData.issueFee)},
                        { title: 'Cleaning Fee', field: 'cleaningFee', render: rowData => formatFeeDisplay(rowData.cleaningFee)},
                        { title: 'Freight Per Keg?', field: 'freightPerKegFee', render: rowData => formatFeeDisplay(rowData.freightPerKegFee) },
                        { title: 'Freight Fee - Capital Metro', field: 'freightCapitalMetroFee', render: rowData => formatFeeDisplay(rowData.freightCapitalMetroFee) },
                        { title: 'Freight Fee - Regional Metro', field: 'freightRegionalMetroFee', render: rowData => formatFeeDisplay(rowData.freightRegionalMetroFee) },
                        { title: 'Freight Fee - Regional', field: 'freightRegionalFee', render: rowData => formatFeeDisplay(rowData.freightRegionalFee) },
                        { title: 'Freight Fee - Super Regional', field: 'freightSuperRegionalFee', render: rowData => formatFeeDisplay(rowData.freightSuperRegionalFee)  },
                        { title: 'Freight-Pickup', field: 'freightPickUpFee', render: rowData => formatFeeDisplay(rowData.freightPickUpFee) },
                        { title: 'Next Day Delivery Fee', field: 'expressFee', render: rowData => formatFeeDisplay(rowData.expressFee) },
                        { title: 'Pallet Fee', field: 'palletFee', render: rowData => formatFeeDisplay(rowData.palletFee) },
                        { title: 'Non-Mystar Fee', field: 'nonMystarFee', render: rowData => formatFeeDisplay(rowData.nonMystarFee) },
                        { title: 'Taproom to Venue', field: 'taproomVenueFee', render: rowData => formatFeeDisplay(rowData.taproomVenueFee) },
                        { title: 'Taproom to Distributor', field: 'taproomDistributorFee', render: rowData => formatFeeDisplay(rowData.taproomDistributorFee) },
                        { title: 'Taproom to Producer', field: 'taproomProducerFee', render: rowData => formatFeeDisplay(rowData.taproomProducerFee) },
                        { title: 'Daily Rental', field: 'dailyRentalFee', render: rowData => formatFeeDisplay(rowData.dailyRentalFee)  },
                        { title: 'Timed Fee (no. of days)', field: 'timedFee', render: rowData => formatFeeDisplay(rowData.timedFee) },
                        { title: 'Collection - Regional', field: 'collectionRegionalFee', render: rowData => formatFeeDisplay(rowData.collectionRegionalFee) },
                        { title: 'Collection - Super Regional', field: 'collectionSuperRegionalFee', render: rowData => formatFeeDisplay(rowData.collectionSuperRegionalFee)  },
                        { title: 'Stop at Distributor Fee', field: 'stopAtDistributorFee', render: rowData => formatFeeDisplay(rowData.stopAtDistributorFee) },
                        { title: 'Intermarket Fee', field: 'interCountryFee', render: rowData => formatFeeDisplay(rowData.interCountryFee) },
                    ]}

                />
            </Box>
        </Container>
    )
}

export default OrganisationPSGList;
