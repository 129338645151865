import {AppBar, Button, Drawer, Grid, Paper, TextField, Toolbar, Typography} from "@material-ui/core";
// import Link from "./Link";
// import { Link } from 'react-router-dom';
import Link from "@material-ui/core/Link";
import {
    useParams,
    useLocation,
    useHistory,
    useRouteMatch,
} from "react-router-dom";
import queryString from "query-string";
import React, { Fragment, useState, useMemo } from "react";

import {makeStyles} from "@material-ui/core/styles";

import PersonIcon from '@material-ui/icons/Person';
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import styled, { keyframes } from 'styled-components';
import {fadeIn} from 'react-animations';
import KegstarLogoOnly from "../../assets/images/kegstar-logo-only.svg";
import DashboardIcon from '@material-ui/icons/Dashboard';
import KegIcon from '@material-ui/icons/Dns';
import ProducerIcon from '@material-ui/icons/Business';
import LogicticsIcon from '@material-ui/icons/LocalShipping';
import VenueIcon from '@material-ui/icons/LocalBar';
import FinancialIcon from '@material-ui/icons/Money';
import AdminIcon from '@material-ui/icons/SupervisorAccount';
import SettingsIcon from '@material-ui/icons/Settings';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import SearchIcon from '@material-ui/icons/Search';

import {SearchBar} from "./SearchBar";
import {getMarketColorCode, kegLeaseOrderEnabled} from "./common";
import MarketSwitcherButton from "./MarketSwitcherButton";
import {adminMenu, financialMenu, kegsMenu} from './common/menus';

const animationSubHeader = keyframes`${fadeIn}`;
const AnimateSubHeader = styled.div` 
  animation: 1s ${animationSubHeader};
`;
const useStyles = makeStyles(theme => ({
    appBar: {
        border: 'none'
    },
    searchDrawer: {
        '& .MuiDrawer-paper': {
            width: '100%',
            height: '35%',
        },
    },
    searchBar: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    mainMenuItem: {
        color: '#fff',
        textDecoration: 'none'
    },
    subNavContainer: {
        backgroundColor: getMarketColorCode(),
        maxWidth: '100%'
    },
    subNavContainerTwo: {
        width: '85%'
    },
    subNavItem: {
        margin: '10px',
        color: '#bfbfbf',
        textAlign: 'center'
    },
    subNavItemLink: {
        color: '#fff',
        textDecoration: 'none'
    },
    subNavIcon: {
        verticalAlign: 'middle'
    },
    activeLink: {
        color: getMarketColorCode()
    },
    footer: {
        height: "200px",
        backgroundColor: theme.palette.primary.main,
        width: "100%"
    }
}));

const Header = ({user}) => {
/*    const router = useRouter();*/
    const history = useHistory();

    const classes = useStyles();
    const [state, setState] = React.useState({
        searchBarIsOpen: false,
    });

    const [dashboardMenuIsActive, setDashboardMenuIsActive] = useState(history.location.pathname === '/dashboard' || history.location.pathname === '/');
    const [kegsMenuIsActive, setKegsMenuIsActive] = useState(history.location.pathname.includes("/kegs"));
    const [producersMenuIsActive, setProducersMenuIsActive] = useState(history.location.pathname === '/producers');
    const [logisticsMenuIsActive, setLogisticsMenuIsActive] = useState(history.location.pathname === '/logistics');
    const [venuesMenuIsActive, setVenuesMenuIsActive] = useState(history.location.pathname === '/venues');
    const [financialMenuIsActive, setFinancialMenuIsActive] = useState(history.location.pathname === '/financial');
    const [adminMenuIsActive, setAdminMenuIsActive] = useState(history.location.pathname === '/admin');
    const [settingsMenuIsActive, setSettingsMenuIsActive] = useState(history.location.pathname === '/settings');

    const mainMenu = [
        { name: 'Dashboard', icon: <DashboardIcon />, link: process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/dashboard', onClick: () => handleMenuChange(setDashboardMenuIsActive) },
        { name: 'Kegs', icon: <KegIcon />, link: process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/kegs', onClick: () => handleMenuChange(setKegsMenuIsActive)},
        { name: 'Producers', icon: <ProducerIcon />, link: process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/producers', onClick: () => handleMenuChange(setDashboardMenuIsActive)  },
        { name: 'Logistics', icon: <LogicticsIcon />, link: process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/logistics', onClick: () => handleMenuChange(setDashboardMenuIsActive)},
        { name: 'Venues', icon: <VenueIcon />, link: process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/venues', onClick: () => handleMenuChange(setDashboardMenuIsActive)},
        { name: 'Financial', icon: <FinancialIcon />, link: process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/financial', onClick: () => handleMenuChange(setFinancialMenuIsActive) },
        { name: 'Admin', icon: <AdminIcon />, link: process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/admin', onClick: () => handleMenuChange(setAdminMenuIsActive)},
        { name: 'Settings', icon: <SettingsIcon />, link: process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/settings/info'},
    ];

  /*  const logout = () => api.logout();*/
    const logout = () => {
        window.location.href = process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/logout';
        return false;
    };

    const handleMenuChange = (callback) => {
        setDashboardMenuIsActive(false);
        setKegsMenuIsActive(false);
        setProducersMenuIsActive(false);
        setLogisticsMenuIsActive(false);
        setVenuesMenuIsActive(false);
        setFinancialMenuIsActive(false);
        setAdminMenuIsActive(false);
        setSettingsMenuIsActive(false);
        return callback(true);
    }

    const SubHeaderNavigation = ({menu}) => {
        return(
            <AnimateSubHeader>
                <Container className={classes.subNavContainer}>
                    <Container className={classes.subNavContainerTwo}>
                        <Grid container
                              direction="row"
                              justify="center"
                              alignItems="center">
                                { menu.map((row) => {
                                    if(row.isActive) {
                                            return(
                                                <Grid item xs className={classes.subNavItem}>
                                                    <Link href={row.link} color="inherit" className={classes.subNavItemLink}  activeClassName={classes.activeLink} >
                                                        <span className={classes.subNavIcon}>{row.icon}</span> {row.name}
                                                    </Link>
                                                </Grid>
                                            )
                                        }
                                    })
                                }
                        </Grid>
                    </Container>
                </Container>
            </AnimateSubHeader>
        )
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    return (
        <Fragment>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar style={{ gap: "20px", minHeight: 'inherit'}}>

                    <Box display={'flex'} alignItems="center" justifyContent="center" >
                        <Box>
                            <img src={KegstarLogoOnly} height={40} width={40}  />
                        </Box>
                    </Box>
                    <Box display={'flex'} alignItems="center" justifyContent="center" >
                        <Box m={1}><PersonIcon /></Box> <Box>{ user.firstName } { user.lastName }</Box>
                    </Box>

                    {mainMenu.map((row) =>
                        <Link href={row.link} color="inherit" className={classes.mainMenuItem} activeClassName={classes.activeLink} onClick={row.onClick}>
                            <span style={{verticalAlign: 'middle'}}>{row.icon}</span> {row.name}
                        </Link>
                    )}

                    {user && <>
                        <Link href="#" color="inherit" onClick={logout}  className={classes.mainMenuItem}  activeClassName={classes.activeLink}>
                            <span style={{verticalAlign: 'middle'}}><LogoutIcon />  </span> Log out
                        </Link>
                    </>}
                    {!user && <>
                        <Link href="/login" color="inherit"  className={classes.mainMenuItem}  activeClassName={classes.activeLink}>
                            Log In
                        </Link>
                    </>}

                    <Box  display="flex" flexDirection="row-reverse" maxWidth={250}>
                        <Box>
                            <MarketSwitcherButton marketColor={getMarketColorCode()} />
                        </Box><Box>
                            <Button onClick={toggleDrawer('searchBarIsOpen', true)}>
                                <SearchIcon style={{color: '#fff'}} fontSize={"large"} />
                            </Button>
                        </Box>
                    </Box>

                </Toolbar>

            </AppBar>

            { (dashboardMenuIsActive || producersMenuIsActive || logisticsMenuIsActive || venuesMenuIsActive) &&
            <AnimateSubHeader>
                <Container className={classes.subNavContainer} style={{height: '8px'}} />
            </AnimateSubHeader>
            }

            { kegsMenuIsActive && <SubHeaderNavigation menu={kegsMenu} /> }
            { financialMenuIsActive && <SubHeaderNavigation menu={financialMenu} /> }
            { adminMenuIsActive && <SubHeaderNavigation menu={adminMenu} /> }

            <Drawer className={classes.searchDrawer} anchor={'searchBarIsOpen'} open={state['searchBarIsOpen']}
                    onClose={toggleDrawer('searchBarIsOpen', false)}>
                <SearchBar state={state} setState={setState} />
            </Drawer>

        </Fragment>
    )
}

export default Header;
