import React, {Fragment, useState, useCallback} from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    FunctionField, Filter, ReferenceInput, AutocompleteInput, SelectInput, useLoading, DateInput, ReferenceArrayInput,
    SelectArrayInput, NumberInput,
    useAuthenticated, TextInput
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";

import {
    CustomPagination, getMarketColorCode, formatBusinessDate, OrderStatuses, OrderTypes, setCurrentFilters, applyCurrentFilter
} from "../../utilities/Common";

import {Container, LinearProgress, Box, CircularProgress, Button} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import VenuesDetailsPage from "./VenuesDetailsPage";
import VenuesCollectPage from "./VenuesCollectPage";

export const styles = (theme => ({
    root: {},
    loading: {
        '& .MuiTable-root': {
            display: 'none'
        },
        '& .MuiTypography-body2': {
            display: 'none'
        },
        '& .MuiTablePagination-toolbar': {
            display: 'none'
        }

    },
    orderFilter: {
        width: '10em'
    },
    organisationFilter: {
        width: '18em'
    },
    skuFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    },
    typeFilter: {
        minWidth: '8em'
    },
    statusFilter: {
        minWidth: '6em'
    },
    stateFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    }
}));

const useStyles = makeStyles(styles);

const ListFilters = props => {
    const classes = useStyles();

    return (
        <Filter {...props}>
            {/*<ReferenceInput source="filters.name" reference="organisations" optionValue="name"*/}
            {/*                // filter={{market: localStorage.getItem('mId'), isProducer: 1, isActive: 1}}*/}
            {/*                sort={{ field: 'name', order: 'asc' }}*/}
            {/*                sortDefaultValues={{ field: 'name', order: 'asc' }} label={'Producer'}*/}
            {/*                className={classes.organisationFilter} alwaysOn resettable>*/}
            {/*    <AutocompleteInput optionText="name" optionValue="name" />*/}
            {/*</ReferenceInput>*/}
            {/*    emptyText={'All'} allowEmpty*/}

            <TextInput label={'Organisation Name'} source={'filters.name'} alwaysOn resettable={true}
                       className={classes.organisationFilter}/>
            <TextInput label={'Last Activity'} source={'filters.lastActivity'} alwaysOn resettable={true}
                       className={classes.organisationFilter}/>
            <TextInput label={'Area'} source={'filters.state'} alwaysOn resettable={true}
                       className={classes.organisationFilter}/>
            <TextInput label={'Country'} source={'filters.countryName'} alwaysOn resettable={true}
                       className={classes.organisationFilter}/>


        </Filter>
    )
};

const VenuesList = props => {
    useAuthenticated();
    const loading = useLoading();
    const classes = useStyles();
    const marketId = localStorage.getItem('mId');
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [collectPageOrgId, setCollectPageOrgId] = useState(null);

    const state = {
        setErrorMessage: setErrorMessage,
        setSuccessMessage: setSuccessMessage
    };

    let defaultFilterValues = {market: marketId, tz: Intl.DateTimeFormat().resolvedOptions().timeZone};

    const collectOnClickHandler = (orderId) =>{
        //show the collect page and scroll to the top
        setCollectPageOrgId(orderId);
        window.scrollTo(0, 0);
    };

    const updateCollectValueToNull = useCallback( () => {
        //hide the collect page
        console.log("called");
        setCollectPageOrgId(null);
    }, [setCollectPageOrgId]);

    const [bottomDrawer, setBottomDrawer] = React.useState(false);

    return (
        <Fragment>
            <Container maxWidth={false}>
                {collectPageOrgId && <VenuesCollectPage org_id={collectPageOrgId} updateCollectPageOrgId={updateCollectValueToNull}/>}
            </Container>
            <Container maxWidth={false}>
                {loading && <LinearProgress/>}
                {loading &&
                <Box justifyContent={'center'} justifyItems={'center'} alignItems={'center'} alignContent={'center'}
                     style={{
                         backgroundColor: '#1f2124', color: '#fff', padding: '5px', position: 'fixed',
                         bottom: '0', zIndex: 50, width: '90%'
                     }}>
                    <Box p={1}><CircularProgress size={20} style={{color: '#fff'}}/> Please wait...</Box>
                </Box>
                }
                <List {...props}
                      actions={false}
                      filter={{market_id: marketId}}
                      filters={<ListFilters/>}
                      sort={{field: 'name', order: 'asc'}}
                      perPage={15}
                      bulkActionButtons={false}
                      exporter={false}
                >
                    <Datagrid rowClick="expand" expand={row => <VenuesDetailsPage org_id={row.record.id}/>}>
                    <FunctionField source={''} render={record =>
                        <Button onClick={() => collectOnClickHandler(record.id)} variant={'text'} size={'small'}
                                style={{fontWeight: 'bold', color: '#1887ad'}}>Collect</Button>
                    }
                    />
                        <FunctionField source={'name'} label={'Name'} render={record =>
                            <Link
                                href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.id}/overview`}>
                                                                <span style={{
                                                                    fontWeight: 'bold',
                                                                    color: '#1887ad'
                                                                }}>{record.name}</span>
                            </Link>
                        }
                        />
                        <TextField source="preferred_collector_name" label={'Preferred Collector'} sortable={false}/>
                        <FunctionField source={'is_recurring'} render={record => (<span>{(record.is_recurring == "Yes") 
                        ?                 <Link
                        href={`/#/organisations/settings/${record.uuid}`}>
                        <span
                            style={{color: getMarketColorCode(localStorage.getItem('mId'))}}>Recurring Collection</span>
                        </Link> : ''}</span>) } label={'Regular Collection'} />
                        <FunctionField render={record => 
                        ( formatBusinessDate(record.businessTime)) } label={'Opening Hours'} source={'businessTime'}  />
                        <TextField source="phone" label={'Contact Number'}/>
                        <FunctionField source="contact.first_name"
                                       render={record => `${record.contact_first_name ? record.contact_first_name : '-'} ${record.contact_last_name ? record.contact_last_name : ''}`}
                                       label={'Contact Name'} sortable={false}/>
                        <FunctionField source="kegstar_preferred"
                                       render={record => (record.active === 1 ? 'Yes' : 'No')}
                                       label={'Active'}/>
                        <TextField source="days_since_last_dispatch" label={'Days Since Last Dispatch'} sortBy={'days-since-last-dispatch'}/>
                        <TextField source="holding" label={'Holding'} sortBy={'holding'}/>               
                        <TextField source="avg_holding_age" label={'Avg Holding Age'} sortBy={'avg-holding-age'}/>               
                        <NumberField source="call_action_days_ago" label={'Days Since Activity'}
                                     sortBy={'activity-days'}/>
                        <TextField source="call_type" label={'Last Activity'} sortBy={'last-activity'}/>
                        <TextField source="locality" label={'Locality'} sortBy={'locality'}/>
                        <TextField source="address" label={'Address'} sortBy={'address'}/>
                        <TextField source="state" label={'Area'} sortBy={'state'}/>
                        <TextField source="country_name" label={'Country'} sortBy={'country-name'}/>
                    </Datagrid>
                </List>
            </Container>
        </Fragment>
    );

}

export default VenuesList;