// @ts-nocheck
import * as React from 'react';
import {useState} from 'react';
import axios from 'axios';
import {Box, Button, Typography, Container, Paper, LinearProgress}
    from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    Notification,
    useNotify,
    useGetList,
    ReferenceInput,
    AutocompleteInput,
    DateInput, SimpleForm, Toolbar
} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import PageviewIcon from "@material-ui/icons/Pageview";

import {AnyObject, useFormState} from "react-final-form";
import {GetCountry, GetOrganisation, ProcessStatusDisplay} from "../../utilities/Common";
import CachedIcon from "@material-ui/icons/Cached";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
    buttons: {backgroundColor: theme.palette.primary.main , color: theme.palette.secondary.contrastText },
    toolbar: {
        width: '100%', alignItems: 'center',
    },
    organisationField: {
        width: '250px',
        paddingRight: '10px'
    },
    filterFields: {
        paddingRight: '10px'
    }
}));


const GetDraftChargeProcesses = () => {

    const { data, ids, loading, error } = useGetList(
        'processes/draftcharges',
        { page: 1, perPage: 15 },
        { field: 'id', order: 'DESC' },
        { marketId: localStorage.getItem('mId') }
    );

    if (loading) { return <LinearProgress />; }
    if (error) { return <p>ERROR</p>; }
    const records = Object.values(data).sort((a,b)=> (b.id > a.id ? 1 : -1));

    return (
        <React.Fragment>
            <Box m={2}>
                <Typography variant={'h5'}>
                    <ListAltIcon /> Process List
                </Typography>
            </Box>
            { records.length > 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>

                                </TableCell>
                                <TableCell>
                                    <Typography variant={'subtitle1'}>Date From</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={'subtitle1'}>Date To</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={'subtitle1'}>Run Date</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={'subtitle1'}>Country</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={'subtitle1'}>Organisation</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={'subtitle1'}>Run By</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={'subtitle1'}>Status</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { records.map(
                                item =>
                                    (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                <Link href={`/#/billing/processes/draft/${item.uuid}`} >
                                                    <PageviewIcon fontSize={'large'} />
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                {new Date(item.messagePayload.startDate).toLocaleDateString()}
                                            </TableCell>
                                            <TableCell >
                                                {new Date(item.messagePayload.cutoffDate).toLocaleDateString()}
                                            </TableCell>
                                            <TableCell>
                                                {new Date(new Date(item.runningDate).toISOString().split('T')[0]).toLocaleDateString()}
                                            </TableCell>
                                            <TableCell>
                                                { (item.messagePayload.countryUuid) ? <GetCountry uuid={item.messagePayload.countryUuid} /> : null }
                                            </TableCell>
                                            <TableCell>
                                                { (item.messagePayload.organisationUuid) ? <GetOrganisation uuid={item.messagePayload.organisationUuid} /> : null }
                                            </TableCell>
                                            <TableCell>
                                                {item.userName}
                                            </TableCell>
                                            <TableCell>
                                                <ProcessStatusDisplay processStatusText={item.processStatusText} />
                                            </TableCell>
                                        </TableRow>
                                    )
                            )}
                        </TableBody>
                    </Table>
                :
                <Box m={3}>
                    <Typography variant={'subtitle1'}> No Draft Charges process data. Set your options and click on <span style={{textDecoration: 'underline'}}>Generate Draft Charges</span> to start generating charges for preview.</Typography>
                </Box>
            }
        </React.Fragment>
    );
};

const GenerateDraftCharges = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [warningMessage, setWarningMessage] = useState(false);
    const [valid, setValid] = useState(true);
    const [generateChargeSuccessful, setGenerateChargeSuccessful] = useState(false);

    const validateFields = ({countryUuid, organisationUuid, dateFrom, dateTo}: AnyObject) => {
        const errors = {} as any;
        // console.log(countryUuid, organisationUuid, dateFrom, dateTo);
        var dateFromDate = new Date(dateFrom);
        var dateTodayDate = new Date();
        setValid(true);

        // if (dateFromDate.getMonth() < dateTodayDate.getMonth()) {
        //     errors.dateFrom = [
        //         'Charges has already been generated for the selected month',
        //     ];
        //     setValid(false);
        //     // setWarningMessage('Charges has already been generated for the selected month');
        // }
        if (!countryUuid && !organisationUuid) {
            errors.countryUuid = [
                'Select a country or organisation',
            ];
            errors.organisationUuid = [
                'Select an organisation or country',
            ];
            setValid(false);
            // setWarningMessage('Select a country or organisation');
        }
        if (!dateFrom || !dateTo) {
            errors.dateFrom = [
                'Select a date range',
            ];
            setValid(false);
            // setWarningMessage('Select a date range');
        }
        // setWarningMessage(errors.stringify);
        return errors;
    };

    const handleSubmit = async (e, formState) => {
        e.preventDefault();

        let countryUuid = formState.values['countryUuid'];
        let organisationUuid = formState.values['organisationUuid'];
        let startDate = formState.values['dateFrom'];
        let cutoffDate = formState.values['dateTo'];
        // let userUuid = localStorage.getItem('uUuid');

        let payload = {
            marketId: localStorage.getItem('mId'),
            countryUuid: (countryUuid) ? countryUuid : null,
            organisationUuid: (organisationUuid) ? organisationUuid : null,
            startDate: startDate,
            cutoffDate: cutoffDate
        };

        await generateDraftCharges(payload);

    }

    const generateDraftCharges = async (payload) => {
        setErrorMessage(false);
        setLoading(true);
        try {
            let url = `${process.env.REACT_APP_KEGNOVA_API_URL}/charges/draft/generate`;
            const options = {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',
                    'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')}
            };
            const result = await axios.post(url, payload, options);
            setLoading(false);
            setGenerateChargeSuccessful(true);
            // notify('Draft charge generation initialised.', 'info');
        } catch (error) {
            setLoading(false);
            // console.log(error.response.data.message)
            let errMsg = `Draft charge generation failed! ${error.response.data.message}`;
            setErrorMessage(errMsg);
            // notify(errMsg, 'warning');
        }
    }

    const DraftChargesToolbar = ({classes, ...props}) => {
        const formState = useFormState();

        return(
            <Toolbar {...props} style={{backgroundColor: '#fff', margin: '0px'}}>
                <Box m={1} display="flex" justifyContent="center">
                    <Box m={1}>
                        <Button variant={'contained'} disabled={!valid} onClick={(e)=>handleSubmit(e, formState)}><CachedIcon />&nbsp; Generate Draft Charges</Button>
                    </Box>
                </Box>
            </Toolbar>
        )
    };

    if ( !(localStorage.getItem('role').indexOf('ROLE_ADMIN') > -1) && !(localStorage.getItem('role').indexOf('ROLE_ACCOUNTING') > -1) ) {
        return (
            <Container>
                <Box p={2}>
                    <Alert severity="error" style={{display: 'flex', width: '95%'}}>
                        <AlertTitle variant={'h6'}> Unauthorised </AlertTitle>
                        You are not allowed to access this page
                    </Alert>
                </Box>
            </Container>
        )
    }
    return (
        <Container>

            <Box m={1}>
                <Typography variant={'h4'}>
                    Monthly Draft Charges
                </Typography>
            </Box>
            <Box m={1}>
                <Divider />
            </Box>
            <Box m={1}>
                <Paper style={{display: 'flex'}}>
                    <SimpleForm  validate={validateFields} toolbar={<DraftChargesToolbar classes={classes}/>} >
                        <Alert severity="info" style={{display: 'flex', width: '95%'}} >
                            <AlertTitle>Select the following options to generate monthly draft charges</AlertTitle>
                        </Alert>
                        { generateChargeSuccessful &&

                            <Alert severity="success" style={{display: 'flex', width: '95%'}}>
                                <AlertTitle variant={'h6'}> Success </AlertTitle>
                                Processing Draft charges. Status below will indicate when charges are <strong>Completed</strong>. This may take some time.
                            </Alert>

                        }
                        { errorMessage &&

                        <Alert severity="error" style={{display: 'flex', width: '95%'}}>
                            <AlertTitle variant={'h6'}> Error </AlertTitle>
                            { errorMessage }
                        </Alert>

                        }
                        { warningMessage &&

                        <Alert severity="warning" style={{display: 'flex', width: '95%'}}>
                            <AlertTitle variant={'h6'}> Validation Alert </AlertTitle>
                            { warningMessage }
                        </Alert>

                        }
                        <div style={{display: 'flex', width: '100%'}} >
                            <ReferenceInput source="countryUuid" reference="countries" label={'Country'}
                                            filter={{marketId: localStorage.getItem('mId')}} sort={{id: 'asc'}} className={classes.filterFields} resettable>
                                <AutocompleteInput optionText="name" />
                            </ReferenceInput>
                            <ReferenceInput source="organisationUuid" reference="organisations" label={'Organisation'} className={classes.organisationField}
                                            filter={{market: localStorage.getItem('mId'), isProducer: 1, isActive: 1}}
                                            sort={{name: 'asc'}}
                                            resettable>
                                <AutocompleteInput optionText="name" />
                            </ReferenceInput>
                            <DateInput source="dateFrom" className={classes.filterFields} />
                            <DateInput source="dateTo" className={classes.filterFields} />
                        </div>
                    </SimpleForm>
                </Paper>
            </Box>
            <Box m={3}>
            </Box>
            <Box m={1}>
                <Divider />
            </Box>
            <Box m={3}>
            </Box>
            <Box m={1}>
                { loading ? <LinearProgress /> :  <GetDraftChargeProcesses /> }
            </Box>
            <Notification />

        </Container>
    );
};



export default GenerateDraftCharges;
