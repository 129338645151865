// @ts-nocheck
import {downloadCSV} from 'react-admin';
import jsonExport from 'jsonexport/dist';

const CyclesExport = (records) => {

    const dataForExport = records.map(record => {

        const {
            uuid, displayId, fromLocationId, toLocationId, status, specialInstructions,
            fromOrganisationId, toOrganisationId, dispatchCount,sku,
            ...dataForExport
        } = record; // omit unecessary fields
        dataForExport.skuName = sku.name;

        // dataForExport.fieldName = record.field.name; // add a field
        // dataForExport.kegSerialNumber = (record.kegs) ? record.kegs.serialNumber : null;

        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'id', 'statusText', 'kegsRequested', 'fromOrganisationName', 'toOrganisationName',
            'fromLocationName', 'toLocationName', 'createdAt', 'requestedAt', 'actionedAt', 'skuName',
        ], // order fields in the export
        rename: [
            'Cycle No.', 'Status', 'No. of Kegs', 'From Organisation', 'To Organisation',
            'From Location', 'To Location', 'Placed At', 'Due Date', 'Actioned At', 'Keg Type',
        ]
    }, (err, csv) => {
        let today = new Date();
        downloadCSV(csv, 'Cycles_' + today.toISOString().split('T')[0]); // download as 'posts.csv` file
    });

};

export default CyclesExport;
