import React, {Fragment, useState} from 'react';
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    FunctionField,
    List,
    NumberField,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectArrayInput,
    useAuthenticated,
    useLoading
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";

import {CustomPagination, getMarketColorCode, OrderStatuses} from "../../utilities/Common";

import {Box, CircularProgress, Container, LinearProgress} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import moment from 'moment';
import 'moment-timezone';

export const styles = (theme => ({
    root: {},
    loading: {
        '& .MuiTable-root': {
            display: 'none'
        },
        '& .MuiTypography-body2': {
            display: 'none'
        },
        '& .MuiTablePagination-toolbar': {
            display: 'none'
        }

    },
    orderFilter: {
        width: '10em'
    },
    organisationFilter: {
        width: '18em'
    },
    skuFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    },
    typeFilter: {
        minWidth: '8em'
    },
    statusFilter: {
        minWidth: '6em'
    },
    stateFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    }
}));

const useStyles = makeStyles(styles);

const ListFilters = props => {
    const classes = useStyles();

    return (
        <Filter {...props}>
            <NumberInput source={'id'} label={'ID'} min={0} resettable alwaysOn/>

            <ReferenceInput source="fromLocationOrganisationUuid" reference="organisations"
                            filter={{ isActive: 1}}
                            sort={{field: 'name', order: 'asc'}}
                            sortDefaultValues={{field: 'name', order: 'asc'}} label={'From Venue'}
                            className={classes.organisationFilter} alwaysOn resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>

            <ReferenceInput source="toOrganisationUuid" reference="organisations"
                            filter={{isProducer: 1, isActive: 1}}
                            sort={{field: 'name', order: 'asc'}}
                            sortDefaultValues={{field: 'name', order: 'asc'}} label={'To Producer'}
                            className={classes.organisationFilter} alwaysOn resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
        </Filter>
    )
};

const ToOrganisation = ({record}) => {
    return (
        <React.Fragment>
            {record.toOrganisationName ?
                <Link
                    href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.toOrganisationId}/overview`}>
                    <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.toOrganisationName}</span>
                </Link>
                : null}
        </React.Fragment>
    )
};


const FromOrganisation = ({record}) => {
    return (
        <React.Fragment>
            {record.fromLocationOrganisationName ?
                <Link
                    href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.fromLocationOrganisationId}/locations/view/${record.fromLocationId}`}>
                    <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.fromLocationOrganisationName}</span>
                </Link>
                : '--'}
        </React.Fragment>
    )
};

const LeadsList = props => {
    useAuthenticated();
    const loading = useLoading();
    const classes = useStyles();
    const marketId = localStorage.getItem('mId');
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const state = {
        setErrorMessage: setErrorMessage,
        setSuccessMessage: setSuccessMessage
    };

    // @ts-ignore
    return (
        <Fragment>
            <Container maxWidth={false}>
                {loading && <LinearProgress/>}
                {loading &&
                <Box justifyContent={'center'} justifyItems={'center'} alignItems={'center'} alignContent={'center'}
                     style={{
                         backgroundColor: '#1f2124', color: '#fff', padding: '5px', position: 'fixed',
                         bottom: '0', zIndex: 50, width: '90%'
                     }}>
                    <Box p={1}><CircularProgress size={20} style={{color: '#fff'}}/> Please wait...</Box>
                </Box>
                }
                <List {...props}
                      actions={false}
                      filters={<ListFilters/>}
                      filter={{ marketId: marketId }}
                    // sort={{field: 'id', order: 'desc'}}
                      perPage={10}
                      bulkActionButtons={false}
                      pagination={<CustomPagination/>}
                >
                    <Datagrid>
                        <NumberField source="id" label={'ID'}/>
                        <FunctionField source={'fromOrganisationName'}
                                       render={record => <FromOrganisation record={record}/>} label={'From Venue'} sortable={false}/>
                        <FunctionField source={'toOrganisationName'}
                                       render={record => <ToOrganisation record={record}/>} label={'To Producer'} sortable={false}/>
                        <FunctionField source={'createdByFirstName'} render={record =>
                            <span>{ `${record.createdByFirstName} ${record.createdByLastName === "Kegstar Apps" ? '' : record.createdByLastName}` }</span>
                        } label={'Created By'} sortable={false} />
                        {/*{timeZone: localStorage.getItem('timezone')}*/}
                        <FunctionField source={'createdAt'} render={record =>
                            <span>{record.createdAt ? moment(record.createdAt).tz(localStorage.getItem('timezone')).format('Do MMMM, YYYY h:mm A') : '-'}</span>
                        }  label={'Created On'}  />
                    </Datagrid>
                </List>
            </Container>
        </Fragment>
    );

};

export default LeadsList;

