// @ts-nocheck
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const CollectionsExport = (records) => {
    const dataForExport = records.map(record => {

        const {
            area,	day,	time,	openDays,	collectFromTime,	collectToTime,	callType,	createdAt,	appCreatedAt,
            originLocationId,	originOrganisationId,	originOrganisation,	originOrganisationUuid,	collectorLocationId,
            collectorOrganisationId,	collectorOrganisationUuid,	originCountry,	originCountryId,
            collectorCountry,	collectorCountryId,	createdByUserFirstName,	createdByUserLastName,
            createdByUserId,	preferredCollectorUuid,	preferredCollectorId,	appCreatedAtText,	callTypeText,	status,
            displayId, collectorOrganisation,	originLocationAddressLine1,	originLocationAddressLine2,	collectorAssignedAt,	updatedAt,	collectorContactFirstName,
            collectorContactLastName,	statusText,
            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field
        // dataForExport.kegSerialNumber = (record.kegs) ? record.kegs.serialNumber : null;

        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'id',
            'scanCount',
            'collectedCount',
            'callTypeText',
            'createdByUserFullName',
            'collectorContactFullName',
            'collectorContactPhone',
            'collectorContactEmail',
            'openDaysText',
            'collectFromTimeText',
            'collectToTimeText',
            'originLocationId',
            'originLocation',
            'originLocationAddress',
            'originLocationAddressDetails',
            'fromLocality',
            'fromPostcode',
            'fromState',
            'originCountry',
            'fromLatitude',
            'fromLongitude',
            'fromCollectionNote',
            'preferredCollectorName',
            'approxQuantity',
            'collectorLocation',
            'collectorAssignedAtText',
            'deliverToNote',
            'createdAtText',
            'updatedAtText'

        ], // order fields in the export,
        rename:[
            'ID',
            'Scan count',
            'Collected count',
            'Call Type Text',
            'Created By User',
            'Contact User Name',
            'Contact User Email',
            'Contact User Phone',
            'Open Days Text',
            'Collect From Time Text',
            'Collect To Time Text',
            'From Location Id',
            'From Location Name',
            'From Location Address',
            'From Location Address Details',
            'From Location Locality',
            'From Location Postcode',
            'From Location State',
            'From Location Country',
            'From Location Latitude',
            'From Location Longitude',
            'From Location Collection Note',
            'From Location Preferred Collector Operating Name',
            'Approx Quantity',
            'Collector Location',
            'Collector Assigned At',
            'Deliver To Note',
            'Created At',
            'Updated At'
        ]
    }, (err, csv) => {
        let today = new Date();
        downloadCSV(csv, 'Collections_'+ today.toISOString().split('T')[0]); // download as 'posts.csv` file
    });

};

export default CollectionsExport;
