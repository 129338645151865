// @ts-nocheck
import * as React from 'react';
import { useSelector } from 'react-redux';
import {Layout, LayoutProps, Loading, Sidebar, useAuthState, useAuthenticated} from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { darkTheme, lightTheme } from './themes';
import { AppState } from '../types';
import {makeStyles} from "@material-ui/core/styles";
import KegnovaLoadingScreen from "../components/loaders/KegnovaLoadingScreen";
import {CustomNotification} from "../utilities/Common";

const useStyles = makeStyles(
    theme => ({
        layout: {
            marginTop: 0
        }
    })
);
// const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />;
const CustomSidebar = (props: any) => <React.Fragment />;

export default (props: LayoutProps) => {

    const classes =  useStyles();
    const theme = useSelector((state: AppState) =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );

    // useAuthenticated();
    const { loading, authenticated } = useAuthState();

    if (loading) {
        return <KegnovaLoadingScreen />;
    }

    return (
        <Layout
                {...props}
            appBar={AppBar}
            sidebar={CustomSidebar}
            menu={Menu}
            theme={theme}
            notification={CustomNotification}
        />
    );
};
