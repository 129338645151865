// @ts-nocheck
import * as React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import {Box, Container}
    from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import OrganisationPSGList from "../../resources/organisations/psg/OrganisationPSGList";

const useStyles = makeStyles(theme => ({
    formRoot: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '22ch',
        },
    }
}));

const Pricing = () => {
    let {uuid} = useParams();

    return (
        <Container>
            <Box p={2}>
                <OrganisationPSGList basePath={'/packagesetgroups'} resource={'packagesetgroups'} />
            </Box>
        </Container>
    );
};

Pricing.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

export default Pricing;
