import {Box, Button, Container} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import RefreshIcon from "@material-ui/icons/Refresh";
import * as React from "react";

const EmptyAlertBox = ({message}) => {

    return (
        <Container>
            <Box p={2}>

                <Box m={1}>
                    <Box m={1}>

                        <Alert severity="warning">
                            <AlertTitle variant={'h6'}> No Data </AlertTitle>
                            { message }
                        </Alert>

                    </Box>
                    <Box m={3}>
                    </Box>
                    <Box m={1}>
                        <Button color="primary" variant="contained"
                                onClick={() => {
                                    window.location.reload(false)
                                    // setHasErrors(false); // setLoading(false);// setShowUpdateFees(false);
                                } }>
                            <RefreshIcon/> Try again </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

export default EmptyAlertBox;
