import * as React from 'react';
import {cloneElement, useMemo, FC, ReactElement, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    sanitizeListRestProps,
    Identifier,
    SortPayload,
    Exporter,
    useListContext,
    useResourceContext,
    useResourceDefinition,
} from 'ra-core';
import {Box, Button, ToolbarProps} from '@material-ui/core';

import TopToolbar from '../../components/layout/TopToolbar';
import CreateButton from '../../components/button/CreateButton';
import ChargesExportButton from "./ChargesExportButton";

const ChargesListActions: FC<ListActionsProps> = props => {
    const { className, exporter, filters, updateFiltersAndTotal, openDrawer, ...rest } = props;
    const {
        currentSort,
        displayedFilters,
        filterValues,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext(props);
    const resource = useResourceContext(rest);
    const { hasCreate } = useResourceDefinition(rest);

    useEffect(()=>{
        updateFiltersAndTotal(total, filterValues, currentSort);
    }, [total]);

    const handleSelectAll = () =>{
        openDrawer()
    };

    return useMemo(
        () => (
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
                {hasCreate && <CreateButton basePath={basePath} />}
                {exporter !== false && (
                    <ChargesExportButton
                        disabled={total === 0}
                        resource={resource}
                        sort={currentSort}
                        filterValues={filterValues}
                    />
                )}
                <Button onClick={handleSelectAll} variant={'text'} style={{color:'#000', marginTop:'-3px'}} > Select All </Button>
            </TopToolbar>
        ),
        [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
    );
};

ChargesListActions.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    currentSort: PropTypes.any,
    displayedFilters: PropTypes.object,
    exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    filters: PropTypes.element,
    filterValues: PropTypes.object,
    hasCreate: PropTypes.bool,
    resource: PropTypes.string,
    onUnselectItems: PropTypes.func.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.any),
    showFilter: PropTypes.func,
    total: PropTypes.number,
};

ChargesListActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};

export interface ListActionsProps extends ToolbarProps {
    currentSort?: SortPayload;
    className?: string;
    resource?: string;
    filters?: ReactElement<any>;
    displayedFilters?: any;
    exporter?: Exporter | boolean;
    filterValues?: any;
    permanentFilter?: any;
    hasCreate?: boolean;
    basePath?: string;
    selectedIds?: Identifier[];
    onUnselectItems?: () => void;
    showFilter?: (filterName: string, defaultValue: any) => void;
    total?: number;
    updateFiltersAndTotal?: any;
    openDrawer?: any;
}

export default ChargesListActions;
