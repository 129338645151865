// @ts-nocheck
import * as React from 'react';
import { FC } from 'react';
import {
    List,
    ListProps,
    Datagrid,
    TextField,
    FunctionField,
    DateField,
    Filter,
    FilterProps, TextInput, SelectInput, useAuthenticated, useLoading, ReferenceInput, AutocompleteInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Container, LinearProgress} from "@material-ui/core";
import {applyCurrentFilter, formatFeeDisplay, setCurrentFilters} from "../../../utilities/Common";

import CountryPackageExport from "./CountryPackageExport";
import ListActions from "../../../components/list/ListActions";

const useStyles = makeStyles(theme => ({
    links: {
        color: '#2d9edf'
    },
    hiddenOnSmallScreens: {
        display: 'table-cell',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    cycleStatusFilter: {
        width: '50px'
    }
}));


const ListFilters = (props: Omit<FilterProps, 'children'>) => {
    const classes = useStyles();
    setCurrentFilters(props,
        ['countryUuid', 'isCustomPackage', 'skuName']);
    return (
        <Filter {...props} style={{float: 'left', paddingTop: '10px'}} >

            <ReferenceInput source="countryUuid" reference="countries" label={'Country'}
                            filter={{marketId: localStorage.getItem('mId')}} sort={{id: 'asc'}} alwaysOn resettable>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <SelectInput source="isCustomPackage" label={'Custom Package?'} choices={[
                { id: 'Yes', name: 'Yes' },
                { id: 'No', name: 'No' }
            ]} alwaysOn resettable={true} style={{minWidth: '170px'}} />

            <ReferenceInput source="skuName" reference="skus" label={'SKU'} resettable >
                <AutocompleteInput optionValue={'name'} optionText="name" />
            </ReferenceInput>

        </Filter>
    )
}

const RecordRowClick = (id, basePath, record) => {
    // console.log(id, basePath, record);
    let url = `/billing/packages/countries/${record.countryUuid}`;
    if(record.skuUuid) {
        url = `/billing/packages/countries/${record.countryUuid}/${record.skuUuid}`;
    }
    return url;
};

const CountryPackageList: FC<ListProps> = props => {
    useAuthenticated();
    const loading = useLoading();
    const classes = useStyles();
    let defaultFilterValues = {marketId: localStorage.getItem('mId')};
    defaultFilterValues = applyCurrentFilter(defaultFilterValues,
        ['countryUuid', 'isCustomPackage', 'skuName']);

    return (
        <React.Fragment>
            <Container style={{width: 'auto', overflowX: 'scroll', maxWidth: '100vw', padding: '8px'}}>
                { loading &&
                <Box>
                    <LinearProgress />
                </Box>
                }
                <List
                    {...props}
                    bulkActionButtons={false}
                    actions={<ListActions />}
                    filters={<ListFilters />}
                    filter={{marketId: localStorage.getItem('mId')}}
                    filterDefaultValues={defaultFilterValues}
                    perPage={10}
                    sort={{ field: 'countryId', order: 'desc' }}
                    exporter={CountryPackageExport}
                >
                    <Datagrid style={{ maxWidth: '1356px' }} rowClick={RecordRowClick}>
                        <TextField label={'Id'} source={'countryId'} />
                        <TextField label={'Country'} source={'countryName'} style={{paddingRight: '65px'}} />

                        <DateField label={'Pricing Applicable From'} source={'pricingApplicableFrom'} />
                        <DateField label={'Pricing Applicable To'} source={'pricingApplicableTo'} />
                        <TextField label={'Sku'} source={'skuName'} />

                        <FunctionField render={record => ( formatFeeDisplay(record.issueFee)) } label={'Issue Fee'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.fixedFee)) } label={'Fixed Fee'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.cleaningFee)) } label={'Cleaning Fee'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.selfDistributionFixedDiscount)) } label={'Self Dist Fixed Discount'} />

                        <FunctionField render={record => ( formatFeeDisplay(record.freightCapitalMetroFee)) } label={'Freight Fee - Capital Metro'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.freightRegionalMetroFee)) } label={'Freight Fee - Regional Metro'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.freightRegionalFee)) } label={'Freight Fee - Regional'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.freightSuperRegionalFee)) } label={'Freight Fee - Super Regional'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.freightPickUpFee)) } label={'Freight-Pickup'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.expressFee)) } label={'Next Day Delivery Fee'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.palletFee)) } label={'Pallet Fee'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.nonMystarFee)) } label={'Non-Mystar Fee'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.taproomVenueFee)) } label={'Taproom to Venue'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.taproomDistributorFee)) } label={'Taproom to Distributor'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.taproomProducerFee)) } label={'Taproom to Producer'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.dailyRentalFee)) } label={'Daily Rental'} />
                        <TextField label={'Timed Fee'} source={'timedFee'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.collectionRegionalFee)) } label={'Collection - Regional'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.collectionSuperRegionalFee)) } label={'Collection - Super Regional'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.stopAtDistributorFee)) } label={'Stop at Distributor Fee'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.interCountryFee)) } label={'Intermarket Fee'} />
                    </Datagrid>
                </List>

            </Container>
        </React.Fragment>
    );
};

export default CountryPackageList;
