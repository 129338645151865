// @ts-nocheck
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const OrdersExport = (records) => {

    const dataForExport = records.map(record => {
        //
        //
        const {
            id, uuid, displayId, toOrganisationIsFriendly, actionedAt,	shippingMethod,	forecastType,
            orderStatus, orderType, skuId,
            isExpress,	nonMystarChargeable, createdAt,	createdBy,	updatedAt,	updatedBy,	deletedAt,	deletedBy, cleanKegs,
            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field
        // dataForExport.kegSerialNumber = (record.kegs) ? record.kegs.serialNumber : null;

        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'orderId', 'orderStatusText', 'orderTypeText', 'forecastTypeText', 'shippingMethodText',
            'isExpressOrderText', 'kegsRequested', 'poNumber', 'toOrganisationId', 'toOrganisationName', 'toOrganisationIsFriendlyText', 'toOrganisationContactName', 'toOrganisationContactEmail',
            'toOrganisationContactPhone', 'createdAtText', 'requestedAt', 'actionedAtText', 'toLocationId', 'toLocationName', 'toLocationCountry', 'toLocationState',
            'fromOrganisationId', 'fromOrganisationName', 'fromLocationId', 'fromLocationName', 'fromLocationCountry', 'fromLocationState', 'skuName'
        ], // order fields in the export
        rename:[
            'Order Id', 'Status', 'Type', 'Forecast Type', 'Shipping Method',
            'Express Order', 'Kegs Requested', 'PO Number', 'To Organisation Id', 'To Organisation Name', 'To Organisation Is Friendly', 'To Organisation Contact Name', 'To Organisation Contact Email',
            'To Organisation Contact Phone', 'Created At', 'Requested At', 'Actioned At', 'To Location Id', 'To Location Name', 'To Location Country', 'To Location State',
            'From Organisation Id', 'From Organisation Name','From Location Id', 'From Location Name', 'From Location Country', 'From Location State', 'SKU'
        ]
    }, (err, csv) => {
        let today = new Date();
        downloadCSV(csv, 'Orders_'+ today.toISOString().split('T')[0]); // download as 'posts.csv` file
    });

};

export default OrdersExport;
