import * as React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Box, Button, Container, Typography, Grid, Chip, CircularProgress } from '@material-ui/core';
import Divider from "@material-ui/core/Divider";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Link from "@material-ui/core/Link";
import axios from "axios";
import dateFormat from "dateformat";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    FunctionField, Filter, ReferenceInput, AutocompleteInput, SelectInput, useLoading, DateInput, ReferenceArrayInput,
    SelectArrayInput, NumberInput,
    useAuthenticated, TextInput,
    Confirm,
    useListContext,
    useCreate, useRefresh
} from 'react-admin';
import DispatchesList from "../../resources/dispatches/OrderDispatchList";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Alert } from "@material-ui/lab";
import InfoIcon from '@material-ui/icons/Info';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12)
    },
}))(Tooltip);


const DisplayOrderTotalValue = ({ data }) => {
    let backgroundColor = '#000';
    if (data.status === '4') {
        backgroundColor = '#5cab00';
    } else if (data.status === '5') {
        backgroundColor = '#a90018';
    }
    return <Chip label={parseFloat(data.total).toLocaleString("en-US")} color={'secondary'} style={{ backgroundColor: backgroundColor, color: '#fff' }} size="small" />
}

const Orders = () => {

    const [loading, setLoading] = useState(false);

    const dispatchesProps = {
        basePath: "dispatches",
        resource: "dispatches"
    }
    const [open, setOpen] = useState(false);
    const [openForce, setOpenForce] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);
    const [openSubmit, setOpenSubmit] = useState(false);

    const [title, setTitle] = useState();

    const [content, setContent] = useState();
    
    const [handleOption, setHandleOption] = useState();


    const [errorMessage, setErrorMessage] = useState(false);
    const {orderId} = useParams();

    const [successMessage, setSuccessMessage] = useState(false);

    const orderDetailsUrl = `${process.env.REACT_APP_KEGNOVA_API_URL}/tracks/orders/${orderId}`;

    const [data, setData] = useState([]);
    
    const handleResendConfirm = () => setOpen(true);
    const handleForceComplete = () => setOpenForce(true);
    const handlecancelOrder = () => setOpenCancel(true);
    const handleReSubmitOrder = () => setOpenSubmit(true);

    const handleDialogClose = () => {
        setOpen(false);
        setOpenForce(false);
        setOpenCancel(false);
        setOpenSubmit(false);
    }    


    const [orderSummaryData, setOrderSummaryData] = useState(null);
    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const result = await axios.get(orderDetailsUrl, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey') } });
                console.warn(result.data)
                setData(result.data);
                setLoading(false);
            } catch (error) {
                setErrorMessage('Retrieving Recurring Collections failed. ' + (error.response.length > 0) ? error.response.data.message : '');
                setLoading(false);
            }
        })();
    }, [orderDetailsUrl]);

    const viewPdf = () => {
        window.location.replace(`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/orders/${orderId}/details/pdf`);
    };

    const resendConfirmation = () => {
        
        setOpen(false);
        
        try {       
            let url = `${process.env.REACT_APP_TRACKS_URL}/api/orders/${orderId}/resend-confirmation`;
            const options = {
                headers: {
                    'x-api-key': localStorage.getItem('apiKey'),
                }
            };
            const payload = {};
            const result =  axios.post(url, data, options);
            alert('Confirmation Mail sent');
            setErrorMessage(null);
            setSuccessMessage("Resend mail comeplted")
        } catch (error) {
            setErrorMessage('Error in force completing the error');
            setSuccessMessage(null);
        }
    };
    
    const viewfees = () => {
        let url = `/#/billing/packages/orders/view/${data.uuid}`;
        window.location.replace(url);
    };
    
    const updatefees = () => {
        let url = `/#/billing/packages/orders/edit/${data.uuid}`;
        window.location.replace(url);
    };
    
    const forceComplete = async () => {
        setOpenForce(false);
        try {
            let url = `${process.env.REACT_APP_TRACKS_URL}/api/orders/${orderId}/complete/force`;
            const options = {
                headers: {
                    'x-api-key': localStorage.getItem('apiKey'),
                }
            };
            const payload = {};
            const result = await axios.get(url, options);
            var response = result.data;
            data.statusText = response['statusText'];
            alert('Order marked as completed');
            setErrorMessage(null);
            setSuccessMessage("order force comeplted")
        } catch (error) {
            setErrorMessage('Error in force completing the error');
            setSuccessMessage(null);
        }
    };
    
    const edit = () => {
        window.location.replace(`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/orders/${orderId}/modify`);
    };
    
    const updateActioned = () => {
        window.location.replace(`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/orders/${orderId}/action`);
    };

    const cancelOrder = async () => {
        setOpenCancel(false);

        try {
            
            let url = `${process.env.REACT_APP_TRACKS_URL}/api/orders/${orderId}/cancel`;
            const options = {
                headers: {
                    'x-api-key': localStorage.getItem('apiKey'),
                }
            };
            const payload = {};
            const result = axios.post(url, data, options);
            var response = result.data;
            data.statusText = response.statusText;
            alert('Order Cancelled')
            setSuccessMessage("order force comeplted")
        } catch (error) {
            setErrorMessage('Error in force completing the error');
            setSuccessMessage(null);
        }
    };

    const reSubmitOrder = async () => {
        setOpenSubmit(false);
        try {
            
            let url = `${process.env.REACT_APP_TRACKS_URL}/api/orders/${orderId}/submit`;
            const options = {
                headers: {
                    'x-api-key': localStorage.getItem('apiKey'),
                }
            };
            const payload = {};
            const result = await axios.get(url, options);
            var response = result.data;
            data.statusText = response['statusText'];
            setErrorMessage(null);
            alert("Re-Submitted Order");
            setSuccessMessage("order force comeplted")
        } catch (error) {
            setErrorMessage('Error in force completing the error');
            setSuccessMessage(null);
        }
    };


    return (
        <Container>
            <Box className='orderDetails'>
                <Box className='orderDetailsList'>
                    <Grid container spacing={3} alignItems="stretch">
                        <Grid item xs={12} sm={4}>
                            <Table>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant={'h5'}>Order : <span style={{fontWeight: 'lighter'}}>{orderId}</span></Typography>                                        
                                    </TableCell>
                                    <TableCell>
                                            <Typography variant={'h6'}>{data.kegsRequested} KEGS</Typography>    
                                        
                                    </TableCell>
                                </TableRow>
                            </Table>            
                         </Grid>
                         <Grid item xs={8} sm={8}>
                            <Grid container spacing={3} alignItems="stretch">
                                <Grid item xs={12} sm={6}>
                                    <Link href={process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/organisations/'+(data.orderToLocationOrgId)+'/orders'} >
                                        <span style={{fontWeight: 'bold', color: '#1887ad'}}><p>View Producer's Orders</p></span>
                                    </Link>
                                </Grid>
                                <Grid className = "orderDetails" item xs={12} sm={6}>
                                    
                                    <Button variant={'contained'} size={'small'} onClick={handleResendConfirm}>Resend confirmation</Button>

                                    {data.status == 5 ? (
                                    <Button variant={'contained'} size={'small'} onClick={handleReSubmitOrder}>Re-submit</Button>
                                    ) : null}

                                    {data.status != 5 ? (
                                    <Button variant={'contained'} size={'small'} onClick={viewfees}>View Fees</Button>
                                    ) : null}

                                    <Button variant={'contained'} size={'small'} onClick={updatefees}>Update Fees</Button>

                                    {data.status == 1 || data.status == 2 || data.status == 3 ? (
                                    <Button variant={'contained'} size={'small'} onClick={handleForceComplete} >Force complete</Button>
                                    ) : null}

                                    {data.status == 1 || data.status == 2 || data.status == 3 ? (
                                    <Button variant={'contained'} size={'small'} onClick={handlecancelOrder}>Cancel</Button>
                                    ) : null}

                                    <Button variant={'contained'} size={'small'} onClick={edit}>Edit</Button>

                                    {data.status == 4 ? (
                                    <Button variant={'contained'} size={'small'} onClick={updateActioned}>Update Actioned</Button>
                                    ) : null}

                                    <Button variant={'contained'} size={'small'} onClick={viewPdf}>View Pdf</Button>

                                    <Confirm
                                        isOpen={open}
                                        title="Resend Confirmation"
                                        content = "Are you sure you want to resend confirmation?"
                                        onConfirm={resendConfirmation}
                                        onClose={handleDialogClose}
                                    />
                                    <Confirm
                                        isOpen={openSubmit}
                                        title="Resubmit Order"
                                        content = "Are you sure you want to resend confirmation?"
                                        onConfirm={reSubmitOrder}
                                        onClose={handleDialogClose}
                                    />
                                    <Confirm
                                        isOpen={openForce}
                                        title="Force Complete order"
                                        content = "Are you sure you want to resend confirmation?"
                                        onConfirm={forceComplete}
                                        onClose={handleDialogClose}
                                    />
                                    <Confirm
                                        isOpen={openCancel}
                                        title="Cancel Confirmation"
                                        content = "Are you sure you want to cancel the order?"
                                        onConfirm={cancelOrder}
                                        onClose={handleDialogClose}
                                    />

                                </Grid>
                             </Grid>   

                         </Grid>   


                        
                        <Grid item xs={12} sm={4}>

                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <span style={{fontWeight: 'bold'}}> Order Placed</span>
                                        </TableCell>
                                        <TableCell>
                                        {dateFormat(data.createdAt, "d mmm, yyyy, h:MM TT")}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <span style={{fontWeight: 'bold'}}> Pricing</span>
                                        </TableCell>
                                        <TableCell>
                                            {data.price}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <span style={{fontWeight: 'bold'}}> Shipping Method</span>
                                        </TableCell>
                                        <TableCell>
                                            {data.shippingMethodText}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <span style={{fontWeight: 'bold'}}> Logistics</span>
                                        </TableCell>
                                        <TableCell>
                                            {data.orderFromLocationOrgName}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <span style={{fontWeight: 'bold'}}> Freight Contact</span>
                                        </TableCell>
                                        <TableCell>
                                            {data.deliveryContactFirstName} {data.deliveryContactLastName}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <span style={{fontWeight: 'bold'}}> Delivery Actioned</span>
                                        </TableCell>
                                        <TableCell>
                                            {dateFormat(data.actionedAt, "d mmm, yyyy, h:MM TT")}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <span style={{fontWeight: 'bold'}}> Due Date</span>
                                        </TableCell>
                                        <TableCell>
                                            {dateFormat(data.requestedAt, "d mmm, yyyy, h:MM TT")}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <span style={{fontWeight: 'bold'}}> Status</span>
                                        </TableCell>
                                        <TableCell>
                                            {data.statusText}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <span style={{fontWeight: 'bold'}}> Forecast type</span>
                                        </TableCell>
                                        <TableCell>
                                            {data.forecastTypeText}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <span style={{fontWeight: 'bold'}}> Kegtype</span>
                                        </TableCell>
                                        <TableCell>
                                            {data.skuName}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <span style={{fontWeight: 'bold'}}> Clean Kegs</span>
                                        </TableCell>
                                        <TableCell>
                                            {data.cleanKegs ? 'Clean': ''}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <span style={{fontWeight: 'bold'}}> Keg Collars</span>
                                        </TableCell>
                                        <TableCell>
                                            {data.isKegCollarsRequired ? 'Yes': 'No'}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <span style={{fontWeight: 'bold'}}> Next Day Delivery</span>
                                        </TableCell>
                                        <TableCell>
                                            {data.isExpressOrder ? 'Yes':' No'}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <span style={{fontWeight: 'bold'}}> Type</span>
                                        </TableCell>
                                        <TableCell>
                                            {data.orderType}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <span style={{fontWeight: 'bold'}}> Created By</span>
                                        </TableCell>
                                        <TableCell>
                                            {data.createdByUserFirstName} {data.createdByUserLastName}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                            <Divider />
                        </Grid>

                        <Grid item xs={8} sm={8}>

                            <Grid container spacing={3} alignItems="stretch">

                                <Grid item xs={12} sm={6}>    
                                    <p>From <br/>
                                    <Link href={process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/organisations/'+(data.orderFromLocationOrgId)+'/overview'} >
                                        <span style={{fontWeight: 'bold', color: '#1887ad'}}>
                                            {data.orderFromLocationOrgName}
                                        </span>    
                                    </Link>
                                    <br/><br/><br/>
                                    <Link href={process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/organisations/'+(data.orderFromLocationOrgId)+'/location/view/'+(data.orderFromLocationId)} >
                                        <span style={{fontWeight: 'bold', color: '#1887ad'}}>
                                            {data.orderFromLocationName}
                                        </span>
                                    </Link>
                                    <br/><br/>
                                    {data.orderFromLocationAddress}</p>

                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <p>To <br/>
                                    <Link className="blue" href={process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/organisations/'+(data.orderToLocationOrgId)+'/overview'} >
                                        <span style={{fontWeight: 'bold', color: '#1887ad'}}>
                                            {data.orderToLocationOrgName}
                                        </span>
                                    </Link>
                                    <br/><br/><br/>
                                    <Link href={process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/organisations/'+(data.orderToLocationOrgId)+'/location/view/'+(data.orderToLocationId)} >
                                        <span style={{fontWeight: 'bold', color: '#1887ad'}}>
                                            {data.orderToLocationName}
                                        </span>    
                                    </Link>
                                    <br/><br/>
                                    {data.orderToLocationAddress}</p>
                                </Grid>
                        
                            </Grid>   

                            <Divider/>

                            <Typography variant = "h6"> Special Instruction</Typography>
                            {data.specialInstructions}


                        </Grid>
                    
                    </Grid>
                </Box>
                <br/>
                <Typography variant={'h6'}>
                {data.dispatchesCount} Dispatch
                </Typography>
                <DispatchesList {...dispatchesProps} />

            </Box>
            
        </Container>
    );
};

export default Orders;
