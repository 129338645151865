import * as React from "react";
import {useState, useEffect} from "react";
import {
    Box,
    Button,
    ButtonGroup,
    TableContainer,
    Paper,
    TextField,
    Card,
    CardContent,
    CardActions,
    Container,
    Grid,
    Typography
} from "@material-ui/core";
import {AutocompleteInput, NumberInput, ReferenceInput, SimpleForm, TextInput, Toolbar} from "react-admin";

import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import SaveIcon from "@material-ui/icons/Save";
import NoteAdd from "@material-ui/icons/NoteAdd";
import AddIcCall from "@material-ui/icons/AddIcCall";
import axios from "axios";
import Link from "@material-ui/core/Link";
import {makeStyles} from "@material-ui/core/styles";
import {useFormState} from "react-final-form";

import moment from 'moment';
import 'moment-timezone';

const useStyles = makeStyles(theme => ({
    dateFields: {
        minWidth: '230px',
        margin: '10px'
    },
    textFields: {
        minWidth: '220px',
        margin: '10px',
    },
    optionTextFields: {
        minWidth: '450px',
        marginTop: '10px',
        marginBottom: '10px',
    },
    dowTextField: {
        minWidth: '220px',
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '10px'
    },
    optionFirstHiddenTextFields: {
        minWidth: '220px',
        marginTop: '10px',
        marginBottom: '10px',
        display: 'none'
    },
    hiddenTextFields: {
        minWidth: '220px',
        margin: '10px',
        display: 'none'
    },
    selectFields: {
        minWidth: '220px',
        margin: '10px'
    },
    selectBooleanFields: {
        minWidth: '170px',
        margin: '10px'
    },

    LinkFields: {
        color: "#4183c4",
        padding: "20px"
    },
    hiddenRadioFields: {
        display: 'none',
        paddingRight: 0,
    },
    radioFields: {
        display: 'unset',
        paddingRight: 0,
        marginTop: '10px'
    },
    buttonContained: {
        color: 'white',
        backgroundColor: 'black'
    },
    buttonOutlined: {
        color: 'black',
        backgroundColor: '#fafafa'
    },
    gridRowStyle: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    noteFormStyle: {
        display: 'block',
        maxWidth: 345
    },
    noteFormHiddenStyle: {
        display: 'none',
        maxWidth: 345
    }
}));

const ProducersDetailsPage = props => {
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const classes = useStyles();

    //for fetching the call activity
    const urlCallActivity = `https://q98zqbqajj.execute-api.ap-southeast-2.amazonaws.com/beta/organisations/call-activity?cache_buster=${+new Date()}&org_id=${props.org_id}`;
    const [dataCallActivity, setDataCallActivity] = useState(null);
    const [shouldFetchCallActivityApiCount, setShouldFetchCallActivityApiCount] = useState(0);

    // for fetching the collection requests
    const [urlCollectionRequests, setUrlCollectionRequests] = useState(`https://staging-api2.kegstar.com/api/collection-requests?fromOrganisationId=${props.org_id}&_sort=id&_order=asc`);
    const [dataCollectionRequests, setDataCollectionRequests] = useState(null);

    // for fetching the notes list
    const [urlOrganisationNotes, setUrlOrganisationNotes] = useState(`https://q98zqbqajj.execute-api.ap-southeast-2.amazonaws.com/beta/organisations/notes?cache_buster=${+new Date()}&org_id=${props.org_id}`);
    const [dataOrganisationNotes, setDataOrganisationNotes] = useState(null);

    const [selectedButtonTabValue, setSelectedButtonTabValue] = useState(0);

    //to show the note creation form
    const [noteTextToAdd, setNoteTextToAdd] = useState(null);
    const [showNoteCreateForm, setShowNoteCreateForm] = useState(false);

    const [callActivityTextToAdd, setCallActivityToAdd] = useState(null);
    const [showCallActivityCreateForm, setShowCallActivityCreateForm] = useState(false);

    useEffect(() => {
        setLoading(true);

        //api call - collection requests 
        (async () => {
            try {
                const result = await axios.get(urlCollectionRequests, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Cache': 'no-cache',
                        'x-api-key': localStorage.getItem('apiKey')
                    }
                });
                setErrorMessage(null);
                setLoading(false);
                setDataCollectionRequests(result.data);
            } catch (error) {
                setErrorMessage('Retrieving Scans failed.' + error.message);
                setLoading(false);
            }
        })();

        //api call - get notes 
        (async () => {
            const request = await new Request(
                urlOrganisationNotes,
                {
                    method: 'GET',
                    headers: new Headers({
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'x-api-key': localStorage.getItem('apiKey')
                    }),
                    credentials: 'omit',
                }
            );

            try {
                await fetch(request)
                    .then(response => {
                        if (response.status < 200 || response.status >= 300) {
                            throw new Error(response.statusText);
                        }

                        return response.json();
                    })
                    .then((data) => {
                        setErrorMessage(null);
                        setLoading(false);
                        setDataOrganisationNotes(data.response);
                    });
            } catch (error) {
                setErrorMessage('Retrieving Notes failed.' + error.message);
                setLoading(false);
            }
        })();

    }, [props.org_id]);

    // api call - call activity fetch, as response is empty so, have to fetch the new values.
    useEffect(() => {
        (async () => {
            const request = await new Request(
                urlCallActivity,
                {
                    method: 'GET',
                    headers: new Headers({
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'x-api-key': localStorage.getItem('apiKey')
                    }),
                    credentials: 'omit',
                }
            );

            try {
                await fetch(request)
                    .then(response => {
                        if (response.status < 200 || response.status >= 300) {
                            throw new Error(response.statusText);
                        }

                        return response.json();
                    })
                    .then((data) => {
                        setErrorMessage(null);
                        setLoading(false);
                        setDataCallActivity(data.response);
                        // return console.log("Call Activity data@@@@@@", data.response);
                    });
            } catch (error) {
                setErrorMessage('Retrieving Scans failed.' + error.message);
                setLoading(false);
            }
        })();

    }, [props.org_id, shouldFetchCallActivityApiCount]);

    const tracksApiHandler = (url, mode, payload) => {
        const options = {
            headers: {
                'Accept': 'application/json', 'Content-Type': 'application/json',
                'x-api-key': localStorage.getItem('apiKey')
            }
        };

        if (mode === 'get') {
            return axios.get(url, options);
        } else if (mode === 'patch') {
            return axios.patch(url, payload, options);
        } else if (mode === 'post') {
            return axios.post(url, payload, options);
        }
    };

    // api call - creation of a new note 
    const addNoteApiHandler = async (orgId) => {
        if (!noteTextToAdd && noteTextToAdd === '') {
            return
        }
        let url = `${process.env.REACT_APP_TRACKS_URL}/api/organisations/${orgId}/notes`;
        const payload = {body: noteTextToAdd};

        try {
            const response = await tracksApiHandler(url, 'post', payload);
            if (response.data) {
                const noteElement = {
                    body: response.data.body,
                    created_at: response.data.createdAt,
                    user: response.data.createdBy.fullName
                };
                setDataOrganisationNotes([noteElement, ...dataOrganisationNotes])
            }
        } catch (error) {
            setErrorMessage('Error updating package' + (error.response.length > 0) ? error.response.data.message : '');
        }
        setNoteTextToAdd('');
        setShowNoteCreateForm(false);
    };

    // api call - creation of new call activity 
    const addCallActivityApiHandler = async (org_id) => {
        let url = `${process.env.REACT_APP_TRACKS_URL}/api/call-actions`;
        const payload = {
            organisationId: org_id,
            callTypeId: selectedButtonTabValue,
            note: callActivityTextToAdd
        };

        try {
            const response = await tracksApiHandler(url, 'post', payload);
            //there is no response body of updated body, thus, fetching the data again.
            setShouldFetchCallActivityApiCount(shouldFetchCallActivityApiCount + 1)
        } catch (error) {
            setErrorMessage('Error creating Call Activity ' + (error.response.length > 0) ? error.response.data.message : '');
        }
        setCallActivityToAdd('');
        setShowCallActivityCreateForm(false);
    };

    const convert24Clock = (H) => {
        let h = H % 12 || 12;
        let ampm = (H < 12 || H === 24) ? "AM" : "PM";
        return (h + ampm);
    };

    return (
        <Container>
            {errorMessage &&
                <Box m={2}>
                    <React.Fragment>
                        <Box display={'inline'}>
                            <Alert severity="error">
                                <AlertTitle>Failure</AlertTitle>
                                <Box m={1}>
                                    {errorMessage}
                                </Box>
                            </Alert>
                        </Box>
                    </React.Fragment>
                </Box>
            }

            {loading &&
                <Box m={2}>
                    <React.Fragment>
                        <Box display={'inline'}>
                            <Alert severity="info">
                                <AlertTitle> Fetching Data, please wait ... </AlertTitle>
                            </Alert>
                        </Box>
                    </React.Fragment>
                </Box>
            }

            {/*TODO for Upper part having two side pages*/}
            <Grid container spacing={8}>

                {/*TODO Left page*/}
                <Grid item xs={7}>
                    <Box m={1}>
                        <Box m={1}>
                            <Grid container spacing={8}>
                                <Grid item xs={8}>
                                    <Typography variant={'h5'}>Call Activity <Typography variant={'body1'}
                                                                                         style={{display: 'inline'}}>{'(max 10 most recent)'}</Typography></Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button variant={'contained'}
                                            onClick={() => setShowCallActivityCreateForm(true)}><AddIcCall/>&nbsp; Log a
                                        Call</Button>
                                </Grid>
                            </Grid>
                        </Box>

                        {/*@todo create call activity*/}
                        <Card
                            className={showCallActivityCreateForm ? classes.noteFormStyle : classes.noteFormHiddenStyle}
                            style={{maxWidth: '90%'}}>
                            <CardContent style={{display: 'flex'}}>
                                <Typography gutterBottom variant="h6">
                                    Call Type* :&nbsp;&nbsp;
                                </Typography>
                                <ButtonGroup variant="outlined" aria-label="outlined button group">
                                    <Button
                                        className={selectedButtonTabValue === 0 ? classes.buttonContained : classes.buttonOutlined}
                                        onClick={() => setSelectedButtonTabValue(0)}>No Kegs</Button>
                                    <Button
                                        className={selectedButtonTabValue === 1 ? classes.buttonContained : classes.buttonOutlined}
                                        onClick={() => setSelectedButtonTabValue(1)}>No Contacts</Button>
                                    <Button
                                        className={selectedButtonTabValue === 2 ? classes.buttonContained : classes.buttonOutlined}
                                        onClick={() => setSelectedButtonTabValue(2)}>Voice Mail</Button>
                                    <Button
                                        className={selectedButtonTabValue === 3 ? classes.buttonContained : classes.buttonOutlined}
                                        onClick={() => setSelectedButtonTabValue(3)}>Emailed</Button>
                                    <Button
                                        className={selectedButtonTabValue === 4 ? classes.buttonContained : classes.buttonOutlined}
                                        onClick={() => setSelectedButtonTabValue(4)}>Complaint</Button>
                                </ButtonGroup>
                            </CardContent>

                            <CardContent style={{display: 'flex'}}>
                                <Typography gutterBottom variant="h6"> Note:&nbsp;&nbsp; </Typography>
                                <TextField
                                    id="outlined-multiline-static"
                                    label=""
                                    multiline
                                    rows={4}
                                    value={callActivityTextToAdd}
                                    variant="filled"
                                    defaultValue=""
                                    onChange={(event) => setCallActivityToAdd(event.target.value)}
                                    style={{width: '-webkit-fill-available'}}
                                />
                            </CardContent>
                            <CardActions style={{justifyContent: 'end'}}>
                                <Button size="small" variant="contained"
                                        onClick={() => addCallActivityApiHandler(props.org_id)}>Save</Button>
                                <Button size="small" variant="contained"
                                        onClick={() => {
                                            setCallActivityToAdd('');
                                            setShowCallActivityCreateForm(false);
                                        }}>Cancel</Button>
                            </CardActions>
                        </Card>

                        <Grid container spacing={2}>
                            <Grid item xs>
                                <Box m={1}>
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableHead style={{    backgroundColor: '#FAFAFA'}}>
                                                <TableRow>
                                                    <TableCell> <Typography variant={'body1'}
                                                                            style={{fontWeight: 'bold'}}>Activity </Typography></TableCell>
                                                    <TableCell><Typography variant={'body1'}
                                                                           style={{fontWeight: 'bold'}}>By</Typography></TableCell>
                                                    <TableCell><Typography variant={'body1'}
                                                                           style={{fontWeight: 'bold'}}>Created</Typography></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    (dataCallActivity)
                                                        ?
                                                        dataCallActivity.map(
                                                            item => {
                                                                return (
                                                                    <TableRow key={item.id}
                                                                              style={{border: 0}}
                                                                    >
                                                                        <TableCell> {item.description} {item.note ? (
                                                                            <>
                                                                                <br/>
                                                                                (<strong>Note</strong>: {item.note})
                                                                            </>) : ''}</TableCell>
                                                                        <TableCell>{item.created_by}</TableCell>
                                                                        <TableCell>
                                                                            {item.created_at  ? moment(item.created_at).tz(localStorage.getItem('timezone')).format('Do MMMM, YYYY') : '-'}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                        )
                                                        :
                                                        <TableRow>
                                                            <TableCell colSpan={4}>No Call Activity</TableCell>
                                                        </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                {/*TODO Right page*/}
                <Grid item xs={5}>
                    <Box m={1}>
                        <Grid container spacing={8}>
                            <Grid item xs={4}>
                                <Typography variant={'h5'}>Notes</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Button variant={'contained'}
                                        onClick={() => setShowNoteCreateForm(true)}><NoteAdd/>&nbsp; Add Note</Button>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* @todo create note */}
                    <Card className={showNoteCreateForm ? classes.noteFormStyle : classes.noteFormHiddenStyle}>
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div">
                                Create Note
                            </Typography>
                            <TextField
                                id="outlined-multiline-static"
                                label=""
                                multiline
                                rows={4}
                                variant="filled"
                                defaultValue=""
                                value={noteTextToAdd}
                                onChange={(event) => setNoteTextToAdd(event.target.value)}
                                style={{width: '-webkit-fill-available'}}
                            />
                        </CardContent>
                        <CardActions style={{justifyContent: 'end'}}>
                            <Button size="small" variant="contained"
                                    onClick={() => addNoteApiHandler(props.org_id)}>Save</Button>
                            <Button size="small" variant="contained" onClick={() => {
                                setNoteTextToAdd('');
                                setShowNoteCreateForm(false);
                            }}>Cancel</Button>
                        </CardActions>
                    </Card>

                    <br/>

                    {(dataOrganisationNotes) ?
                        dataOrganisationNotes.map(
                            item => {
                                return (
                                    <>
                                        <Card style={{maxWidth: 345}}>
                                            {/*@todo, leads page has added moments , use it to convert the created_at value to the ago field.*/}
                                            <CardActions style={{justifyContent: 'space-between'}}>
                                                <Typography variant="subtitle2">
                                                    {item.user ? item.user : ''}
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    {item.created_at  ? moment(item.created_at).tz(localStorage.getItem('timezone')).format('Do MMMM, YYYY') : '-'}
                                                </Typography>
                                            </CardActions>
                                            <CardContent style={{  display: 'flex', justifyContent: 'center'}}>
                                                <Typography variant="body1">
                                                    {item.body ? item.body : ''}
                                                </Typography>
                                            </CardContent>

                                        </Card>
                                        <br/>
                                    </>)
                            }) : ''}
                </Grid>
            </Grid>

            {/*TODO for bottom full length page*/}
            <Box m={1}>
                <Typography variant={'h5'}>Past Collections</Typography>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                    <TableHead style={{    backgroundColor: '#FAFAFA'}}>
                        <TableRow>
                            <TableCell> <Typography variant={'body1'}
                                                    style={{fontWeight: 'bold'}}>Collection
                                Request </Typography></TableCell>
                            <TableCell><Typography variant={'body1'}
                                                   style={{fontWeight: 'bold'}}>Created</Typography></TableCell>
                            <TableCell><Typography variant={'body1'}
                                                   style={{fontWeight: 'bold'}}>Via</Typography></TableCell>
                            <TableCell><Typography variant={'body1'}
                                                   style={{fontWeight: 'bold'}}>Qty</Typography></TableCell>
                            <TableCell><Typography variant={'body1'}
                                                   style={{fontWeight: 'bold'}}>Collector</Typography></TableCell>
                            <TableCell><Typography variant={'body1'}
                                                   style={{fontWeight: 'bold'}}>Status</Typography></TableCell>
                            <TableCell><Typography variant={'body1'}
                                                   style={{fontWeight: 'bold'}}>Venue Open</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (dataCollectionRequests && dataCollectionRequests.length > 0)
                                ?
                                dataCollectionRequests.map(
                                    item => {
                                        return (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    <Link
                                                        href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/collections/${item.id}/details`}>
                                                                <span style={{
                                                                    fontWeight: 'bold',
                                                                    color: '#1887ad'
                                                                }}>{item.id}</span>
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    {item.createdAt  ? moment(item.created_at).tz(localStorage.getItem('timezone')).format('Do MMMM, YYYY') : '-'}
                                                </TableCell>
                                                <TableCell>{item.callTypeText}</TableCell>
                                                <TableCell>
                                                    <Link
                                                        href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/collections/${item.id}/details`}>
                                                                <span style={{
                                                                    fontWeight: 'bold',
                                                                    color: '#1887ad'
                                                                }}>{item.scanCount} scans</span>
                                                    </Link>
                                                    &nbsp; &nbsp;
                                                    <Link
                                                        href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/collections/${item.id}/details`}>
                                                                <span style={{
                                                                    fontWeight: 'bold',
                                                                    color: '#1887ad'
                                                                }}>Approx. {item.approxQuantity} kegs</span>
                                                    </Link>

                                                </TableCell>

                                                <TableCell>
                                                    {(item.collectorLocationName) ?
                                                        <Link
                                                            href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${item.fromOrganisationId}/locations/view/${item.fromLocationId}`}>
                                                                <span style={{
                                                                    fontWeight: 'bold',
                                                                    color: '#1887ad'
                                                                }}>{item.collectorLocationName}</span>
                                                        </Link>
                                                        :
                                                        'No collector'
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        (item.collectedCount < item.scanCount) ?
                                                            <span>{item.collectedCount} of {item.scanCount}</span> : ''
                                                    }

                                                    {
                                                        (item.collectedCount && item.collectedCount == item.scanCount) ?
                                                            <span>{item.collectedCount} of {item.scanCount}</span> : ''
                                                    }


                                                </TableCell>
                                                {/*@todo convert time in am and pm and add links to above link , also
                                                    date to  dd month year format too.
                                                */}

                                                <TableCell>
                                                    <>
                                                        <span>{item.collectFromTime ? convert24Clock(item.collectFromTime) : ''} - {item.collectToTime ? convert24Clock(item.collectToTime) : '' }</span>
                                                        <span>{item.openDays}</span>
                                                    </>
                                                </TableCell>

                                            </TableRow>
                                        )
                                    }
                                )
                                :
                                <TableRow>
                                    <TableCell colSpan={4}>No Call Activity</TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
                </TableContainer>
            </Box>
        </Container>
    )
};

export default ProducersDetailsPage;