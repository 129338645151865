// @ts-nocheck
import * as React from 'react';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
    AppBar as MuiAppBar,
    Toolbar,
    useMediaQuery,
    Theme,
} from '@material-ui/core';
import { AppBarProps as MuiAppBarProps } from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import PortalNavigationBar from "./PortalNavigationBar";
import Header from '../components/Header/Header';

const useStyles = makeStyles(
    theme => ({
        appBar: {
            border: 0,
            background: '#e7e6e7',
            width: '100vw'
        },
        toolbar: {
            paddingRight: 24,
        },
        menuButton: {
            display: 'none',
            marginLeft: '0.5em',
            marginRight: '0.5em',
        },
        menuButtonIconClosed: {
            transition: theme.transitions.create(['transform'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: 'rotate(0deg)',
        },
        menuButtonIconOpen: {
            transition: theme.transitions.create(['transform'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: 'rotate(180deg)',
        },
        title: {
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
    }),
    { name: 'RaAppBar' }
);

const PortalAppBar = (props: PortalAppBarProps): JSX.Element => {

    const {
        children,
        className,
        color = 'secondary',
        logout,
        open,
        title,
        userMenu,
        ...rest
    } = props;
    const classes = useStyles(props);
    const dispatch = useDispatch();
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );
    // const translate = useTranslate();
    const userData = {
        firstName: localStorage.getItem('firstName'), lastName: localStorage.getItem('lastName')
    }

    return (
        <Header user={userData} />
        /*<MuiAppBar position={'relative'} className={classes.appBar} color={color} {...rest}>
            <Toolbar
                disableGutters
                variant={isXSmall ? 'regular' : 'dense'}
                className={classes.toolbar}
            >
                <Container style={{ maxWidth: '1356px', backgroundColor: '#e7e6e7', borderColor: '#000' }}
                >
                    <PortalNavigationBar />
                </Container>
            </Toolbar>
        </MuiAppBar>*/
    );
};

PortalAppBar.propTypes = {
    children: PropTypes.node,
    // @ts-ignore
    classes: PropTypes.object,
    className: PropTypes.string,
    color: PropTypes.oneOf([
        'default',
        'inherit',
        'primary',
        'secondary',
        'transparent',
    ]),
    logout: PropTypes.element,
    open: PropTypes.bool,
    userMenu: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
};
export interface PortalAppBarProps
    extends Omit<MuiAppBarProps, 'title' | 'classes'> {
    classes?: ClassesOverride<typeof useStyles>;
    logout?: JSX.Element;
    open?: boolean;
    title?: string | JSX.Element;
    userMenu?: JSX.Element | boolean;
}

export default memo(PortalAppBar);
