import React, {Fragment, useState} from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    FunctionField, Filter, ReferenceInput, AutocompleteInput, SelectInput, useLoading, DateInput, ReferenceArrayInput,
    SelectArrayInput, NumberInput,
    useAuthenticated, TextInput
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import {
    CustomPagination, getMarketColorCode, OrderStatuses, OrderTypes, setCurrentFilters, applyCurrentFilter
} from "../../utilities/Common";
import {Container, LinearProgress, Box, CircularProgress, Button} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import TransfersListActions from "../transfers/TransfersListActions";
import OrganisationListingListActions from "./OrganisationListingActions";
import TransfersExport from "../transfers/TransfersExport";
import OrganisationListingExport from "./OrganisationListingExport";

export const styles = (theme => ({
    root: {},
    loading: {
        '& .MuiTable-root': {
            display: 'none'
        },
        '& .MuiTypography-body2': {
            display: 'none'
        },
        '& .MuiTablePagination-toolbar': {
            display: 'none'
        }

    },
    orderFilter: {
        width: '10em'
    },
    organisationFilter: {
        width: '18em'
    },
    skuFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    },
    typeFilter: {
        minWidth: '8em'
    },
    statusFilter: {
        minWidth: '6em'
    },
    stateFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    }
}));

const useStyles = makeStyles(styles);

const ListFilters = props => {
    const classes = useStyles();

    return (
        <Filter {...props}>
            <ReferenceInput source="organisationUuid" reference="organisations"
                            filter={{market: localStorage.getItem('mId'),isActive: 1}}
                            sort={{ field: 'name', order: 'asc' }}
                            sortDefaultValues={{ field: 'name', order: 'asc' }} label={'Organisation Name'}
                            className={classes.organisationFilter} alwaysOn resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
        </Filter>
    )
};

const ToOrganisation = ({record}) => {
    return(
        <React.Fragment>
            { record.organisationName ?
                <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.organisationId}/overview`}>
                    <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.organisationName}</span>
                </Link>
                : null }
        </React.Fragment>
    )
}

const OrganisationListingList = props => {
    useAuthenticated();
    const loading = useLoading();
    const classes = useStyles();
    const marketId = localStorage.getItem('mId');
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const state = { setErrorMessage: setErrorMessage,
        setSuccessMessage: setSuccessMessage }

    // let defaultFilterValues = { marketId: marketId, tz: Intl.DateTimeFormat().resolvedOptions().timeZone };

    return (
        <Fragment>
            <Container maxWidth={false}>
                { loading && <LinearProgress /> }
                { loading &&
                <Box justifyContent={'center'} justifyItems={'center'}  alignItems={'center'} alignContent={'center'}
                     style={{backgroundColor: '#1f2124', color: '#fff', padding:'5px', position: 'fixed',
                         bottom: '0', zIndex: 50, width: '90%'}}>
                    <Box p={1}><CircularProgress size={20} style={{color: '#fff'}} /> Please wait...</Box>
                </Box>
                }
                <List {...props}
                      actions={<OrganisationListingListActions state={state}/>}
                      filter={{ marketId: marketId, tz: localStorage.getItem('timezone') }}
                      filters={ <ListFilters /> }
                      perPage={25}
                      bulkActionButtons={false}
                      exporter={OrganisationListingExport}
                      pagination={<CustomPagination />}
                >
                    <Datagrid>
                        <FunctionField source={'organisationName'} render={record => <ToOrganisation record={record} /> } label={'Name'} sortable={false} />
                        <FunctionField source={'contactFirstName'} render={record =>
                            <span>{ `${record.contactFirstName} ${record.contactLastName}` }</span>
                        } label={'Contact Name'} sortable={false} />
                        <TextField source={'contactPhone'} label={'Contact Number'} sortable={false} />
                        <FunctionField source={'rented'} render={record => <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.rented}/kegs/rented`}>
                            <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.rented}</span>
                        </Link> } label={'Rented'} />
                        <FunctionField source={'holding'} render={record => <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.holding}/kegs/holding`}>
                            <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.holding}</span>
                        </Link> } label={'Holding'} />
                    </Datagrid>
                </List>
            </Container>
        </Fragment>
    );

};

export default OrganisationListingList;

