// @ts-nocheck
import * as React from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {Box, Button, Typography, Container, Paper}
    from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Notification, useQuery} from 'react-admin';
import ErrorAlertBox from "../../components/alerts/ErrorAlertBox";
import EmptyAlertBox from "../../components/alerts/EmptyAlertBox";
import Link from "@material-ui/core/Link";
import DraftChargeList from "../../resources/charges/draft/DraftChargesList";
import Divider from "@material-ui/core/Divider";
import {GetCountry, GetOrganisation} from "../../utilities/Common";
import ScrollToRightSignal from "../../components/gifs/ScrollToRightSignal";
import BackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles(theme => ({
    formRoot: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '22ch',
        },
    }
}));

const DraftProcessesList = () => {
    const {processUuid} = useParams();
    const [hasErrors, setHasErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [noData, setNoData] = useState(false);

    const GetDraftChargesProcessInfo = ({processUuid}) => {
        const { data, loading, error } = useQuery({
            type: 'getOne',
            resource: 'processes/draftcharges',
            payload: { id: processUuid, marketId: localStorage.getItem('mId') }
        });

        if (loading) return null;
        if (error) return  "Error";
        if (!data) return null;

        return (
            <React.Fragment>
                <Box display="flex" flexDirection="row-reverse">
                    <Box m={1}>
                        <Link href={`/#/billing/generate/charges/draft`}>
                            <Button variant={'contained'}><BackIcon /> &nbsp; Process List</Button>
                        </Link>
                    </Box>
                </Box>
                <Box m={2}></Box>
                <Box m={1}>
                    <Paper>
                        <Box display="flex" flexDirection="row" m={1}>
                            <Box m={1} style={{paddingRight: '40px'}}>
                                <Typography variant={'caption'}>Country</Typography>
                                <Typography> { (data.messagePayload.countryUuid) ? <GetCountry uuid={data.messagePayload.countryUuid} />  : null }</Typography>
                            </Box>
                            <Box m={1} style={{paddingRight: '80px'}}>
                                <Typography variant={'caption'}>Organisation</Typography>
                                <Typography>{ (data.messagePayload.organisationUuid) ? <GetOrganisation uuid={data.messagePayload.organisationUuid} /> : null }</Typography>
                            </Box>

                            <Box m={1} style={{paddingRight: '40px'}}>
                                <Typography variant={'caption'}>Date From</Typography>
                                <Typography>{new Date(data.messagePayload.startDate).toLocaleDateString()}</Typography>
                            </Box>
                            <Box m={1} style={{paddingRight: '40px'}}>
                                <Typography variant={'caption'}>Date To</Typography>
                                <Typography>{new Date(data.messagePayload.cutoffDate).toLocaleDateString()}</Typography>
                            </Box>
                            <Box m={1} style={{paddingRight: '40px'}}>
                                <Typography variant={'caption'}>Run Date</Typography>
                                <Typography>{new Date(new Date(data.runningDate).toISOString().split('T')[0]).toLocaleDateString()}</Typography>
                            </Box>
                            <Box m={1} style={{paddingRight: '40px'}}>
                                <Typography variant={'caption'}>Run By</Typography>
                                <Typography>{data.userName}</Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
                <Box m={1}>
                    <ScrollToRightSignal />
                </Box>
            </React.Fragment>
        )
    };

    if (hasErrors) {
        return (
            <ErrorAlertBox errorMessage={errorMessage} />
        )
    }
    if (noData) {
        return (
            <EmptyAlertBox message={'Order Package details not found.'} />
        )
    }

    let chargesProps = {
        basePath: "/charges/draft",
        resource: "charges/draft"
    }

    return (
        <Container >

                <Box m={1}>
                    <Typography variant={'h4'}>
                        Monthly Draft Charges
                    </Typography>
                </Box>
                <Box m={1}>
                    <Divider />
                </Box>
                <GetDraftChargesProcessInfo processUuid={processUuid} />
                <DraftChargeList processUuid={processUuid} {...chargesProps} />

                <Notification />

        </Container>
    );
};

DraftProcessesList.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

export default DraftProcessesList;
