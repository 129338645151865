import React, {Fragment, useState} from 'react';
import './PalletsList.css';
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    FunctionField,
    List,
    NumberField,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectArrayInput,
    useAuthenticated,
    useLoading
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";

import {CustomPagination, getMarketColorCode, OrderStatuses} from "../../utilities/Common";

import {Box, Button, CircularProgress, Container, LinearProgress} from "@material-ui/core";
import PalletsExport from "../pallets/PalletsExport";
import PalletsListActions from "../pallets/PalletsListActions";
import Link from "@material-ui/core/Link";
import ScansList from "./ScansList";

export const styles = (theme => ({
    root: {},
    loading: {
        '& .MuiTable-root': {
            display: 'none'
        },
        '& .MuiTypography-body2': {
            display: 'none'
        },
        '& .MuiTablePagination-toolbar': {
            display: 'none'
        }

    },
    orderFilter: {
        width: '10em'
    },
    organisationFilter: {
        width: '18em'
    },
    skuFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    },
    typeFilter: {
        minWidth: '8em'
    },
    statusFilter: {
        minWidth: '6em'
    },
    stateFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    }
}));

const useStyles = makeStyles(styles);

const ListFilters = props => {
    const classes = useStyles();

    return (
        <Filter {...props}>
            <NumberInput source={'id'} label={'Pallets Id.'} min={0} resettable alwaysOn/>
            <ReferenceInput source="organisationUuid" reference="organisations"
                // filter={{market: localStorage.getItem('mId'), isProducer: 1, isActive: 1}}
                            sort={{field: 'name', order: 'asc'}}
                            sortDefaultValues={{field: 'name', order: 'asc'}} label={'Organisation'}
                            className={classes.organisationFilter} alwaysOn resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>

            <DateInput label={'Created From'} source={'createdAt_from'} resettable alwaysOn/>
            <DateInput label={'Created Till'} source={'createdAt_to'} resettable alwaysOn/>

            <NumberInput label={'No. of Kegs From'} source={'total_kegs_from'} min={0} resettable alwaysOn/>
            <NumberInput label={'No. of Kegs To'} source={'total_kegs_to'} min={0} resettable alwaysOn/>
        </Filter>
    )
};

const FromOrganisation = ({record}) => {
    return (
        <React.Fragment>
            {record.org_name ?
                <Link
                    href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.org_id}/overview`}>
                    <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.org_name}</span>
                </Link>
                : null}
        </React.Fragment>
    )
};

const PalletsList = props => {
    useAuthenticated();
    const loading = useLoading();
    const classes = useStyles();
    const marketId = localStorage.getItem('mId');
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const state = {
        setErrorMessage: setErrorMessage,
        setSuccessMessage: setSuccessMessage
    };

    // @ts-ignore
    return (
        <Fragment>
            <Container maxWidth={false}>
                {loading && <LinearProgress/>}
                {loading &&
                    <Box justifyContent={'center'} justifyItems={'center'} alignItems={'center'} alignContent={'center'}
                         style={{
                             backgroundColor: '#1f2124', color: '#fff', padding: '5px', position: 'fixed',
                             bottom: '0', zIndex: 50, width: '90%'
                         }}>
                        <Box p={1}><CircularProgress size={20} style={{color: '#fff'}}/> Please wait...</Box>
                    </Box>
                }
                <List {...props}
                      actions={<PalletsListActions state={state}/>}
                    // filter={{}}
                    // filterDefaultValues={}
                      filters={<ListFilters/>}
                      sort={{field: 'id', order: 'desc'}}
                      perPage={10}
                      bulkActionButtons={false}
                      exporter={PalletsExport}
                      pagination={<CustomPagination/>}
                >
                    <Datagrid rowClick="expand" expand={row => <ScansList palletUuid={row.record.palletUuid}/>}>
                        <FunctionField source={'id'} render={record =>
                            <span style={{fontWeight: 'bold'}}>{record.id}</span>
                        } label={'ID'} style={{width:'100px'}} />
                        <NumberField source="total_kegs" label={'No. of Kegs'} textAlign="center" style={{width:'100px'}} />
                        <FunctionField source={'firstName'} render={record => (record.firstName + ' ' + record.lastName)}
                                       label={'User'} style={{width:'8em'}} sortable={false}/>
                        <FunctionField source={'createdAt'} render={record =>
                            record.createdAt
                                ?
                                <DateField source="createdAt" label={'Created'}
                                           locales={(localStorage.getItem('mId') === '4') ? 'en-US' : 'en-GB'}
                                           options={{
                                               day: 'numeric',
                                               month: 'numeric',
                                               year: 'numeric',
                                               hour: 'numeric',
                                               minute: 'numeric',
                                               hour12: true
                                           }}/>
                                : '-'
                        } label={'Created'}/>
                        <FunctionField source={'org_name'}
                                       render={record => <FromOrganisation record={record}/>} label={'Organisation'} sortable={false}/>
                    </Datagrid>
                </List>
            </Container>
        </Fragment>
    );

};

export default PalletsList;

