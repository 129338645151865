// @ts-nocheck
import * as React from 'react';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {Box, Button, CircularProgress, Container, Paper, LinearProgress, Tabs, Tab, Typography}
    from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Notification, useNotify, useQuery} from 'react-admin';
import ErrorAlertBox from "../../components/alerts/ErrorAlertBox";

import Link from "@material-ui/core/Link";

import ChargeList from "../../resources/charges/ChargeList";

import DraftInvoiceItemsList from "../../resources/invoiceitems/draft/DraftInvoiceItemsList";
import Divider from "@material-ui/core/Divider";
import InvoiceIcon from "@material-ui/icons/Receipt";
import SendIcon from "@material-ui/icons/Send";
import StatementIcon from "@material-ui/icons/PictureAsPdf";
import {GetMarket, getMarketColorCode} from "../../utilities/Common";
import ScrollToRightSignal from "../../components/gifs/ScrollToRightSignal";

import {ProcessStatusDisplay} from "../../utilities/Common";
import BackIcon from "@material-ui/icons/ArrowBack";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import YesIcon from "@material-ui/icons/CheckCircle";
import NoIcon from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles(theme => ({
    formRoot: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '22ch',
        },
    }
}));

const GetMonthlyProcessInfo = ({processUuid}) => {
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'processes/monthly',
        payload: { id: processUuid }
    });

    const dateFormatting = (dateString) => {
        // to handle invalid date in safari "2010-08-09 01:02:03";
        let reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
        let dateArray = reggie.exec(dateString);
        return new Date(
            (+dateArray[1]),
            (+dateArray[2])-1, // Careful, month starts at 0!
            (+dateArray[3]),
            (+dateArray[4]),
            (+dateArray[5]),
            (+dateArray[6])
        );
    };

    if (loading) return <LinearProgress />;
    if (error) return  "Error";
    if (!data) return null;

    return (
        <React.Fragment>
            <Box m={2}></Box>
            <Box m={1}>
                <Paper>
                    <Box display="flex" flexDirection="row" m={1}>
                        <Box m={1} style={{paddingRight: '40px'}}>
                            <Typography variant={'caption'}>Market</Typography>
                            <Typography> { (data.messagePayload.marketId) ? <GetMarket marketId={data.messagePayload.marketId} />  : null }</Typography>
                        </Box>

                        <Box m={1} style={{paddingRight: '40px'}}>
                            <Typography variant={'caption'}>Invoice Run</Typography>
                            <Typography>{dateFormatting(data.messagePayload.startDate).toLocaleDateString()}</Typography>
                        </Box>

                        <Box m={1} style={{paddingRight: '40px'}}>
                            <Typography variant={'caption'}>Invoice Date</Typography>
                            <Typography>{dateFormatting(data.messagePayload.cutoffDate).toLocaleDateString()}</Typography>
                        </Box>

                        <Box m={1} style={{paddingRight: '40px'}}>
                            <Typography variant={'caption'}>Run Date</Typography>
                            <Typography>{new Date(new Date(data.runningDate).toISOString().split('T')[0]).toLocaleDateString()}</Typography>
                        </Box>
                        <Box m={1} style={{paddingRight: '40px'}}>
                            <Typography variant={'caption'}>Run By</Typography>
                            <Typography>{data.userName}</Typography>
                        </Box>
                    </Box>
                </Paper>
            </Box>

        </React.Fragment>
    )
};

const ProcessesList = () => {
    const {processUuid} = useParams();

    const [tabValue, setTabValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [processData, setProcessData] = useState(null);
    const notify = useNotify();
    const [generateInvoicesDialog, setGenerateInvoicesDialog] = React.useState(false);
    const [generateStatementsDialog, setGenerateStatementsDialog] = React.useState(false);
    const [sendToNetsuiteDialog, setSendToNetsuiteDialog] = React.useState(false);
    const [refresh, setRefresh] = useState(false);

    const handleInvoiceOpen = (e) => {
        e.preventDefault();
        setGenerateInvoicesDialog(true);
    };
    const handleInvoiceClose = () => {
        setGenerateInvoicesDialog(false);
    };

    const handleStatementOpen = (e) => {
        e.preventDefault();
        setGenerateStatementsDialog(true);
    };
    const handleStatementClose = () => {
        setGenerateStatementsDialog(false);
    };

    const handleNetsuiteOpen = (e) => {
        e.preventDefault();
        setSendToNetsuiteDialog(true);
    };
    const handleNetsuiteClose = () => {
        setSendToNetsuiteDialog(false);
    };

    const url = `${process.env.REACT_APP_KEGNOVA_API_URL}/processes/monthly/${processUuid}`;
    /** Get Package Details **/
    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const result = await axios.get(url, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')} });
                setProcessData(result.data);
            } catch (error) {
                let errMsg = `Error fetching monthly processes data. ${error.response.data.message}`;
                notify(errMsg, 'warning');
                // setHasErrors(true);
                // setErrorMessage('Retrieving package for order failed.');
            }
            setLoading(false);
        })();
    }, [url]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    if (hasErrors) {
        return (
            <ErrorAlertBox errorMessage={errorMessage} />
        )
    }

    const generateMonthlyInvoices = async (e, marketId, parentProcessUuid) => {
        e.preventDefault();
        let payload = {
            marketId: marketId,
            parentProcessUuid: parentProcessUuid
        };

        setLoading(true);
        setGenerateInvoicesDialog(false);
        try {
            let url = `${process.env.REACT_APP_KEGNOVA_API_URL}/invoices/draft/generate`;
            const options = {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',
                    'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')}
            };
            const result = await axios.post(url, payload, options);
            setLoading(false);
            // notify('Generate monthly charges successful.', 'info');
            setSuccessMessage('Processing Monthly Invoices. Status below will indicate when invoices are Completed. This may take some time.');
            setRefresh(true);
            setTimeout(function(){ setRefresh(false); }, 1000);
        } catch (error) {
            let errMsg = `Generate Monthly Invoices failed! ${error.response.data.message}`;
            setErrorMessage(errMsg);
            notify(errMsg, 'warning');
        }
    }

    const sendToNetsuite = async (e, marketId, parentProcessUuid) => {
        e.preventDefault();
        let payload = {
            marketId: marketId,
            parentProcessUuid: parentProcessUuid
        };

        setLoading(true);
        setSendToNetsuiteDialog(false);

        try {
            let url = `${process.env.REACT_APP_KEGNOVA_API_URL}/invoices/draft/sendtonetsuite`;
            const options = {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',
                    'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')}
            };
            const result = await axios.post(url, payload, options);
            setLoading(false);
            // notify('Send to Netsuite successful.', 'info');
            setSuccessMessage('Processing the sending of invoices to Netsuite. Status below will indicate when its Completed. This may take some time.');
            setRefresh(true);
            setTimeout(function(){ setRefresh(false); }, 1000);

        } catch (error) {
            setLoading(false);
            let errMsg = `Sending to Netsuite failed!${error.response.data.message}`;
            setErrorMessage(errMsg);
            // notify(errMsg, 'warning');
        }
    }

    const generateStatements = async (e, marketId, parentProcessUuid) => {
        e.preventDefault();
        let payload = {
            marketId: marketId,
            parentProcessUuid: parentProcessUuid
        };

        setLoading(true);
        setGenerateStatementsDialog(false);

        try {
            let url = `${process.env.REACT_APP_KEGNOVA_API_URL}/invoices/draft/generatestatement`;
            const options = {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',
                    'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')}
            };
            const result = await axios.post(url, payload, options);
            setLoading(false);
            // notify('Generate Statements successful.', 'info');
            setSuccessMessage('Processing Monthly Statements. Status below will indicate when statements are Completed. This may take some time.');
            setRefresh(true);
            setTimeout(function(){ setRefresh(false); }, 1000);

        } catch (error) {
            setLoading(false);
            let errMsg = `Generate Statements failed!${error.response.data.message}`;
            setErrorMessage(errMsg);
            // notify(errMsg, 'warning');
        }
    }

    let chargesProps = {
        basePath: "/charges",
        resource: "charges"
    }
    let draftInvoiceItemsProps = {
        basePath: "/invoiceitems/draft",
        resource: "invoiceitems/draft"
    }

    const disableProcessButton = (processType) => {
        if (processType) {
            if(processType.processStatus == 3) { // Disable the button since its already completed
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    const GenerateButton = ({processData}) => {
        if(!processData) return null;
            return (
                <React.Fragment>
                    <ProcessStatusDisplay processStatusText={processData.processStatusText} />
                    {  ( processData && processData.processStatusText.toLowerCase().indexOf("completed") > -1 ) &&

                            <Button style={{marginLeft: '10px'}}
                                variant={'contained'} onClick={handleInvoiceOpen} disabled={ disableProcessButton(processData.childrenProcess.DraftInvoice) }><InvoiceIcon /> &nbsp; Generate Invoices </Button>

                    }
                    <Dialog
                        open={generateInvoicesDialog}
                        onClose={handleInvoiceClose}
                        aria-labelledby="alert-dialog-title-generate-invoices"
                        aria-describedby="alert-dialog-description-generate-invoices"
                    >
                        <DialogTitle id="alert-dialog-title-generate-invoices" style={{backgroundColor: getMarketColorCode(localStorage.getItem('mId')), color: 'white'}}>{"Invoice Generation"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description-generate-invoices">
                                Generate Invoices button initiates the invoice generation process.
                                Are you sure you want to initiate Invoice generation process? Once initiated, you will not be able to reverse or stop the process and/or make any changes to charges.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant={'contained'} onClick={(e)=>generateMonthlyInvoices(e, processData.messagePayload.marketId, processData.uuid)} color="primary" autoFocus>
                                <YesIcon /> &nbsp; Yes
                            </Button>
                            <Button variant={'contained'} onClick={handleInvoiceClose} color="primary">
                                <NoIcon /> &nbsp; No
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            )
    }

    const StatementsAndSendToNetsuiteButton = ({processData}) => {
        if(!processData) return null;
        return (
            <React.Fragment>
                <ProcessStatusDisplay processStatusText={processData.processStatusText} />
                {  processData && processData.processStatusText.toLowerCase().indexOf("completed") > -1 &&
                    <React.Fragment>
                        <Button style={{marginLeft: '10px'}}
                                variant={'contained'} onClick={handleStatementOpen} disabled={ disableProcessButton(processData.childrenProcess.InvoiceStatement) }><StatementIcon /> &nbsp; Generate Statements </Button>
                        <Button style={{marginLeft: '10px'}}
                                variant={'contained'} onClick={handleNetsuiteOpen} disabled={ disableProcessButton(processData.childrenProcess.DraftInvoiceSendToNetsuite) }><SendIcon /> &nbsp; Send to Netsuite</Button>
                    </React.Fragment>
                }
                <Dialog
                    open={generateStatementsDialog}
                    onClose={handleStatementClose}
                    aria-labelledby="alert-dialog-title-generate-statements"
                    aria-describedby="alert-dialog-description-generate-statements"
                >
                    <DialogTitle id="alert-dialog-title-generate-statements" style={{backgroundColor: getMarketColorCode(localStorage.getItem('mId')), color: 'white'}}>{"Statement Generation"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description-generate-statements">
                            Generate Statements button initiates the statements generation process.
                            Are you sure you want to initiate Statements generation process? Once initiated, you will not be able to reverse or stop the process.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={'contained'} onClick={(e)=>generateStatements(e, processData.messagePayload.marketId, processData.uuid)} color="primary" autoFocus>
                            <YesIcon /> &nbsp; Yes
                        </Button>
                        <Button variant={'contained'} onClick={handleStatementClose} color="primary">
                            <NoIcon /> &nbsp; No
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={sendToNetsuiteDialog}
                    onClose={handleNetsuiteClose}
                    aria-labelledby="alert-dialog-title-send-netsuite"
                    aria-describedby="alert-dialog-description-send-netsuite"
                >
                    <DialogTitle id="alert-dialog-title-send-netsuite" style={{backgroundColor: getMarketColorCode(localStorage.getItem('mId')), color: 'white'}}>{"Send to Netsuite"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description-send-netsuite">
                            Send to Netsuite button initiates the sending of invoices to Netsuite process.
                            Are you sure you want to initiate Send to Netsuite process? Once initiated, you will not be able to reverse or stop the process.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={'contained'} onClick={(e)=>sendToNetsuite(e, processData.messagePayload.marketId, processData.uuid)} color="primary" autoFocus>
                            <YesIcon /> &nbsp; Yes
                        </Button>
                        <Button variant={'contained'} onClick={handleNetsuiteClose} color="primary">
                            <NoIcon /> &nbsp; No
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }

    return (
        <Container>
            <Box p={2}>
                <Box m={2}></Box>
                <Box m={1}>
                    <Typography variant={'h4'}>
                        Monthly Invoices
                    </Typography>
                </Box>
                <Box m={1}>
                    <Divider />
                    <Box m={1} display={'flex'} flexDirection={'row-reverse'}>
                        <Link href={`/#/billing/generate/invoices`}>
                            <Button variant={'contained'}><BackIcon /> &nbsp; Process List</Button>
                        </Link>
                    </Box>
                </Box>

                <Box m={1}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
                        <Tab label="Monthly Charges" {...formTabProps(0)} style={{minWidth: '100px', fontWeight: 'bold', fontSize: '1rem', backgroundColor: '#e7e6e7', textTransform: 'none'}}  />
                        { processData && processData.childrenProcess.DraftInvoice && <Tab label="Monthly Invoices" {...formTabProps(1)} style={{minWidth: '100px', fontWeight: 'bold', fontSize: '1rem', backgroundColor: '#e7e6e7', textTransform: 'none'}} /> }
                    </Tabs>
                </Box>

                   <Box m={1}>
                       <hr style={{ height: '0.5px', color: '#1b1b1b', backgroundColor: '#1b1b1b', border: 'solid 1px #1b1b1b', marginTop: '-10px' }}/>
                   </Box>

                <Box m={1}>
                    <GetMonthlyProcessInfo processUuid={processUuid} />
                </Box>

                <TabPanel value={tabValue} index={0}>
                    <Box display="flex" flexDirection="row-reverse">
                        <Box>

                                { (loading) ? <span><CircularProgress size={12} /> &nbsp; Please wait...</span>
                                    : <GenerateButton processData={processData} />  }

                        </Box>
                    </Box>
                    {/*<Box m={1}>*/}
                    {/*    <ScrollToRightSignal />*/}
                    {/*</Box>*/}
                    <Box m={2} />
                    { processData && processData.childrenProcess.Charges && !refresh && <ChargeList processData={processData} {...chargesProps} /> }

                </TabPanel>

                { processData && processData.childrenProcess.DraftInvoice && !refresh &&
                <TabPanel value={tabValue} index={1}>
                    <Box display="flex" flexDirection="row-reverse">
                        <Box m={1}>

                            <StatementsAndSendToNetsuiteButton processData={processData} />

                        </Box>
                    </Box>

                    <DraftInvoiceItemsList processData={processData} {...draftInvoiceItemsProps} />

                </TabPanel>
                }

                {/* @todo put the GenerateStatementsButton here same as above */}

                <Notification />
            </Box>
        </Container>
    );
};

ProcessesList.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

// @ts-ignore
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function formTabProps(index) {
    return {
        id: `processes-tab-${index}`,
        'aria-controls': `processes-tabpanel-${index}`,
    };
}

export default ProcessesList;
