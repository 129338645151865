import React, {Fragment, useState} from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    FunctionField,
    Filter,
    ReferenceInput,
    AutocompleteInput,
    useLoading,
    useQuery,
    SelectArrayInput,
    ReferenceArrayInput, TextInput, NumberInput
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";

import {
    ChargeFeeTypes,
    formatFeeDisplay, OrderTypes,
    ProcessStatusDisplay
} from "../../../utilities/Common";

import {Container, LinearProgress} from "@material-ui/core";
import DraftChargesExport from "./DraftChargesExport";
import DraftChargesListActions from "./DraftChargesListActions";

export const styles = (theme => ({
    organisationFilter: {
        width: '20em'
    },
    typeFilter: {
        minWidth: '10em'
    },
    orderFilter: {
        width: '10em'
    },
}));

const useStyles = makeStyles(styles);

const ListFilters = ({classes, ...props}) => (
    <Filter {...props}>
        <ReferenceInput source="countryUuid" reference="countries" label={'Country'}
                        filter={{marketId: localStorage.getItem('mId')}} sort={{id: 'asc'}} alwaysOn resettable allowEmpty={false}>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="organisationUuid" reference="organisations"
                        // filter={{market: localStorage.getItem('mId'), isProducer: 1, isActive: 1}}
                        sort={{name: 'asc'}}
                        resource="name" label={'Organisation'} className={classes.organisationFilter} alwaysOn resettable allowEmpty={false}>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="orderUuid" reference="orders" label={'Order Id'}
                        className={classes.orderFilter}
                        sortDefaultValues={{ orderId: 'desc'}} alwaysOn resettable allowEmpty={false}>
            <AutocompleteInput optionText="displayId" />
        </ReferenceInput>
        <ReferenceInput source="skuUuid" reference="skus" label={'SKU'} resettable alwaysOn allowEmpty={false}>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput source={'kegSerialNumber'} label={'Keg Serial Number'} resettable alwaysOn />
        <SelectArrayInput source="feeType" label={'Fee Description'}
                          choices={ChargeFeeTypes} className={classes.typeFilter} resettable alwaysOn  />
    </Filter>
);

const GetProcessStatusDisplay = ({processUuid, setProcessStatus}) => {
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'processes/draftcharges',
        payload: { id: processUuid }
    });

    if (loading) return <LinearProgress />;
    if (error) return  "Error";
    if (!data) return null;
    if(data){
        setProcessStatus(data.processStatus);
    }

    return (
        <ProcessStatusDisplay processStatusText={data.processStatusText} />
    )
};

const DraftChargeList = ({processUuid, ...props}) => {
    const loading = useLoading();
    const classes = useStyles();
    const [processStatus, setProcessStatus] = useState(0);

    return (
        <Fragment>
            <Container>
                { loading && <LinearProgress /> }
                <div style={{float: 'right'}}>
                    { processUuid &&
                        // @ts-ignore
                        <GetProcessStatusDisplay processUuid={processUuid} setProcessStatus={setProcessStatus} />}
                </div>
                {/* Content Start */}
                <List {...props} hasCreate={false}
                      actions={<DraftChargesListActions />}
                      filter={{processUuid: processUuid}}
                      filterDefaultValues={{ processUuid: processUuid }}
                      filters={<ListFilters classes={classes} />} sort={{ field: 'id', order: 'DESC' }} perPage={10} bulkActionButtons={false}
                      exporter={processStatus === 3 ? DraftChargesExport : false}
                >
                    <Datagrid>
                        <TextField source="organisationId" label={'Id'} sortable={false}/>
                        <TextField source="organisationName" label={'Organisation'} sortable={false}/>
                        <TextField source="cycleId" label={'Cycle Id'} sortable={false}/>
                        <TextField source="orderId" label={'Order Id'} sortable={false}/>
                        <TextField source="kegSerialNumber" label={'Keg Serial Number'} sortable={false}/>
                        <TextField source="skuName" label={'SKU'} sortable={false}/>
                        <TextField source="cycleStartDate" label={'Cycle Start Date'} sortable={false}/>
                        <TextField source="cycleEndDate" label={'Cycle End Date'} sortable={false}/>
                        <TextField source="cycleEndLocation" label={'Cycle End Location'} sortable={false}/>
                        <TextField source="cycleEndRole" label={'Cycle End Location Role'} sortable={false}/>
                        <TextField source="cycleEndRegion" label={'Cycle End Location Region'} sortable={false}/>
                        <TextField source="chargeId" label={'Charge Id'} sortable={false}/>
                        <DateField source="applicableDate" label={'Applicable Date'} sortable={false}/>
                        <TextField source="description" label={'Fee Description'} sortable={false}/>
                        <NumberField source="quantity" label={'Quantity'} sortable={false}/>
                        <FunctionField render={record => ( formatFeeDisplay(record.unitAmount)) } label={'Unit Amount'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.total)) } label={'Total'} />
                    </Datagrid>
                </List>
                {/* Content End */}
            </Container>
        </Fragment>
    );

}

export default DraftChargeList;

