import * as React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {Box, Button, Container, Typography, Grid, Chip, CircularProgress} from '@material-ui/core';
import Divider from "@material-ui/core/Divider";
import CyclesList from "../../resources/cycles/CyclesList";
import {useEffect, useState} from "react";
import axios from "axios";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Alert} from "@material-ui/lab";
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles({
    kegsAvailabilitySummaryHeader: {
        backgroundColor: '#e2e2e2',
        margin: 0,
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: 0,
        borderTopRightRadius: '5px',
        borderBottomRightRadius: 0,
        borderLeft: '2px solid #e2e2e2',
        borderTop: '2px solid #e2e2e2',
        borderRight: '2px solid #e2e2e2'
    },
    kegsAvailabilityAlertInfo: {
        padding: 0,
        paddingLeft: '10px',
        paddingRight: '10px',
        float: 'right'
    }
});

function createData(name, total, average) {
    return { name, total, average };
}

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12)
    },
}))(Tooltip);


const DisplayOrderTotalValue = ({data}) => {
    let backgroundColor = '#000';
    if (data.status === '4') {
        backgroundColor = '#5cab00';
    } else if (data.status === '5') {
        backgroundColor = '#a90018';
    }
    return <Chip label={parseFloat(data.total).toLocaleString("en-US")} color={'secondary'}  style={ {backgroundColor: backgroundColor, color: '#fff'} } size="small" />
}

const Cycles = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState(false);
    const [success, setSuccess] = useState(false);

    const cycleDetailsUrl = `${process.env.REACT_APP_KEGNOVA_API_URL}/tracks/cycles/hud?market=`+localStorage.getItem('mId');

    const cyclesProps = {
        basePath: "tracks/cycles",
        resource: "tracks/cycles"
    };

    const [data, setData] = useState([]);
    const [breakdownData, setBreakdownData] = useState([]);

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const result = await axios.get(cycleDetailsUrl, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey') } });
                console.warn(result.data)
                var result1 = result.data;
                setBreakdownData(result1.data.breakdown);
                setData(result1.data.openVsClosed);
                setLoading(false);
            } catch (error) {
                setErrorMessage('Retrieving Cycle details failed. ' + (error.response.length > 0) ? error.response.data.message : '');
                setLoading(false);
            }
        })();
    }, [cycleDetailsUrl]);

    function DenseTable() {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell align="right"><Typography variant="h6" component="h6">Total</Typography></TableCell>
                            <TableCell align="right"><Typography variant="h6" component="h6">Avg. Days</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Typography variant="subtitle1">{row.name}</Typography>
                                </TableCell>
                                <TableCell align="right">{row.total}</TableCell>
                                <TableCell align="right">{row.average}</TableCell>
                            </TableRow>
                        ))}
                        <br/>
                        <TableRow>
                            <TableCell component="th" scope="row">
                            </TableCell>
                            <TableCell align="right"/>
                            <TableCell align="right"/>
                        </TableRow>
                        {breakdownData.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Typography variant="subtitle1">{row.name}</Typography>
                                </TableCell>
                                <TableCell align="right">{row.total}</TableCell>
                                <TableCell align="right">{row.average}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    return (
        <Container>
            <Box m={2}>
                <Typography variant={'h4'}>Cycles</Typography>
            </Box>
            <Box m={1} >
                <Divider />
            </Box>
            <Box m={2}>
                <Grid container spacing={2}>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={10}>
                        <DenseTable/>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
            </Box>
            <Box m={1} display={'flex'} flexDirection={'row-reverse'}>
                <Box m={1}>
                    <Button variant={'contained'} onClick={ () => { window.location.href = `${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/cycles/create`; return false} }> Create Cycle </Button>
                </Box>
            </Box>

            <Box p={2}>
                {/* Cycles List */}
                <CyclesList {...cyclesProps} />
            </Box>
        </Container>
    );
};

export default Cycles;
