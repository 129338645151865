import * as React from 'react';
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {useState} from "react";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Link } from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import {getMarketColorCode} from "./common";
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles(theme => ({
    selectField: {
            backgroundColor: getMarketColorCode(),
            color: '#fff', width: '95px', textAlign: 'center', borderBottom: 'none',
    },
    menuItem: {
        width: '180px',
        backgroundColor: '#fff',
        color: '#000',
        "&:hover": {
            backgroundColor: '#000',
            color: "#fff"
        },
        "&:active": {
            backgroundColor: '#000',
            color: "#fff"
        },
    }
}));

const MarketSwitcherButton = ({marketColor}) => {
    const classes = useStyles();
    const handleChange = (event) => {
        console.log( event.target.value )
        localStorage.setItem('mId', event.target.value);
        window.location.reload();
        return false;
    };

    const marketCodes = [
        { id: 0, name: '', description: 'Global'},
        { id: 1, name: 'AU', description: 'Australia'},
        { id: 2, name: 'NZ', description: 'New Zealand'},
        { id: 3, name: 'UK', description: 'United Kingdom'},
        { id: 4, name: 'US', description: 'United States'}
    ]
    const getMarketCode = () => {
        let index = localStorage.getItem('mId') ? parseInt(localStorage.getItem('mId')) : 0;
        return marketCodes[index].name;
    }

    return (
        <Box p={1} bgcolor="black">
            <Select
                value={''}
                onChange={handleChange}
                className={classes.selectField}
                displayEmpty
            >
                <MenuItem value={''} style={{borderBottom: '1px solid #000', backgroundColor: '#000', color: '#fff', verticalAlign: 'middle'}}>
                    <LanguageIcon style={{paddingLeft: '6px', paddingRight: '5px'}} /> <span style={{fontWeight: 'bold', verticalAlign: 'super'}}> {getMarketCode()}</span>
                </MenuItem>
                {
                    marketCodes.map((row) =>
                        <MenuItem value={row.id} className={classes.menuItem}>{row.description}</MenuItem>
                    )
                }
            </Select>

        </Box>
    )
}

export default MarketSwitcherButton;
