import React, {useRef, useEffect} from 'react';
import { useFormState } from 'react-final-form';
import {
    Create,
    DateInput,
    SimpleForm,
    required, AutocompleteInput, ReferenceInput, TopToolbar, Toolbar, ShowButton, SaveButton, CreateButton, useNotify
} from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { Typography, Box } from '@material-ui/core';
import { AnyObject } from 'react-final-form';
import Button from '@material-ui/core/Button';
import axios from "axios";

const useStyles = makeStyles(theme => ({
    form: {
        display: 'flex'
    },
    buttons: {backgroundColor: theme.palette.primary.main , color: theme.palette.secondary.contrastText },
    toolbar: {
        width: '100%', alignItems: 'center',
    },
    organisationField: {
        width: '250px',
        paddingRight: '10px'
    },
    filterFields: {
        paddingRight: '10px'
    }
}));

export const validateFields = ({countryUuid, organisationUuid, dateFrom, dateTo}: AnyObject) => {
    const errors = {} as any;
    // console.log(countryUuid, organisationUuid, dateFrom, dateTo);
    // if (password && confirm_password && password !== confirm_password) {
    //     errors.confirm_password = [
    //         'resources.customers.errors.password_mismatch',
    //     ];
    // }

    return errors;
};
const DraftChargesActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        {/*<Button color="primary" >Custom Action</Button>*/}
    </TopToolbar>
);



const DraftChargesToolbar = ({classes, ...props}) => {
    const notify = useNotify();
    const formState = useFormState();

    const generateDraftCharges = async values => {
        let value = formState.values['organisationUuid'];
        // console.log(value);
        // return false;

        let payload = values;
        try {
            let url = `${process.env.REACT_APP_KEGNOVA_API_URL}/charges/draft/generate`;
            const options = {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',
                    'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')}
            };
            const result = await axios.post(url, payload, options);


            notify('billing.updatepackagesuccessful', 'info');
        } catch (error) {
            notify('billing.updatepackagefailed', 'warning');
        }
    }

    return(
        <Toolbar {...props} className={classes.toolbar}>
            <Box m={1} display="flex" justifyContent="center">
                <Box m={1}>
                    <Button className={classes.buttons} >View Draft Charges</Button>
                </Box>
                <Box m={1}>
                    <Button className={classes.buttons} onClick={generateDraftCharges}>Generate</Button>
                    {/*<CreateButton label={'Generate Draft Charges'} basePath={'/charges/draft'} />*/}
                </Box>
            </Box>
        </Toolbar>
    )
};

const DraftChargesCreate = props => {

    const classes = useStyles(props);
    const refContainer = useRef();

    return (
        // @ts-ignore
        <Create {...props} style={{display: 'flex'}} >
            <SimpleForm  validate={validateFields} toolbar={<DraftChargesToolbar classes={classes}/>} >
                <div style={{display: 'flex', width: '100%'}} >
                    <ReferenceInput source="countryUuid" reference="countries" label={'Country'}
                                    filter={{marketId: localStorage.getItem('mId')}} sort={{id: 'asc'}} className={classes.filterFields} resettable>
                        <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput source="organisationUuid" reference="organisations" label={'Organisation'} className={classes.organisationField}
                                    filter={{market: localStorage.getItem('mId'), isProducer: 1, isActive: 1}}
                                    sort={{name: 'asc'}}
                                    resettable>
                        <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                    <DateInput source="dateFrom" className={classes.filterFields} />
                    <DateInput source="dateTo" className={classes.filterFields} />
                </div>
            </SimpleForm>
        </Create>
    );
};

const requiredValidate = [required()];

export default DraftChargesCreate;
