// @ts-nocheck
import * as React from 'react';
import {useState} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
    Box, Button, CircularProgress, Container, Typography, LinearProgress,
}
    from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Notification, useGetList} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import PageviewIcon from "@material-ui/icons/Pageview";
import CachedIcon from "@material-ui/icons/Cached";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    UnauthorisedAccountingAccess,
    unauthorisedAccountingAccess,
    ProcessStatusDisplay,
    getMarketColorCode
} from "../../utilities/Common";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import YesIcon from "@material-ui/icons/CheckCircle";
import NoIcon from "@material-ui/icons/Cancel";
import APIErrorIcon from "@material-ui/icons/Error";

const useStyles = makeStyles(theme => ({
    buttons: {backgroundColor: theme.palette.primary.main , color: theme.palette.secondary.contrastText }
}));

const GetMonthlyProcesses = () => {

    const { data, ids, loading, error } = useGetList(
        'processes/monthly',
        { page: 1, perPage: 15 },
        { field: 'id', order: 'DESC' },
        { marketId: localStorage.getItem('mId') }
    );

    if (loading) { return <LinearProgress />; }
    if (error) { return <p><APIErrorIcon color={'red'}/> &nbsp;ERROR</p>; }
    if (!data) return null;
    const records = Object.values(data).sort((a,b)=> (b.id > a.id ? 1 : -1));

    const dateFormatting = (dateString) => {
        // to handle invalid date in safari "2010-08-09 01:02:03";
        let reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
        let dateArray = reggie.exec(dateString);
        return new Date(
            (+dateArray[1]),
            (+dateArray[2])-1, // Careful, month starts at 0!
            (+dateArray[3]),
            (+dateArray[4]),
            (+dateArray[5]),
            (+dateArray[6])
        );
    };

    return (
        <React.Fragment>
            <Box m={2}>
                <Typography variant={'h5'}>
                    <ListAltIcon /> Process List
                </Typography>
            </Box>
            { records.length > 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>

                                </TableCell>
                                <TableCell>
                                    <Typography variant={'subtitle1'}>Invoice Run</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={'subtitle1'}>Invoice Date</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={'subtitle1'}>Run Date</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={'subtitle1'}>Run By</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={'subtitle1'}>Status</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { records.map(
                                item =>
                                    (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                <Link href={`/#/billing/processes/${item.uuid}`} >
                                                    <PageviewIcon fontSize={'large'} />
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                {dateFormatting(item.messagePayload.startDate).toLocaleDateString()}
                                            </TableCell>
                                            <TableCell >
                                                {dateFormatting(item.messagePayload.cutoffDate).toLocaleDateString()}
                                            </TableCell>
                                            <TableCell>
                                                {new Date(new Date(item.runningDate).toISOString().split('T')[0]).toLocaleDateString()}
                                            </TableCell>
                                            <TableCell>
                                                {item.userName}
                                            </TableCell>
                                            <TableCell>
                                                <ProcessStatusDisplay processStatusText={item.processStatusText} />
                                            </TableCell>
                                        </TableRow>
                                    )
                            )}
                        </TableBody>
                    </Table>
                :
                <Box m={3}>
                    <Typography variant={'subtitle1'}> No Monthly Invoices process data. Click on <span style={{textDecoration: 'underline'}}>Generate Monthly Invoices</span> to run current month end billing.</Typography>
                </Box>
            }
        </React.Fragment>
    );
};


const GenerateInvoices = () => {
    let { uuid } = useParams();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [generateMonthlyBillingSuccessful, setGenerateMonthlyBillingSuccessful] = useState(false);
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const generateMonthlyBilling = async (e) => {
        e.preventDefault();
        setOpen(false);
        if(!localStorage.getItem('mId')){
            // notify('Check your Market session and try again', 'warning');
            setErrorMessage('Check your Market session and try again');
            return false;
        }
        if( (parseInt(localStorage.getItem('mId')) < 0) || (parseInt(localStorage.getItem('mId')) >= 5)) {
            // notify('Invalid Market Id', 'warning');
            setErrorMessage('Invalid Market Id');
            return false;
        }

        let payload = {
            marketId: parseInt(localStorage.getItem('mId'))
        };

        // console.log(payload);
        setLoading(true);

        try {
            let url = `${process.env.REACT_APP_KEGNOVA_API_URL}/charges/generate`;
            const options = {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',
                    'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')}
            };
            const result = await axios.post(url, payload, options);
            setLoading(false);
            // notify('Generate monthly billing successful.', 'info');
            setGenerateMonthlyBillingSuccessful(true);
        } catch (error) {
            setLoading(false);
            // console.log(error.response.data.message)
            let errMsg = `Generate monthly billing failed! ${error.response.data.message}`;
            // notify(errMsg, 'warning');
            setErrorMessage(errMsg);
        }
    }

    let draftChargesProps = {
        basePath: "/invoices/draft",
        resource: "invoices/draft"
    }
    // Check if user is allowed to access this accounting page
    if ( unauthorisedAccountingAccess() ) {
        return (
            <UnauthorisedAccountingAccess />
        )
    }

    return (
        <Container>

            <Box m={1}>
                <Typography variant={'h4'}>
                    Monthly Invoices
                </Typography>
            </Box>
            <Box m={1}>
                <Divider />
            </Box>
            <Box m={1}>
                <Box m={1} display="flex" flexDirection="row-reverse">
                    <Box m={1}>
                        { (loading) ? <span><CircularProgress size={12} /> &nbsp; Please wait...</span>
                            : <Button className={classes.buttons} onClick={handleClickOpen} ><CachedIcon />&nbsp;Generate Monthly Invoices</Button> }
                    </Box>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title-month-end-billing"
                        aria-describedby="alert-dialog-description-month-end-billing"
                    >
                        <DialogTitle id="alert-dialog-title-month-end-billing" style={{backgroundColor: getMarketColorCode(localStorage.getItem('mId')), color: 'white'}}>{"Start Month End Billing"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description-month-end-billing">
                                Are you sure you want to initiate Monthly Charge generation process? Once initiated, you will not be able to reverse or stop the process.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant={'contained'} onClick={(e)=>generateMonthlyBilling(e)} color="primary" autoFocus>
                                <YesIcon /> &nbsp; Yes
                            </Button>
                            <Button variant={'contained'} onClick={handleClose} color="primary">
                                <NoIcon /> &nbsp; No
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Box>
            <Box m={1}>
                { generateMonthlyBillingSuccessful &&

                <Alert severity="success" style={{display: 'flex', width: '95%'}}>
                    <AlertTitle variant={'h6'}> Success </AlertTitle>
                    Processing Charges. Status below will indicate when a process status is <strong>Completed</strong>. This may take some time.
                </Alert>

                }
                { errorMessage &&

                <Alert severity="error" style={{display: 'flex', width: '95%'}}>
                    <AlertTitle variant={'h6'}> Error </AlertTitle>
                    { errorMessage }
                </Alert>

                }
            </Box>
            <Box m={1}>
                { !loading && <GetMonthlyProcesses /> }
            </Box>
            <Notification />

        </Container>
    );
};

export default GenerateInvoices;
