// @ts-nocheck
import * as React from 'react';

import {
    Box, Button, Container,
    Typography,
}
    from '@material-ui/core';
import CountryPackageList from "../../resources/countries/psg/CountryPackageList";
import ScrollToRightSignal from "../../components/gifs/ScrollToRightSignal";
import Link from "@material-ui/core/Link";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import Divider from "@material-ui/core/Divider";


const CountryPackages = () => {
    // const loading = useLoading();
    let countryPackageProps = {
        basePath: "/countries/psg",
        resource: "countries/psg"
    }

    return (
        <Container>
            <Box m={2}>
                <Typography variant={'h4'} style={{paddingBottom: '10px', paddingLeft: '10px'}}>Country Packages</Typography>
            </Box>
            <Box m={1}>
                <Divider />
            </Box>
            <Box m={1}>
                <ScrollToRightSignal />
            </Box>
            <Box p={2}>

                <CountryPackageList {...countryPackageProps} />

            </Box>
        </Container>
    );
};

export default CountryPackages;
