import * as React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {Box, Button, Container, Typography, Grid, Chip, CircularProgress} from '@material-ui/core';
import Divider from "@material-ui/core/Divider";
import OrdersList from "../../resources/orders/OrdersList";
import {useEffect, useState} from "react";
import axios from "axios";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Alert} from "@material-ui/lab";
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles({
    kegsAvailabilitySummaryHeader: {
        backgroundColor: '#e2e2e2',
        margin: 0,
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: 0,
        borderTopRightRadius: '5px',
        borderBottomRightRadius: 0,
        borderLeft: '2px solid #e2e2e2',
        borderTop: '2px solid #e2e2e2',
        borderRight: '2px solid #e2e2e2'
    },
    kegsAvailabilityAlertInfo: {
        padding: 0,
        paddingLeft: '10px',
        paddingRight: '10px',
        float: 'right'
    }
});

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12)
    },
}))(Tooltip);


const DisplayOrderTotalValue = ({data}) => {
    let backgroundColor = '#000';
    if (data.status === '4') {
        backgroundColor = '#5cab00';
    } else if (data.status === '5') {
        backgroundColor = '#a90018';
    }
    return <Chip label={parseFloat(data.total).toLocaleString("en-US")} color={'secondary'}  style={ {backgroundColor: backgroundColor, color: '#fff'} } size="small" />
}

const Orders = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState(false);
    const [success, setSuccess] = useState(false);
    const url = `${process.env.REACT_APP_KEGNOVA_API_URL}/orders/summary?marketId=[${localStorage.getItem('mId')}]`;
    const [orderSummaryData, setOrderSummaryData] = useState(null);



    const ordersProps = {
        basePath: "/orders",
        resource: "orders"
    }

    return (
        <Container>
            <Box m={2}>
                <Typography variant={'h4'}>Orders</Typography>
            </Box>
            {/*<Box m={1}>*/}
            {/*    <Divider />*/}
            {/*</Box>*/}
            {/* Orders Summary */}
            {/*<Box m={2}>*/}
            {/*    <Grid container spacing={1}>*/}
            {/*        <Grid item xs={12} md={2} lg={2}>*/}
            {/*            <TableContainer component={Paper}>*/}
            {/*                <Table size="small" aria-label="order totals">*/}
            {/*                    <TableHead>*/}
            {/*                        <TableRow>*/}
            {/*                            <TableCell colSpan={2} style={{backgroundColor:'#000', color: '#fff'}}><Typography variant={'h6'}>Keg Sales Orders</Typography></TableCell>*/}
            {/*                        </TableRow>*/}
            {/*                    </TableHead>*/}
            {/*                    <TableBody>*/}
            {/*                        { !orderSummaryData && <TableRow>*/}
            {/*                            <TableCell colSpan={2}> <Box m={1} textAlign={'center'}><CircularProgress /></Box> </TableCell>*/}
            {/*                        </TableRow> }*/}
            {/*                        { orderSummaryData && orderSummaryData.totals.map((row) => (*/}
            {/*                            <TableRow key={row.status}>*/}
            {/*                                <TableCell component="th" scope="row">*/}
            {/*                                    {row.name}*/}
            {/*                                </TableCell> <TableCell align="right"> <DisplayOrderTotalValue data={row} /> </TableCell>*/}
            {/*                            </TableRow>*/}
            {/*                        )) }*/}
            {/*                    </TableBody>*/}
            {/*                </Table>*/}
            {/*            </TableContainer>*/}
            {/*        </Grid>*/}

            {/*        { !orderSummaryData &&*/}
            {/*        <Grid item xs>*/}
            {/*            <Paper>*/}
            {/*                <Box m={5} textAlign={'center'}><CircularProgress /></Box>*/}
            {/*            </Paper>*/}
            {/*        </Grid>*/}
            {/*        }*/}

            {/*        { orderSummaryData &&*/}
            {/*        <Grid item xs={12} md={10} lg={10}>*/}
            {/*            <Box m={1} display={'flex'}>*/}
            {/*                <Box m={1} flexGrow={1}>*/}
            {/*                    <Typography variant={'h5'}> Total Kegs Available*/}
            {/*                        <HtmlTooltip*/}
            {/*                            title={*/}
            {/*                                <React.Fragment>*/}
            {/*                                    <Alert severity="info" className={classes.kegsAvailabilityAlertInfo}>Note: this table doesn't take discrepancies/damaged into account</Alert>*/}
            {/*                                </React.Fragment>*/}
            {/*                            }*/}
            {/*                        >*/}
            {/*                            <InfoIcon />*/}
            {/*                        </HtmlTooltip>*/}
            {/*                    </Typography>*/}
            {/*                    <Typography variant={'h5'}>{parseFloat(orderSummaryData.stockOnHand.total).toLocaleString("en-US")}</Typography>*/}
            {/*                </Box>*/}
            {/*                <Box m={1}>*/}
            {/*                    <Button variant={'contained'} onClick={ () => { window.location.href = `${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/orders/create`; return false} }> Create Order </Button>*/}
            {/*                </Box>*/}
            {/*            </Box>*/}
            {/*            <Grid container spacing={1} alignContent={'center'} >*/}
            {/*                { orderSummaryData && orderSummaryData.stockOnHand.states.map((row) => (*/}
            {/*                    <Grid item xs={6} md={3} lg={3}>*/}
            {/*                        <Paper >*/}
            {/*                            <Box m={1} display={'flex'} className={classes.kegsAvailabilitySummaryHeader}>*/}
            {/*                                <Box><Typography variant={'subtitle1'} style={{paddingLeft: '5px'}}>{row.state} </Typography></Box>*/}
            {/*                                <Box m={1}></Box>*/}
            {/*                                <Box><Typography variant={'caption'} style={{color: 'gray', paddingRight: '5px'}}> {row.country} </Typography> </Box>*/}
            {/*                            </Box>*/}
            {/*                            <Box> <Divider /> </Box>*/}
            {/*                            <Box m={1} display={'flex'}>*/}
            {/*                                <Box flexGrow={1}><Typography variant={'h6'} style={{paddingLeft: '5px'}}>{ parseFloat(row.total).toLocaleString("en-US") } </Typography></Box>*/}
            {/*                                <Box><Typography variant={'h6'} style={{color: 'gray', paddingRight: '5px'}}> { row.percentage }% </Typography></Box>*/}
            {/*                            </Box>*/}
            {/*                        </Paper>*/}
            {/*                    </Grid>*/}
            {/*                ))}*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*        }*/}

            {/*    </Grid>*/}
            {/*</Box>*/}
            <Box m={1} >
                <Divider />
            </Box>
            <Box m={1} display={'flex'} flexDirection={'row-reverse'}>
                <Box m={1}>
                    <Button variant={'contained'} onClick={ () => { window.location.href = `${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/orders/create`; return false} }> Create Order </Button>
                </Box>
            </Box>

            <Box p={2}>
            {/* Orders List */}

                <OrdersList {...ordersProps} />


            </Box>
        </Container>
    );
};

export default Orders;
