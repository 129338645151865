import React, {Fragment, useState} from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    Confirm,
    FunctionField, Filter, ReferenceInput, AutocompleteInput, SelectInput, useLoading, DateInput, TextInput,
    SelectArrayInput, NumberInput,
    useListContext, useList, useAuthenticated, useRefresh
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import EmptyRecordIcon from '@material-ui/icons/Inbox';
import { useParams } from 'react-router-dom';
import axios from "axios";

import {
    applyCurrentFilter,
    CustomPagination, DispatchStatuses,
    formatFeeDisplay, getMarketColorCode, LocationRoleTypes, OrderStatuses, OrderTypes,
    ProcessStatusDisplay, setCurrentFilters
} from "../../utilities/Common";

import {Container, LinearProgress, Box, CircularProgress} from "@material-ui/core";
import DispatchesExport from "./DispatchesExport";
import DispatchesListActions from "./DispatchesListActions";
import BackIcon from "@material-ui/icons/ArrowBack";
import Link from "@material-ui/core/Link";
import {urlencoded} from "express";
import Button from '@material-ui/core/Button';
import { exit } from 'process';


export const styles = (theme => ({
    root: {},
    loading: {
        '& .MuiTable-root': {
            display: 'none'
        },
        '& .MuiTypography-body2': {
            display: 'none'
        },
        '& .MuiTablePagination-toolbar': {
            display: 'none'
        }

    },
    dispatchFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        }
    },
    organisationFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        width: '20em'
    },
    skuFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    },
    orderFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        maxWidth: '9em'
    },
    typeFilter: {
        minWidth: '8em'
    },
    locationRoleFilter: {
        minWidth: '13em'
    },
    statusFilter: {
        minWidth: '6em'
    },
    stateFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    }
}));

const useStyles = makeStyles(styles);

const getActionedAtDateFrom = () => {
    let days = 60;
    // @ts-ignore
    let result = new Date( new Date() - days * 24 * 60 * 60 * 1000);
    return result.toISOString().slice(0, 10);
}
const getActionedAtDateTo = () => {
    return new Date().toISOString().slice(0, 10);
}

const ToOrganisation = ({record}) => {
    return(
        <React.Fragment>
            { record.destinationOrganisationId ?
                <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.destinationOrganisationId}/overview`}>
                    <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.destinationOrganisation}</span>
                </Link>
                : null } <br/>
            { record.destinationLocationId ?
                <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.destinationOrganisationId}/locations/view/${record.destinationLocationId}`}>
                    <span style={{color: getMarketColorCode(localStorage.getItem('mId')) }}>{record.destinationLocation}</span> <br/>
                    <span style={{color: "gray" }}>({record.destinationLocationRoleText})</span>
                </Link>
                : null }
        </React.Fragment>
    )
}
const FromOrganisation = ({record}) => {
    return(
        <React.Fragment>
            { record.originOrganisation ?
                <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.originOrganisationId}/overview`}>
                    <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.originOrganisation}</span>
                </Link>
                 : 'Not Allocated' } <br/>
            { record.originLocationId ?
                <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.originOrganisationId}/locations/view/${record.originLocationId}`}>
                    <span style={{color: getMarketColorCode(localStorage.getItem('mId')) }}>{record.originLocation}</span> <br/>
                    <span style={{color: "gray" }}>({record.originLocationRoleText})</span>
                </Link>
                 : null }
        </React.Fragment>
    )
}

const ListFilters = props => {
    const classes = useStyles();

    // setCurrentFilters(props,
    //     ['id', 'status[]', 'context', 'totalKegsFrom', 'totalKegsTo',
    //         'originOrganisationUuid', 'originLocationUuid', 'originCountryUuid', 'originLocationRole[]',
    //     'destinationOrganisationUuid', 'destinationLocationUuid', 'destinationCountryUuid', 'destinationLocationRole[]',
    //     'requestedAt_from_date', 'requestedAt_to_date', 'actionedAt_from_date', 'actionedAt_to_date',
    //     'orderId', 'kegSerialNumber', 'isStarlight', 'type', 'isPromoteRequired'],
    //     'dispatches');

    return (
        <Filter {...props}>
            <ReferenceInput source="id" reference="dispatches" label={'Dispatch No.'}
                            filter={{market: localStorage.getItem('mId')}}
                            className={classes.dispatchFilter}
                            alwaysOn resettable allowEmpty emptyValue={null}>
                <AutocompleteInput optionText="displayId" />
            </ReferenceInput>
            <SelectArrayInput source="status" label={'Status'} choices={DispatchStatuses} className={classes.statusFilter} resettable alwaysOn />
            <SelectInput label={'Context'} source="context" choices={[
                { id: null, name: 'All' },
                { id: 1, name: 'Order' },
                { id: 2, name: 'Transfer' },
                { id: 3, name: 'Collection' },
                { id: 4, name: 'Stock Receipt' }
            ]} allowEmpty={false} alwaysOn />
            <NumberInput label={'No. of Kegs From'} source={'totalKegsFrom'} resettable />
            <NumberInput label={'No. of Kegs To'} source={'totalKegsTo'} resettable />
            <TextInput label={'Origin Organisation'} source={'originOrganisationName'} resettable={true} alwaysOn />
            <TextInput label={'Destination Organisation'} source={'destinationOrganisationName'} resettable={true} alwaysOn />
            <ReferenceInput source="originLocationUuid" reference="locations"
                            filter={{market: localStorage.getItem('mId')}}
                            sort={{ field: 'name', order: 'asc' }}
                            sortDefaultValues={{ field: 'name', order: 'asc' }} label={'Origin Location'}
                            className={classes.organisationFilter} resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="originCountryUuid" reference="countries"
                            sort={{ field: 'name', order: 'asc' }}
                            sortDefaultValues={{ field: 'name', order: 'asc' }} label={'Origin Country'} resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <SelectArrayInput source="originLocationRole" label={'Origin Location Role'}
                              choices={LocationRoleTypes} className={classes.locationRoleFilter} resettable />
            <ReferenceInput source="destinationLocationUuid" reference="locations"
                            filter={{market: localStorage.getItem('mId')}}
                            sort={{ field: 'name', order: 'asc' }}
                            sortDefaultValues={{ field: 'name', order: 'asc' }} label={'Destination Location'}
                            className={classes.organisationFilter} resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="destinationCountryUuid" reference="countries"
                            sort={{ field: 'name', order: 'asc' }}
                            sortDefaultValues={{ field: 'name', order: 'asc' }} label={'Destination Country'} resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <SelectArrayInput source="destinationLocationRole" label={'Destination Location Role'}
                              choices={LocationRoleTypes} className={classes.locationRoleFilter} resettable />
        </Filter>
    )
};

const DispatchesList = props => {
    useAuthenticated();
    const loading = useLoading();
    const classes = useStyles();
    const marketId = localStorage.getItem('mId');
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const state = { setErrorMessage: setErrorMessage,
                         setSuccessMessage: setSuccessMessage }

    const defaultActionedAtDateFrom = getActionedAtDateFrom();
    const defaultActionedAtDateTo =  getActionedAtDateTo();

    const refresh = useRefresh();
    const handleRefreshClick = () => {
        refresh();
    };
    const {orderId} = useParams();

    let defaultFilterValues = { orderId: orderId };

    const [openForce, setOpenForce] = useState(false);
    const [dispatchId, setDispatchId] = useState(0);


    const handleRemoveDispatch = async (id) => {
        setOpenForce(true);
        setDispatchId(id);
    }

    const handleDialogClose = () => {
        setOpenForce(false);
        setDispatchId(0);
    }

    const removeDispatch = async () => {
        setOpenForce(false);
        try {
            let url = `${process.env.REACT_APP_TRACKS_URL}/api/orders/${orderId}/dispatches/${dispatchId}`;
            const options = {
                headers: {
                    'x-api-key': localStorage.getItem('apiKey'),
                }
            };
            const payload = {};
             await axios.delete(url, options);
            alert('Dispatch Successfully removed');
            handleRefreshClick();

        } catch (error) {
            setErrorMessage('Error in force completing the error');
            setSuccessMessage(null);
        }
    };


//    https://staging.kegstar.com/api/orders/227877/dispatches/1691704



    // defaultFilterValues = applyCurrentFilter(defaultFilterValues,
    //     ['id', 'status[]', 'context', 'totalKegsFrom', 'totalKegsTo',
    //         'originOrganisationUuid', 'originLocationUuid', 'originCountryUuid', 'originLocationRole[]',
    //         'destinationOrganisationUuid', 'destinationLocationUuid', 'destinationCountryUuid', 'destinationLocationRole[]',
    //         'requestedAt_from_date', 'requestedAt_to_date', 'actionedAt_from_date', 'actionedAt_to_date',
    //         'orderId', 'kegSerialNumber', 'isStarlight', 'type', 'isPromoteRequired'],
    //     'dispatches');

    return (
        <Fragment>
            <Container maxWidth={false}>
                { loading && <LinearProgress /> }
                { loading &&
                <Box justifyContent={'center'} justifyItems={'center'}  alignItems={'center'} alignContent={'center'}
                     style={{backgroundColor: '#1f2124', color: '#fff', padding:'5px', position: 'fixed',
                         bottom: '0', zIndex: 50, width: '90%'}}>
                    <Box p={1}><CircularProgress size={20} style={{color: '#fff'}} /> Please wait...</Box>
                </Box>
                }
                <List {...props}
                      // className={ loading ? classes.loading : classes.root }
                      // hasCreate={false}
                      actions={<DispatchesListActions state={state} refreshData={handleRefreshClick} />}
                      // filter={{ market: marketId, tz: localStorage.getItem('timezone') }}
                      sort={{ field: 'id', order: 'desc' }}
                      perPage={10}
                      filters={ <ListFilters /> }
                      filterDefaultValues={defaultFilterValues}
                      bulkActionButtons={false}
                      exporter={DispatchesExport}
                      pagination={<CustomPagination />}
                >
                    <Datagrid
                        // empty={<Box m={3} textAlign={'center'}> <EmptyRecordIcon /> </Box>}
                    >

                        <FunctionField source={'orderId'} render={record => <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/dispatches/${record.id}/details`}>
                            <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.id}</span>
                        </Link> } label={'ID'} />

                        <FunctionField source={'context'} render={record => (record.contextText) } label={'Context'} />
                        <FunctionField source={'status'} render={record => (record.statusText) } label={'Status'} />
                        <NumberField source="totalKegs" label={'No. of Kegs'} />
                        {/*<NumberField source="badScans" label={'Bad Scans'} />*/}
                        <FunctionField source={'originOrganisation'} render={record => <FromOrganisation record={record} /> } label={'Origin'} />
                        <FunctionField source={'destinationOrganisation'} render={record => <ToOrganisation record={record} /> } label={'Destination'} />
                        <DateField source="requestedAt" label={'Due Date'} locales={(localStorage.getItem('mId') === '4') ? 'en-US' : 'en-GB'} />
                        <DateField source="actionedAt" label={'Actioned'} locales={(localStorage.getItem('mId') === '4') ? 'en-US' : 'en-GB'}
                          options={{ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }} />
                        <FunctionField label={'More Actions'} render={record =>
                                <Button variant={'contained'} onClick={()=> handleRemoveDispatch(record.id) }  size={'small'}>Remove from order</Button>
                        } />  
                        <Confirm
                                        isOpen={openForce}
                                        title="Force Complete order"
                                        content = "Are you sure you want to resend confirmation?"
                                        onConfirm={removeDispatch}
                                        onClose={handleDialogClose}
                                    />

                    </Datagrid>
                </List>
            </Container>
        </Fragment>
    );

}

export default DispatchesList;

