export const darkTheme = {
    palette: {
        primary: {
            main: '#90caf9',
        },
        secondary: {
            main: '#FBBA72',
        },
        type: 'dark' as 'dark', // Switching the dark mode on is a single property value change.
    },
    overrides: {
        MuiAppBar: {
            colorSecondary: {
                color: '#ffffffb3',
                backgroundColor: '#616161e6',
            },
        },
    },
};

export const lightTheme = {
    palette: {
        primary: {
            main: '#000',
            dark: '#111111',
            light: '#fff'
        },
        secondary: {
            light: '#5f5fc4',
            main: '#1a1a1a',
            dark: '#001064',
            contrastText: '#fff',
        },
        // background: {
        //     default: '#e7e6e7',
        // },
        type: 'light' as 'light',
    },
    typography: {
        fontFamily: 'NettoOT',
    },
    shape: {
        borderRadius: 10,
    },
    overrides: {
        RaMenuItemLink: {
            root: {
                borderLeft: '3px solid #fff',
            },
            active: {
                borderLeft: '3px solid #4f3cc9',
            },
        },
        MuiContainer: {
            root: {
                maxWidth: '2048px !important'
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'none',
            },
            root: {
                border: '1px solid #e0e0e3',
                backgroundClip: 'padding-box',
            },
        },
        MuiButton: {
            contained: {
                backgroundColor: '#000',
                color: '#fff'
            },
        },
        MuiAppBar: {
            colorSecondary: {
                color: '#808080',
                backgroundColor: '#fff',
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#f5f5f5',
            },
            barColorPrimary: {
                backgroundColor: '#d7d7d7',
            },
        },
        MuiToolbar: {
            root: {
                display: 'inline-flex'
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiTableSortLabel: {
            root: {
                "&:hover": {
                    color: "#fff"
                }
            },
            active: {
                color: '#fff !important'
            },
            icon: {
                color: '#fff !important'
            }
        },
        RaLayout: {
            root: {
                marginTop: 0
            },
            appFrame: {
                marginTop: '-10px'
            }
        },
        RaFilterButton: {
            root: {
                paddingTop: '0px !important'
            }
        },
        RaButton: {
            button: {
                alignItems: 'flex-start'
            }
        },
        RaBulkActionsToolbar: {
            toolbar: {
                width: '100%',
                backgroundColor: '#e7e6e7'
            }
        },
        RaSelectArrayInput: {
            chip: {
                backgroundColor: '#000',
                color: '#fff'
            }
        },
        RaDatagrid: {
            headerCell: {
                color: '#fff',
                backgroundColor: '#000',
            },
        },
    },
};
