// @ts-nocheck
import {downloadCSV} from 'react-admin';
import jsonExport from 'jsonexport/dist';

const PalletsExport = (records) => {

    const dataForExport = records.map(record => {

        const {
            createdAt, orgUuid, org_id, palletUuid, user_id, firstName, lastName, uuid,
            ...dataForExport
        } = record; // omit unecessary fields
        dataForExport.user_name = record.firstName + ' ' + record.lastName;

        // dataForExport.fieldName = record.field.name; // add a field
        // dataForExport.kegSerialNumber = (record.kegs) ? record.kegs.serialNumber : null;

        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'id', 'total_kegs', 'createdAtText', 'user_name', 'org_name',
        ], // order fields in the export
        rename: [
            'Pallets Id', 'No. of Kegs', 'Created At', 'User', 'Organisation Name',
        ]
    }, (err, csv) => {
        let today = new Date();
        downloadCSV(csv, 'Pallets_' + today.toISOString().split('T')[0]); // download as 'posts.csv` file
    });

};

export default PalletsExport;
