// @ts-nocheck
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Admin, Resource, DataProvider } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import './App.css';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';

import dataProviderFactory from './dataProvider';

import organisations from './resources/organisations';
import packages from './resources/packages';
import countries from './resources/countries';
import countriespsgs from './resources/countries/psg';
import organisationpsgs from './resources/organisations/psg';
import locationpsgs from './resources/locations/psg';
import locationstates from './resources/locations/states';
import locations from './resources/locations';
import orders from './resources/orders';
import transfers from './resources/transfers';
import pallets from './resources/pallets';
import cycles from './resources/cycles';
import dispatches from './resources/dispatches';
import orderDispatchesList from './resources/dispatches';
import collections from './resources/collections';
import collectionsfromlocation from './resources/collections/fromlocation';
import collectionsoriginlocation from './resources/collections/originlocation';
import collectionsusers from './resources/collections/users';
import collectionscontact from './resources/collections/contact';

import skus from './resources/skus';
import users from './resources/users';
import kegs from './resources/kegs';
import charges from './resources/charges';
import invoices from './resources/invoices';
import draftinvoices from './resources/invoices/draft';
import invoiceitems from './resources/invoiceitems';
import draftinvoiceitems from './resources/invoiceitems/draft';
import draftcharges from './resources/charges/draft';

import chargeproceses from './resources/processes/charges';
import monthlyprocesses from './resources/processes/monthly';
import draftchargeproceses from './resources/processes/draftcharges';
import draftinvoiceproceses from './resources/processes/draftinvoices';
import draftinvoicecountryproceses from './resources/processes/draft-invoice-country';
import draftinvoiceorganisationproceses from './resources/processes/draft-invoice-organisation';
import draftinvoiceregenerateproceses from './resources/processes/draft-invoice-regenerate';
import draftinvoiceresendtonetsuiteproceses from './resources/processes/draft-invoice-send-to-netsuite';
import invoicestatementproceses from './resources/processes/invoice-statement';
import listings from './resources/listings';

import leads from './resources/leads';

import KegnovaLoadingScreen from "./components/loaders/KegnovaLoadingScreen";
import LogisticsList from "./resources/logistics/LogisticsList";
import VenuesList from "./resources/venues/VenuesList";
import ProducersList from "./resources/producers/ProducersList";

const i18nProvider = polyglotI18nProvider(locale => {
    // if (locale === 'es') {
        // return import('./i18n/es').then(messages => messages.default);
    // }
    // Always fallback on english
    return englishMessages;
}, 'en');

const Loading = () => {
    return (
        <React.Fragment>
            <KegnovaLoadingScreen />
        </React.Fragment>
    );
};

const App = () => {
    const [dataProvider, setDataProvider] = useState<DataProvider>();

    useEffect(() => {
        let restoreFetch;

        const fetchDataProvider = async () => {
            restoreFetch = await dataProviderFactory(
                process.env.REACT_APP_DATA_PROVIDER || ''
            );
            const dataProviderInstance = await dataProviderFactory(
                process.env.REACT_APP_DATA_PROVIDER || ''
            );
            setDataProvider(
                // GOTCHA: dataProviderInstance can be a function
                () => dataProviderInstance
            );
        };

        fetchDataProvider();

        return restoreFetch;
    }, []);

    if (!dataProvider) {
        return (
            <Loading />
        );
    }

    return (
        <Admin disableTelemetry
            title=""
            dataProvider={dataProvider}
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            loading={Loading}
            layout={Layout}
            i18nProvider={i18nProvider}
        >
            <Resource name="countries/psg" {...countriespsgs} />
            <Resource name="countries" {...countries} />
            <Resource name="organisations/psg" {...organisationpsgs} />
            <Resource name="organisations" {...organisations} />
            <Resource name="locations/psg" {...locationpsgs} />
            <Resource name="locations/states" {...locationstates} />
            <Resource name="locations" {...locations} />
            <Resource name="skus" {...skus} />
            <Resource name="orders" {...orders} />
            <Resource name="transfers" {...transfers} />
            <Resource name="pallets" {...pallets} />
            <Resource name="tracks/cycles" {...cycles} />
            <Resource name="dispatches" {...dispatches} />
            <Resource name="/order/dispatches" {...orderDispatchesList} />
            <Resource name="collections" {...collections} />
            <Resource name="collections/fromlocation" {...collectionsfromlocation} />
            <Resource name="collections/originlocation" {...collectionsoriginlocation} />
            <Resource name="collections/users" {...collectionsusers} />
            <Resource name="collections/contact" {...collectionscontact} />

            <Resource name="kegs" {...kegs} />
            <Resource name="users" {...users} />
            <Resource name="charges/draft" {...draftcharges} />
            <Resource name="charges" {...charges} />
            <Resource name="packages" {...packages} />

            <Resource name="invoices" {...invoices} />
            <Resource name="invoices/draft" {...draftinvoices} />
            <Resource name="invoiceitems" {...invoiceitems} />
            <Resource name="invoiceitems/draft" {...draftinvoiceitems} />

            <Resource name="processes/monthly" {...monthlyprocesses} />
            <Resource name="processes/charges" {...chargeproceses} />
            <Resource name="processes/draftcharges" {...draftchargeproceses} />
            <Resource name="processes/draftinvoices" {...draftinvoiceproceses} />
            <Resource name="processes/draftinvoicecountry" {...draftinvoicecountryproceses} />
            <Resource name="processes/draftinvoiceorganisation" {...draftinvoiceorganisationproceses} />
            <Resource name="processes/draftinvoice-regenerate" {...draftinvoiceregenerateproceses} />
            <Resource name="processes/draftinvoicesendtonetsuite" {...draftinvoiceresendtonetsuiteproceses} />
            <Resource name="processes/invoicestatement" {...invoicestatementproceses} />

            <Resource name="contact-requests" {...leads} />
            <Resource name="tracks/organisations" {...listings} />
            <Resource name="organisations/logistics" list={LogisticsList} />
            <Resource name="organisations/venues" list={VenuesList} />
            <Resource name="organisations/producers" list={ProducersList} />

        </Admin>
    );
};

export default App;
