import React from 'react';
import { forwardRef } from 'react';
import { Pagination, Notification } from 'react-admin';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {useQuery} from "react-admin";
import {Box, Chip, CircularProgress, Container} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CancelIcon from "@material-ui/icons/Cancel";
import SuccessIcon from "@material-ui/icons/CheckCircle";

export const GetMarket = ({marketId}) => {
    let marketName = '';

    switch (marketId) {
        case 1:
            marketName = 'AU';
            break;
        case 2:
            marketName = 'NZ';
            break;
        case 3:
            marketName = 'UK';
            break;
        case 4:
            marketName = 'US';
            break;
        default:
            marketName = 'Global';
    }
    return marketName;
}

export const getMarketColorCode = (market) => {
    let colorCode = '#525558';
    switch (market) {
        case "1":
            colorCode = '#496901';
            break;
        case "2":
            colorCode = '#607080';
            break;
        case "3":
            colorCode = '#7E0819';
            break;
        case "4":
            colorCode = '#201d56';
            break;
        default:
            colorCode = '#525558';
            break;
    }

    return colorCode;
}

export const GetCountry = ({uuid}) => {
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'countries',
        payload: { id: uuid }
    });

    if (loading) return <CircularProgress size={20}/>;
    if (error) return <ErrorIcon style={{color: 'red'}} />;
    if (!data) return null;

    return data.name
};

export const GetOrganisation = ({uuid}) => {
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'organisations',
        payload: { id: uuid }
    });

    if (loading) return <CircularProgress size={20}/>;
    if (error) return <ErrorIcon style={{color: 'red'}} />;
    if (!data) return null;

    return data.name
};

export const GetLocation = ({uuid}) => {
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'locations',
        payload: { id: uuid }
    });

    if (loading) return <CircularProgress size={20}/>;
    if (error) return <ErrorIcon style={{color: 'red'}} />;
    if (!data) return null;

    return data.name
};

export const GetSku = ({uuid}) => {
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'skus',
        payload: { id: uuid }
    });

    if (loading) return <CircularProgress size={20}/>;
    if (error) return <ErrorIcon style={{color: 'red'}} />;
    if (!data) return null;

    return data.name
};


export const defaultTableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export function formatFeeDisplay(fee) {
    if(fee) {
        return parseFloat(fee).toFixed(2);
    } else {
        return '-';
    }
}

export function formatBusinessDate(bussinessTime) {
    
    const DAY_HOURS = [
        {'': 'Any'},
        {value: '0000', name:'12 AM'},
        {value: '0100', name: '01 AM'},
        {value: '0200', name: '02 AM'},
        {value: '0300', name: '03 AM'},
        {value: '0400', name: '04 AM'},
        {value: '0500', name: '05 AM'},
        {value: '0600', name: '06 AM'},
        {value: '0700', name: '07 AM'},
        {value: '0800', name: '08 AM'},
        {value: '0900', name: '09 AM'},
        {value: '1000', name: '10 AM'},
        {value: '1100', name: '11 AM'},
        {value: '1200', name: '12 PM'},
        {value: '1300', name: '01 PM'},
        {value: '1400', name: '02 PM'},
        {value: '1500', name: '03 PM'},
        {value: '1600', name: '04 PM'},
        {value: '1700', name: '05 PM'},
        {value: '1800', name: '06 PM'},
        {value: '1900', name: '07 PM'},
        {value: '2000', name: '08 PM'},
        {value: '2100', name: '09 PM'},
        {value: '2200', name: '10 PM'},
        {value: '2300', name: '11 PM'},
    ];
    const arr = [];
    if(bussinessTime) {
        const bussinessTimeArr = bussinessTime.split(',');
        var len = '';
        let i = 0, arrayParamsLength = bussinessTimeArr.length;
        // Loop into multiple key params and append the key with []
        while (i < arrayParamsLength) {
            len = '';
            let bussinessTimeZone = bussinessTimeArr[i].split('@');
            switch (parseInt(bussinessTimeZone[0])) {
                case 0:
                    len +=  "Sunday   ";
                    break;
                case 1:
                    len +=  "Monday   ";
                    break;
                case 2:
                    len +=  "Tuesday  ";
                    break;
                case 3:
                    len +=  "Wednesday";
                    break;
                case 4:
                    len +=  "Thursday ";
                    break;
                case 5:
                    len +=  "Friday   ";
                    break;
                case 6:
                    len +=  "Saturday ";
                    break;    
                default:
                    len +=  "Any";
            }
            len += " : ";
            if(parseInt(bussinessTimeZone[1]) == 0) {
                len += "Closed ";
            } else {
                let toTime = DAY_HOURS.find(o => o.value === bussinessTimeZone[2]);
                if (toTime !== undefined) {
                    len += toTime.name + " to ";
                }
                let fromTime = DAY_HOURS.find(o => o.value === bussinessTimeZone[3]);
                if (fromTime !== undefined) {
                    len += fromTime.name;
                }
            }
            arr.push(len);
            i++
        }
        return arr.join('\r\n');
    } else {
        return 'N/A';
    }
}

export function getCountryUuid() {
    let countryId = localStorage.getItem('cId');

    switch (countryId) {
        case ("1" || 1):
            return '52aa17f5-c15f-4ab1-8ee6-8ca7ab481136'
            break;
        case ("2" || 2):
            return '81359d1d-c771-428f-8d34-ac9a853c99fe'
            break;
        case ("3" || 3):
            return '96b787e1-581a-4dba-95a5-35f1080ffa76'
            break;
        case ("4" || 4):
            return 'a8e590c3-4853-4780-9a9e-b42ce8fac73f'
            break;
        default:
            return null;
    }

}

export function stopAtRoleDisplay(stopAtRole) {
    switch (stopAtRole) {
        case 1:
            return "ROLE_WAREHOUSE";
            break;
        case 2:
            return "ROLE_PRODUCER";
            break;
        case 3:
            return "ROLE_DISTRIBUTOR";
            break;
        case 4:
            return "ROLE_VENUE";
            break;
        case 5:
            return "ROLE_COLLECTOR";
            break;
        case 6:
            return "ROLE_CONTRACT_BREWER";
            break;
        default:
            return "";
    }
}

export const ChargeFeeTypes = [
    { id: 1, name: 'Issue Fee' },
    { id: 2, name: 'Daily Rental Fee' },
    { id: 3, name: 'Timed Fee' },
    { id: 4, name: 'Cleaning Fee' },
    { id: 5, name: 'Freight Capital Metro Fee' },
    { id: 6, name: 'Inter-Country Fee' },
    { id: 7, name: 'Taproom Venue Fee' },
    { id: 8, name: 'Taproom Distributor Fee' },
    { id: 9, name: 'Freight Regional Metro Fee' },
    { id: 10, name: 'Freight Regional Fee' },
    { id: 11, name: 'Manual Fee' },
    { id: 12, name: 'Freight Super Regional Fee' },
    { id: 13, name: 'Collection Regional Fee' },
    { id: 14, name: 'Collection Super Regional Fee' }
];

export const OrderStatuses = [
    { id: 0, name: 'Draft' },
    { id: 1, name: 'Submitted' },
    { id: 2, name: 'Filling' },
    { id: 3, name: 'Delivering' },
    { id: 4, name: 'Completed' },
    { id: 5, name: 'Cancelled' },
    { id: 6, name: 'Forecast' },
];

export const OrderTypes = [
    { id: 1, name: 'CUSTOMER REQUESTED' },
    { id: 2, name: 'TAPROOM DISTRIBUTOR' },
    { id: 3, name: 'TAPROOM VENUE' },
    { id: 4, name: 'MISSED SCANS' },
    { id: 5, name: 'TAPROOM PRODUCER' },
    { id: 6, name: 'SELF DISTRIBUTION' },
    { id: 7, name: 'CONTRACT BREWING' }
];

export const KegTypes = [
    { id: 'lease', name: 'Leasing' },
    { id: 'pooling', name: 'Pooling' }
];

export const LocationRoleTypes = [
    { id: 1, name: 'Warehouse' },
    { id: 2, name: 'Producer' },
    { id: 3, name: 'Distributor' },
    { id: 4, name: 'Venue' },
    { id: 5, name: 'Collector' },
];

export const DispatchStatuses = [
    { id: 0, name: 'New' },
    { id: 1, name: 'Kegs Picked' },
    { id: 2, name: 'In-Transit' },
    { id: 3, name: 'Delivered' },
    { id: 4, name: 'Rejected' },
    { id: 5, name: 'Cancelled' },
    { id: 6, name: 'Staged' },
];

export const OpenDays = [
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
    { id: 7, name: 'Sunday' },
];

export const CollectionTime = [
    { id: 0, name: '12 AM' },
    { id: 1, name: '1 AM' },
    { id: 2, name: '2 AM' },
    { id: 3, name: '3 AM' },
    { id: 4, name: '4 AM' },
    { id: 5, name: '5 AM' },
    { id: 6, name: '6 AM' },
    { id: 7, name: '7 AM' },
    { id: 8, name: '8 AM' },
    { id: 9, name: '9 AM' },
    { id: 10, name: '10 AM' },
    { id: 11, name: '11 AM' },
    { id: 12, name: '12 PM' },
    { id: 13, name: '1 PM' },
    { id: 14, name: '2 PM' },
    { id: 15, name: '3 PM' },
    { id: 16, name: '4 PM' },
    { id: 17, name: '5 PM' },
    { id: 18, name: '6 PM' },
    { id: 19, name: '7 PM' },
    { id: 20, name: '8 PM' },
    { id: 21, name: '9 PM' },
    { id: 22, name: '10 PM' },
    { id: 23, name: '11 PM' }
];

export function unauthorisedAccountingAccess() {
    if( !(localStorage.getItem('role').indexOf('ROLE_ACCOUNTING') > -1) ) {
        return true;
    } else {
        return false;
    }
}
export const UnauthorisedAccountingAccess = () => {
    return (
        <Container>
            <Box p={2}>
                <Alert severity="error" style={{display: 'flex', width: '95%'}}>
                    <AlertTitle variant={'h6'}> Unauthorised </AlertTitle>
                    You are not allowed to access this page.
                </Alert>
            </Box>
        </Container>
    )
}

export function isForFinancialNavigation() {
    if (window.location.href.indexOf('/billing') > -1) {
        return true;
    } else {
        return false;
    }
}

export function isForOrderFeeOverrideNavigation() {
    if (window.location.href.indexOf('/orders/edit') > -1) {
        return true;
    } else {
        return false;
    }
}

export const highlightCurrentPsaDiscount = (rentalCount, lowerBound, upperBound) => {
    if (!rentalCount) return false;
    if( (rentalCount >= lowerBound) && (rentalCount <= upperBound) ) {
        return true;
    } else {
        return false;
    }
}

export const ProcessStatusDisplay = ({processStatusText}) => {
    let avatar = <MoreHorizIcon style={{backgroundColor: '#31708f'}} />;
    let style = {paddingLeft: '6px', backgroundColor: '#31708f'};
    let showLoading = false;

    if (processStatusText.toLowerCase().indexOf('canceled') > -1 || processStatusText.toLowerCase().indexOf('terminated') > -1) {
        avatar = <CancelIcon style={{backgroundColor: '#e5b800'}} />;
        style = {paddingLeft: '6px', backgroundColor: '#e5b800'};
    } else if (processStatusText.toLowerCase().indexOf('failed') > -1) {
        avatar = <ErrorIcon style={{backgroundColor: '#a90018'}}  />;
        style = {paddingLeft: '6px', backgroundColor: '#a90018'};
    } else if (processStatusText.toLowerCase().indexOf('completed') > -1) {
        avatar = <SuccessIcon style={{backgroundColor: '#5cab00'}}/>;
        style = {paddingLeft: '6px', backgroundColor: '#5cab00'};
    } else {
        showLoading = true;
    }

    return (
        <React.Fragment>
            <Chip label={processStatusText} color={'secondary'} avatar={avatar} style={style} size="small" />
            { showLoading && <CircularProgress size={10} style={{marginTop: '10px', marginLeft: '5px'}} /> }
        </React.Fragment>
    )
}

export const stopAtRoleChoices = [
        { id: 'ROLE_WAREHOUSE', name: 'Warehouse' },
        { id: 'ROLE_PRODUCER', name: 'Producer' },
        { id: 'ROLE_DISTRIBUTOR', name: 'Distributor' },
        { id: 'ROLE_VENUE', name: 'Venue' }
    ]


export const setCurrentFilters = (props, filters, resource) => {
    // Reset filters first
    for (var i = 0; i < filters.length; i++) {
        localStorage.removeItem(filters[i]+'_Filter_'+resource);
    }
    // Set current filters
    for (const i in props.filterValues) {
        if(props.filterValues[i]){
            localStorage.setItem(`${i}_Filter_${resource}`, props.filterValues[i]);
        }
    }
}

export const applyCurrentFilter = (defaultFilterValues, filters, resource = '') => {

    for (const i in filters) {
        let key = filters[i];
        if(localStorage.getItem(key+'_Filter_'+resource)){
            defaultFilterValues = {...defaultFilterValues, [key]: localStorage.getItem(key+'_Filter_'+resource)};
        }
    }

    return defaultFilterValues;
}

export const CustomPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const ChargesPageCustomPagination = props => <Pagination rowsPerPageOptions={[10, 50, 100, 200, 300, 500]} {...props} />;

export const downloadProgress = (currentPage, total, perPage) => {
    let percentage = currentPage/(total/perPage) * 100;
    if(percentage >= 100) {
        percentage = 100;
    }
    return Math.floor((percentage).toFixed(0));
}


export const CustomNotification = props => <Notification {...props} autoHideDuration={20000}
                                     ContentProps={{ style: { backgroundColor: '#000', borderColor: '#000'} }} />;
