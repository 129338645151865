


function get (endpoint, query) {
    // console.log(query);

    return new Promise((resolve, reject) => {
        let url = endpoint + '?';
        let totalCount = 0;
        let start = parseInt(query.page) * parseInt(query.pageSize);
        let end = start + parseInt(query.pageSize);
        let order = (query.orderDirection) ? query.orderDirection : 'desc';
        url += '_start=' + start;
        url += '&_end=' + end;
        url += '&_order=' + order;

        if(query.orderBy) {
            url += '&_sort=' + query.orderBy.field ;
        }

        if(query.search) {
            url += '&q=' + query.search;
        }

        fetch(url)
            .then(response => {
                totalCount = response.headers.get('X-Total-Count');
                return response.json();
            })
            .then(result => {
                if (result) {
                    resolve({
                        data: result,
                        page: query.page,
                        totalCount: totalCount
                    })
                } else {
                    reject();
                }
            })
    })
}

export default {
    get : get
};
