import * as React from 'react';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {Box, Button, Container, Typography, Grid, Chip, CircularProgress} from '@material-ui/core';
import Divider from "@material-ui/core/Divider";
import OrganisationListingList from "../../resources/listings/OrganisationListingList";
import {useEffect, useState} from "react";

const useStyles = makeStyles({
    kegsAvailabilitySummaryHeader: {
        backgroundColor: '#e2e2e2',
        margin: 0,
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: 0,
        borderTopRightRadius: '5px',
        borderBottomRightRadius: 0,
        borderLeft: '2px solid #e2e2e2',
        borderTop: '2px solid #e2e2e2',
        borderRight: '2px solid #e2e2e2'
    },
    kegsAvailabilityAlertInfo: {
        padding: 0,
        paddingLeft: '10px',
        paddingRight: '10px',
        float: 'right'
    }
});

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12)
    },
}))(Tooltip);


const DisplayOrderTotalValue = ({data}) => {
    let backgroundColor = '#000';
    if (data.status === '4') {
        backgroundColor = '#5cab00';
    } else if (data.status === '5') {
        backgroundColor = '#a90018';
    }
    return <Chip label={parseFloat(data.total).toLocaleString("en-US")} color={'secondary'}
                 style={{backgroundColor: backgroundColor, color: '#fff'}} size="small"/>
}

const OrganisationListing = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [success, setSuccess] = useState(false);

    const listingsProps = {
        basePath: "tracks/organisations",
        resource: "tracks/organisations"
    };

    return (
        <Container>
            <Box m={2}>
                <Typography variant={'h4'}> Organisations Overview </Typography>
            </Box>
            <Box m={1}>
                <Divider/>
            </Box>
            <Box m={1} display={'flex'} flexDirection={'row-reverse'}>
                <Box m={1}>
                    <Button variant={'contained'} onClick={() => {
                        window.location.href = `${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/add`;
                        return false
                    }}>Add Organisation</Button>
                </Box>
            </Box>

            <Box p={2}>
                <OrganisationListingList {...listingsProps} />
            </Box>
        </Container>
    );
};

export default OrganisationListing;
