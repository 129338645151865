import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    ListProps,
    useAuthenticated,
} from 'react-admin';
import {FC} from "react";
import {Container} from "@material-ui/core";

const ChargeProcessesList: FC<ListProps> = props => {
    useAuthenticated();

    return (
        <React.Fragment>
            <Container style={{width: 'auto', overflowX: 'scroll', maxWidth: '100vw', padding: '8px'}}>
                <List
                    {...props}
                >
                    <Datagrid style={{ maxWidth: '1356px' }}>
                        <TextField label={'Id'} source={'id'} />
                    </Datagrid>
                </List>

            </Container>
        </React.Fragment>
    );
};

export default ChargeProcessesList;

