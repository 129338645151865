import React, {useState} from 'react';
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import {Button, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: '#000',
        color: '#fff'
    },
    searchBar: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
}));
export const SearchBar = ({state, setState}) => {
    const classes = useStyles();
    const [query, setQuery] = useState('');
    const handleParam = setValue => e => setValue(e.target.value);
    const preventDefault = f => e => {
        e.preventDefault(); f(e);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setState({ ...state, searchBarIsOpen: false });
        setQuery(query);
        window.location.href = `${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/search?term=${query}`;
        return false;
    }

    return (
        <Container>
            <Box m={5}></Box>
            <Box m={1} className={classes.header}>
               <Box m={1}><Typography variant={'h4'}><SearchIcon fontSize={"large"} /> Search</Typography> </Box>
            </Box>
            <Box m={1}>
                <Box m={1}><Typography variant={'h5'} >Enter a keg code, venue, producer, etc...</Typography></Box>
            </Box>
            <form className={classes.searchBar} noValidate autoComplete="off" onSubmit={handleSubmit}>

                <Container>
                    <TextField variant={'outlined'} id="global-search" label="Search..." type="search" autoFocus={true}
                               value={query} name='term' onChange={handleParam(setQuery)} />
                    <Box m={1} display={'flex'}>
                        <Box><Button color={'primary'} variant={'contained'} type={'submit'}>Search</Button></Box>
                        <Box m={1}></Box>
                        <Box><Button color={'primary'} variant={'contained'} onClick={()=>setState({ ...state, searchBarIsOpen: false })}>Close</Button></Box>
                    </Box>
                </Container>

            </form>
        </Container>
    );
}

export default function Search({ term, setState }) {
    console.log('query', term)
    return (
        <React.Fragment>
            { term ? <div>Searching for {term}</div> :
                <SearchBar setState={setState} />
            }

        </React.Fragment>
    )
}

export async function getServerSideProps(context) {
    const term = context.query.term ? context.query.term : null;

    return {
        props: {
            term
        }, // will be passed to the page component as props
    }
}
