import React, {Fragment, useState} from 'react';
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    FunctionField,
    List,
    NumberField,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectArrayInput,
    useAuthenticated,
    useLoading
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";

import {CustomPagination, getMarketColorCode, OrderStatuses} from "../../utilities/Common";

import {Box, CircularProgress, Container, LinearProgress} from "@material-ui/core";
import TransfersExport from "../transfers/TransfersExport";
import TransfersListActions from "../transfers/TransfersListActions";
import Link from "@material-ui/core/Link";

export const styles = (theme => ({
    root: {},
    loading: {
        '& .MuiTable-root': {
            display: 'none'
        },
        '& .MuiTypography-body2': {
            display: 'none'
        },
        '& .MuiTablePagination-toolbar': {
            display: 'none'
        }

    },
    orderFilter: {
        width: '10em'
    },
    organisationFilter: {
        width: '18em'
    },
    skuFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    },
    typeFilter: {
        minWidth: '8em'
    },
    statusFilter: {
        minWidth: '6em'
    },
    stateFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    }
}));

const useStyles = makeStyles(styles);

const ListFilters = props => {
    const classes = useStyles();

    return (
        <Filter {...props}>
            <NumberInput source={'id'} label={'Transfer No.'} min={0} resettable alwaysOn/>

            <SelectArrayInput source="status" label={'Transfer Status'}
                              choices={OrderStatuses} className={classes.typeFilter} resettable alwaysOn/>

            <ReferenceInput source="fromOrganisationUuid" reference="organisations"
                // filter={{market: localStorage.getItem('mId'), isProducer: 1, isActive: 1}}
                            sort={{field: 'name', order: 'asc'}}
                            sortDefaultValues={{field: 'name', order: 'asc'}} label={'From'}
                            className={classes.organisationFilter} alwaysOn resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
            <ReferenceInput source="toOrganisationUuid" reference="organisations"
                // filter={{market: localStorage.getItem('mId'), isActive: 1}}
                            sort={{field: 'name', order: 'asc'}}
                            sortDefaultValues={{field: 'name', order: 'asc'}} label={'To'}
                            className={classes.organisationFilter} alwaysOn resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
            <ReferenceArrayInput source={'skuUuid'} reference="skus" label={'Keg Type'}
                                 className={classes.skuFilter} resettable alwaysOn>
                <SelectArrayInput source={'skuUuid[]'} optionText="name"/>
            </ReferenceArrayInput>

            <DateInput label={'Due Date From'} source={'requestedAt_from_date'} alwaysOn resettable/>
            <DateInput label={'Due Date To'} source={'requestedAt_to_date'} alwaysOn resettable/>

            <DateInput label={'Placed From'} source={'createdAt_from_date'} resettable alwaysOn/>
            <DateInput label={'Placed To'} source={'createdAt_to_date'} resettable alwaysOn/>

            <DateInput label={'Actioned From'} source={'actionedAt_from_date'} resettable alwaysOn/>
            <DateInput label={'Actioned To'} source={'actionedAt_to_date'} resettable alwaysOn/>

            <NumberInput label={'No. of Kegs From'} source={'kegsRequestedFrom'} resettable alwaysOn/>
            <NumberInput label={'No. of Kegs To'} source={'kegsRequestedTo'} resettable alwaysOn/>
        </Filter>
    )
};

const ToOrganisation = ({record}) => {
    return (
        <React.Fragment>
            {record.toOrganisationName ?
                <Link
                    href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.toOrganisationId}/overview`}>
                    <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.toOrganisationName}</span>
                </Link>
                : null} <br/>
            {record.toLocationId ?
                <Link
                    href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.toOrganisationId}/locations/view/${record.toLocationId}`}>
                    <span
                        style={{color: getMarketColorCode(localStorage.getItem('mId'))}}>{record.toLocationName}</span>
                </Link>
                : null}
        </React.Fragment>
    )
};


const FromOrganisation = ({record}) => {
    return (
        <React.Fragment>
            {record.fromOrganisationName ?
                <Link
                    href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.fromOrganisationId}/overview`}>
                    <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.fromOrganisationName}</span>
                </Link>
                : 'Not Allocated'} <br/>
            {record.fromLocationId ?
                <Link
                    href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.fromOrganisationId}/locations/view/${record.fromLocationId}`}>
                    <span
                        style={{color: getMarketColorCode(localStorage.getItem('mId'))}}>{record.fromLocationName}</span>
                </Link>
                : null}
        </React.Fragment>
    )
};

const TransfersList = props => {
    useAuthenticated();
    const loading = useLoading();
    const classes = useStyles();
    const marketId = localStorage.getItem('mId');
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const state = {
        setErrorMessage: setErrorMessage,
        setSuccessMessage: setSuccessMessage
    };

    // let defaultFilterValues = {market: marketId, tz: Intl.DateTimeFormat().resolvedOptions().timeZone};

    // @ts-ignore
    return (
        <Fragment>
            <Container maxWidth={false}>
                {loading && <LinearProgress/>}
                {loading &&
                <Box justifyContent={'center'} justifyItems={'center'} alignItems={'center'} alignContent={'center'}
                     style={{
                         backgroundColor: '#1f2124', color: '#fff', padding: '5px', position: 'fixed',
                         bottom: '0', zIndex: 50, width: '90%'
                     }}>
                    <Box p={1}><CircularProgress size={20} style={{color: '#fff'}}/> Please wait...</Box>
                </Box>
                }
                <List {...props}
                      actions={<TransfersListActions state={state}/>}
                      filter={{ market: marketId, order: 'name'}}
                      filters={<ListFilters/>}
                      // sort={{field: 'id', order: 'desc'}}
                      perPage={10}
                      bulkActionButtons={false}
                      exporter={TransfersExport}
                      pagination={<CustomPagination/>}
                >
                    <Datagrid>
                        <FunctionField source={'id'} render={record => <Link
                            href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/transfers/${record.id}/details`}>
                            <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.id}</span>
                        </Link>} label={'Transfer No.'}/>
                        <FunctionField source={'status'} render={record => (record.statusText)}
                                       label={'Transfer Status'}/>
                        <NumberField source="kegsRequested" label={'No. of Kegs'}/>
                        <FunctionField source={'fromOrganisationName'}
                                       render={record => <FromOrganisation record={record}/>} label={'From'}/>
                        <FunctionField source={'toOrganisationName'}
                                       render={record => <ToOrganisation record={record}/>} label={'To'}/>
                        <DateField source="createdAt" label={'Placed'}
                                   locales={(localStorage.getItem('mId') === '4') ? 'en-US' : 'en-GB'}
                                   options={{
                                       day: 'numeric',
                                       month: 'numeric',
                                       year: 'numeric',
                                       hour: 'numeric',
                                       minute: 'numeric',
                                       hour12: true,
                                       timeZone: localStorage.getItem('timezone')
                                   }}/>
                        <DateField source="requestedAt" label={'Due Date'}
                                   locales={(localStorage.getItem('mId') === '4') ? 'en-US' : 'en-GB'}/>
                        <FunctionField source={'actionedAt'} render={record =>
                            record.actionedAt
                                ?
                                <DateField source="actionedAt" label={'Actioned'}
                                           locales={(localStorage.getItem('mId') === '4') ? 'en-US' : 'en-GB'}
                                           options={{
                                               day: 'numeric',
                                               month: 'numeric',
                                               year: 'numeric',
                                               hour: 'numeric',
                                               minute: 'numeric',
                                               hour12: true,
                                               timeZone: localStorage.getItem('timezone')
                                           }}/>
                                : '-'
                        } label={'Actioned'}/>
                        <FunctionField source={'skuId'} render={record => (record.sku.name)} label={'Keg Type'}/>
                    </Datagrid>
                </List>
            </Container>
        </Fragment>
    );

};

export default TransfersList;

