import * as React from "react";
import {useState, useEffect} from "react";
import {Box, Container, Grid, Typography} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";


import axios from "axios";
import Link from "@material-ui/core/Link";

import PalletsList from "./PalletsList";

const ScansList = props => {
    const [url, setUrl] = useState(`${process.env.REACT_APP_KEGNOVA_API_URL}/pallet/${props.palletUuid}`);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);


    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const result = await axios.get(url, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Cache': 'no-cache',
                        'x-api-key': localStorage.getItem('apiKey')
                    }
                });
                setErrorMessage(null);
                setLoading(false);
                setData(result.data);
            } catch (error) {
                setErrorMessage('Retrieving Scans failed.' + error.message);
                setLoading(false);
            }
        })();
    }, [url]);


    return (
        <Container>
            {errorMessage &&
            <Box m={2}>
                <React.Fragment>
                    <Box display={'inline'}>
                        <Alert severity="error">
                            <AlertTitle > Failed to get the Scans </AlertTitle>
                            <Box m={1}>
                                {errorMessage}
                            </Box>
                        </Alert>
                    </Box>
                </React.Fragment>
            </Box>
            }

            {loading &&
            <Box m={2}>
                <React.Fragment>
                    <Box display={'inline'}>
                        <Alert severity="info">
                            <AlertTitle > Fetching Data ... </AlertTitle>
                        </Alert>
                    </Box>
                </React.Fragment>
            </Box>
            }
            <Box m={1}>
                <Box m={1}><Box m={3}/><Typography variant={'h5'}>Scans</Typography></Box><Box m={2}/>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Box m={1}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell> <Typography variant={'body1'}
                                                                style={{fontWeight: 'bold'}}>Barcode </Typography></TableCell>
                                        <TableCell><Typography variant={'body1'}
                                                               style={{fontWeight: 'bold'}}>Source</Typography></TableCell>
                                        <TableCell><Typography variant={'body1'}
                                                               style={{fontWeight: 'bold'}}>Method </Typography></TableCell>
                                        <TableCell><Typography variant={'body1'}
                                                               style={{fontWeight: 'bold'}}>Created</Typography></TableCell>
                                        <TableCell><Typography variant={'body1'} style={{fontWeight: 'bold'}}>Bad
                                            Dispatch</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        (data)
                                            ?
                                            data.map(
                                                item => {
                                                    // let currentPsaDiscount = packageDetails.rentalCounts  ? highlightCurrentPsaDiscount(packageDetails.rentalCounts.previousQuarter , item.lowerBound, item.upperBound) : false;
                                                    return (
                                                        <TableRow key={item.id}>
                                                            <TableCell>
                                                                <Link
                                                                    href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/view/${item.kegId}/cycles`}>
                                                                <span style={{
                                                                    fontWeight: 'bold',
                                                                    color: '#1887ad'
                                                                }}>{item.serialNumber}</span>
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell>{item.sourceText}</TableCell>
                                                            <TableCell>{item.methodText}</TableCell>
                                                            <TableCell>{item.createdAt}</TableCell>
                                                            <TableCell>{item.badDispatch ? '✗' : ''}</TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            )
                                            :
                                            <TableRow>
                                                <TableCell colSpan={4}>No Scans</TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
};

export default ScansList;