// @ts-nocheck
import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    Select
} from '@material-ui/core';
import {AutocompleteInput, NumberInput, ReferenceInput, SimpleForm, TextInput, Toolbar, SelectInput} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import axios from "axios";
import {useFormState} from "react-final-form";
import Typography from "@material-ui/core/Typography";
import Slider from '@material-ui/core/Slider';
import Alert from "@material-ui/lab/Alert/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";

const useStyles = makeStyles(theme => ({
    dateFields: {
        minWidth: '230px',
        margin: '10px'
    },
    textFields: {
        minWidth: '220px',
        margin: '10px',
    },
    optionTextFields: {
        minWidth: '450px',
        marginTop: '10px',
        marginBottom: '10px',
    },
    dowTextField: {
        minWidth: '220px',
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '10px'
    },
    optionFirstHiddenTextFields: {
        minWidth: '220px',
        marginTop: '10px',
        marginBottom: '10px',
        display: 'none'
    },
    hiddenTextFields: {
        minWidth: '220px',
        margin: '10px',
        display: 'none'
    },
    selectFields: {
        minWidth: '220px',
        margin: '10px'
    },
    selectBooleanFields: {
        minWidth: '170px',
        margin: '10px'
    },

    LinkFields: {
        color: "#4183c4",
        padding: "20px"
    },
    hiddenRadioFields: {
        display: 'none',
        paddingRight: 0,
    },
    radioFields: {
        display: 'unset',
        paddingRight: 0,
        marginTop: '10px'
    },
    buttonContained: {
        color: 'white',
        backgroundColor: 'black'
    },
    buttonOutlined: {
        color: 'black',
        backgroundColor: '#fafafa'
    },
    gridRowStyle: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'baseline'
    },
    gridRowStyleHidden: {
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        display: 'none'
    }
}));

function valuetext(value) {
    return `${value}°C`;
}

function valueLabelFormat(value) {
    if (value < 13) {
        return `${value} am`
    } else {
        return `${value - 12} pm`
    }
}

const sliderTimeMarks = [
    {
        value: 7,
        label: '7am',
    },
    {
        value: 8,
        label: '8am',
    },
    {
        value: 9,
        label: '9am',
    },
    {
        value: 10,
        label: '10am',
    },
    {
        value: 11,
        label: '11am',
    },
    {
        value: 12,
        label: '12pm',
    },
    {
        value: 13,
        label: '1pm',
    },
    {
        value: 14,
        label: '2pm',
    },
    {
        value: 15,
        label: '3pm',
    },
    {
        value: 16,
        label: '4pm',
    },
    {
        value: 17,
        label: '5pm',
    },
    {
        value: 18,
        label: '6pm',
    },
    {
        value: 19,
        label: '7pm',
    },
];

const VenuesCollectPage = ({updateCollectPageOrgId, ...props}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);

    const [value, setTimeInterval] = React.useState([7, 19]);
    const [selectedButtonTabValue, setSelectedButtonTabValue] = useState(0);

    let urlOrganisationLocation = `https://q98zqbqajj.execute-api.ap-southeast-2.amazonaws.com/beta/organisations/locations-by-orgid?org_id=${props.org_id}`;
    const [dataOrganisationLocation, setDataOrganisationLocation] = useState([]);
    const [organisationDetailData, setOrganisationDetailData] = useState(null);
    const [organisationContactData, setOrganisationContactData] = useState(null);

    const [venueOpenDays, setVenueOpenDays] = React.useState({
        mon: true,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
    });

    const [locationFromLocation, setLocationFromLocation] = useState(null);
    const [locationFromLocationChoices, setLocationFromLocationChoices] = useState(null);

    const [collectionOnsite, setCollectionOnsite] = useState(null);
    const [collectionOnsiteChoices, setCollectionOnsiteChoices] = useState(null);


    const [toLocation, setToLocation] = useState(null);
    const [toLocationChoices, setToLocationChoices] = useState(null);

    const [sendNotificationEnabled, setSendNotificationEnabled] = useState(true);
    const [notificationChoices, setNotificationChoices] = useState(null);

    const [isPreferredCollector, setIsPreferredCollector] = useState(false);

    const [preferredCollectorLocationUuid, setPreferredCollectorLocationUuid] = useState(null);


    const handleNotificationChoices = (itemNotificationId) => {
        const updatedNotificationChoices = notificationChoices.map((i) => {
            if (i.id === itemNotificationId) {
                i.isChecked = !i.isChecked;
            }
            return i;
        });
        setNotificationChoices(updatedNotificationChoices);
    };

    const toggleNotificationEnabled = () => {
        if (!sendNotificationEnabled) {
            setNotificationChoices(prevState => {
                return prevState.map(obj => {
                    return {...obj, isChecked: true};
                });
            });
        }
        setSendNotificationEnabled(!sendNotificationEnabled);
    };

    const toggleVenueOpenDays = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVenueOpenDays({
            ...venueOpenDays,
            [event.target.name]: event.target.checked,
        });
    };

    const {mon, tue, wed, thu, fri} = venueOpenDays;
    const venueOpenDaysError = [mon, tue, wed, thu, fri].filter((v) => v).length !== 2;


    //fetching all the contacts for an organisation
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                let url = `${process.env.REACT_APP_KEGNOVA_API_URL}/tracks/organisations/${props.org_id}/contacts`;
                const options = {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                        'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')
                    }
                };
                const result = await axios.get(url, options);
                setOrganisationContactData(result.data);

                let choices = [];
                result.data.forEach((item, index) => {
                    choices.push({
                        key: item.contactId,
                        name: item.contactFirstName + ' ' + item.contactLastName,
                        phone: item.contactPhone,
                    })
                });
                setCollectionOnsiteChoices(choices);

            } catch (error) {
                setErrorMessage('Error getting the organisation contacts details' + (error.response.length > 0) ? error.response.data.message : '');
            }
        })();
    }, [props.org_id]);


    //fetching the organisation detail
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                let url = `${process.env.REACT_APP_KEGNOVA_API_URL}/tracks/organisations/${props.org_id}`;
                const options = {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                        'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')
                    }
                };
                const result = await axios.get(url, options);
                console.log("###### organisaion detail", result);
                setOrganisationDetailData(result.data);

                let choices = [];
                let choices2 = [];
                result.data.forEach((organisation)=>{
                    organisation.locations.forEach((singleLocation)=>{
                        singleLocation.preferredCollectorContact.forEach((singleCollectorContact)=>{
                            choices.push({
                                id: singleCollectorContact.contactId,
                                name: singleCollectorContact.contactFirstName + ' ' + singleCollectorContact.contactLastName,
                                phone: singleCollectorContact.contactPhone,
                                email: singleCollectorContact.contactEmail,
                            });
                        });

                        singleLocation.preferredCollectorLocations.forEach((singleCollectorLocation)=>{
                            choices2.push({
                                id: singleCollectorLocation.id,
                                address: singleCollectorLocation.address,
                                name: singleCollectorLocation.name,
                                latitude: singleCollectorLocation.latitude,
                                longitude: singleCollectorLocation.longitude,
                                isChecked: false
                            });
                        });

                        setPreferredCollectorLocationUuid(singleLocation.preferredCollectorUuid);
                    });

                });

                setNotificationChoices(choices);
                setToLocationChoices(choices2);
            } catch (error) {
                setErrorMessage('Error getting the organisation details' + (error.response.length > 0) ? error.response.data.message : '');
            }
        })();
    }, [props.org_id]);


    //fetching the aws org location
    useEffect(() => {
        setLoading(true);
        (async () => {
            const request = await new Request(
                urlOrganisationLocation,
                {
                    method: 'GET',
                    headers: new Headers({
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'x-api-key': localStorage.getItem('apiKey')
                    }),
                    credentials: 'omit',
                }
            );

            try {
                await fetch(request)
                    .then(response => {
                        if (response.status < 200 || response.status >= 300) {
                            throw new Error(response.statusText);
                        }

                        return response.json();
                    })
                    .then((data) => {
                        setErrorMessage(null);
                        setLoading(false);
                        setDataOrganisationLocation(data.response);

                        let choices = [];
                        data.response.forEach((item, index) => {
                            choices.push({
                                key: item.id,
                                id: item.id,
                                name: item.name,
                                address: item.address,
                                distance: item.distance ? item.distance : ''
                            })
                        });
                        setLocationFromLocationChoices(choices);
                        return;
                    });
            } catch (error) {
                setErrorMessage('Retrieving  all the org location failed.' + error.message);
                setLoading(false);
            }
        })();
    }, [props.org_id]);

    const handleSliderChange = (event, newValue, activeThumb) => {
        const minDistance = 1;
        if (!Array.isArray(newValue)) {
            return;
        }

        if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], 19 - minDistance);
                setTimeInterval([clamped, clamped + minDistance]);
            } else {
                const clamped = Math.max(newValue[1], minDistance);
                setTimeInterval([clamped - minDistance, clamped]);
            }
        } else {
            setTimeInterval(newValue);
        }
    };

    const tracksApiHandler = (url, mode, payload) => {
        const options = {
            headers: {
                'Accept': 'application/json', 'Content-Type': 'application/json',
                'x-api-key': localStorage.getItem('apiKey')
            }
        };

        if (mode === 'get') {
            return axios.get(url, options);
        } else if (mode === 'patch') {
            return axios.patch(url, payload, options);
        } else if (mode === 'post') {
            return axios.post(url, payload, options);
        }
    };

    //@todo submit button
    const handleSubmit = async (e, formState) => {
        e.preventDefault();
        let form = formState.values;
        console.log("form>>>>>", form);
        const openDays = [];
        if (venueOpenDays['mon']) {
            openDays.push('monday')
        }
        if (venueOpenDays['tue']) {
            openDays.push('tuesday')
        }
        if (venueOpenDays['wed']) {
            openDays.push('wednesday')
        }
        if (venueOpenDays['thu']) {
            openDays.push('thursday')
        }
        if (venueOpenDays['fri']) {
            openDays.push('friday')
        }

        let payload = {
            approxQuantity: form['approxQuantity'],
            collectFromTime: value[0],
            collectToTime: value[1],
            openDays: openDays,
            // collectionNote: form['collectionNote'],
            deliverToNote: form['deliverToNote'],
            fromLocation: locationFromLocation,
            contact: collectionOnsite,
            callType: selectedButtonTabValue,

        };
        console.log("payload #####", payload);

        try {
            let url = `${process.env.REACT_APP_TRACKS_URL}/api/collection-requests`;
            const response = await tracksApiHandler(url, 'post', payload);
            if (response.data) {
                console.log("success####", response.data);
                setErrorMessage(null);
            }

            setSuccess(true);
            setErrorMessage(null);
        } catch (error) {
            setErrorMessage('Error Submitting the collection' + (error.response.length > 0) ? error.response.data.message : '');
        }

    };

    const ActionToolbar = ({classes, ...actionProps}) => {
        const formState = useFormState();
        return (
            <Toolbar {...actionProps} style={{marginLeft: '180px', backgroundColor: 'unset'}}>
                <Box>
                    <Button variant={'contained'} onClick={(e) => handleSubmit(e, formState)}
                            disabled={isSubmitting}>Submit Collection</Button>
                    <Button variant={'contained'} onClick={(e) => handleSubmit(e, formState)}
                            disabled={isSubmitting}>Save as Draft</Button>
                    <Button onClick={() => updateCollectPageOrgId()} variant={'text'} size={'small'}
                            style={{fontWeight: 'bold', color: '#1887ad'}}>Cancel</Button>

                </Box>
            </Toolbar>
        )
    };

    const validateContactCreation = (values) => {
        const errors = {};
        if (!values.firstName) {
            errors.firstName = 'The firstName is required';
        }
        if (!values.age) {
            // You can return translation keys
            errors.age = 'ra.validation.required';
        } else if (values.age < 18) {
            // Or an object if the translation messages need parameters
            errors.age = {
                message: 'ra.validation.minValue',
                args: { min: 18 }
            };
        }
        return errors
    };

    return (
        <Container>

            <Box m={1}>
                { success &&
                    <Alert severity="success" style={{display: 'flex', width: '95%'}}>
                        <AlertTitle variant={'h6'}> Success </AlertTitle>
                        {success}
                    </Alert>
                }

                { errorMessage &&
                    <Alert severity="error" style={{display: 'flex', width: '95%'}}>
                        <AlertTitle variant={'h6'}> Error </AlertTitle>
                        { errorMessage }
                    </Alert>
                }

                {loading &&
                    <Alert severity="info" style={{display: 'flex', width: '95%'}}>
                        <AlertTitle variant={'h6'}> Fetching Data, please wait ... </AlertTitle>
                    </Alert>
                }
            </Box>

            {dataOrganisationLocation.length > 0 && props.org_id &&
                 <Box m={2} style={{display:'flex', justifyContent:'center'}}>
                    <SimpleForm toolbar={<ActionToolbar/>} style={{
                        border: '1px solid #d4d8dd', fontSize: '16px',
                        lineHeight: 1.42857143,
                        color: '#525558',
                        width: '70%'
                    }}>
                        <Grid container spacing={0} style={{minWidth: '70%', margin: 'auto', width: 'unset'}}>

                            <Grid container direction="row"
                                  style={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      alignItems: 'baseline',
                                      marginBottom: '10px'
                                  }}>

                                <Typography style={{'minWidth': '17%', color: 'black'}} varient={'subtitle1'}>Call
                                    Type:</Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: 'min-content',
                                        alignItems: 'center',
                                        '& > *': {
                                            m: 1,
                                        },
                                    }}
                                >
                                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                                        <Button
                                            className={selectedButtonTabValue === 0 ? classes.buttonContained : classes.buttonOutlined}
                                            onClick={() => setSelectedButtonTabValue(0)}>INBOUND COLLECTION</Button>
                                        <Button
                                            className={selectedButtonTabValue === 1 ? classes.buttonContained : classes.buttonOutlined}
                                            onClick={() => setSelectedButtonTabValue(1)}>OUTBOUND COLLECTION</Button>
                                        <Button
                                            className={selectedButtonTabValue === 2 ? classes.buttonContained : classes.buttonOutlined}
                                            onClick={() => setSelectedButtonTabValue(2)}>NO KEGS</Button>
                                        <Button
                                            className={selectedButtonTabValue === 3 ? classes.buttonContained : classes.buttonOutlined}
                                            onClick={() => setSelectedButtonTabValue(3)}>NO CONTACT</Button>
                                        <Button
                                            className={selectedButtonTabValue === 4 ? classes.buttonContained : classes.buttonOutlined}
                                            onClick={() => setSelectedButtonTabValue(4)}>VOICE MAIL</Button>
                                        <Button
                                            className={selectedButtonTabValue === 5 ? classes.buttonContained : classes.buttonOutlined}
                                            onClick={() => setSelectedButtonTabValue(5)}>EMAILED</Button>
                                        <Button
                                            className={selectedButtonTabValue === 6 ? classes.buttonContained : classes.buttonOutlined}
                                            onClick={() => setSelectedButtonTabValue(6)}>COMPLAINT</Button>
                                    </ButtonGroup>
                                </Box>
                            </Grid>

                            <Grid container direction="row"
                                  className={selectedButtonTabValue in [0, 1] ? classes.gridRowStyle : classes.gridRowStyleHidden}>
                                <Typography style={{'minWidth': '27%', color: 'black'}} varient={'subtitle1'}
                                >Collection From: </Typography>
                                <Typography style={{'minWidth': '27%', color: 'black'}} varient={'subtitle1'}
                                >{dataOrganisationLocation[0].name}</Typography>
                            </Grid>

                            <Grid container direction="row"
                                  className={selectedButtonTabValue in [0, 1] ? classes.gridRowStyleHidden : classes.gridRowStyle}
                            >
                                <Typography style={{'minWidth': '27%', color: 'black'}} varient={'subtitle1'}
                                >Call Activity For: </Typography>
                                <Typography style={{'minWidth': '27%', color: 'black'}} varient={'subtitle1'}
                                >{dataOrganisationLocation[0].name}</Typography>
                            </Grid>

                            <Grid container direction="row"
                                  className={classes.gridRowStyle}>
                                <Typography style={{'minWidth': '27%', color: 'black'}}
                                            varient={'subtitle1'}>Location:* </Typography>

                                <Select
                                    value={locationFromLocation}
                                    onChange={(e) => setLocationFromLocation(e.target.value)}
                                    label=""
                                    className={classes.optionTextFields}
                                    // style={{
                                    //     border: '1px solid',
                                    //     paddingLeft: '10px'
                                    // }}
                                >
                                    {locationFromLocationChoices && locationFromLocationChoices.map((item) => {
                                        return (
                                            <MenuItem value={item.key}>({item.id})<br/> {item.name} <br/> {item.address}
                                                <br/> {item.distance}</MenuItem>
                                        );
                                    })}

                                </Select>
                            </Grid>

                            <Grid container direction="row"
                                  className={classes.gridRowStyle}>
                                <Typography style={{'minWidth': '27%', color: 'black'}}>Collection Onsite: </Typography>
                                <Select
                                    value={collectionOnsite}
                                    onChange={(e) => setCollectionOnsite(e.target.value)}
                                    label=""
                                    className={classes.optionTextFields}
                                >
                                    {
                                        collectionOnsiteChoices && collectionOnsiteChoices.length > 0 ? collectionOnsiteChoices.map((item) => {
                                                return (
                                                    <MenuItem value={item.key}>{item.name}<br/> {item.phone} <br/> </MenuItem>
                                                );
                                            }) :
                                            <MenuItem disabled value={null}>
                                                <em>No Contact Available. Please Add a new contact.</em>
                                            </MenuItem>
                                    }
                                </Select>
                            </Grid>

                            {/*@todo: add new contact */}
                            {/*<Grid container direction="row"*/}
                            {/*      className={classes.gridRowStyle}>*/}

                            {/*    <Typography style={{'minWidth': '27%', color: 'black'}}> </Typography>*/}
                            {/*    <Typography>*/}
                            {/*        <Button variant={'text'} size={'small'}*/}
                            {/*                style={{fontWeight: 'bold', color: '#1887ad'}}>Add New Contact</Button>*/}
                            {/*    </Typography>*/}
                            {/*</Grid>*/}

                            {/*<Grid container direction="row"*/}
                            {/*      className={classes.gridRowStyle}>*/}

                            {/*    <Typography style={{'minWidth': '27%', color: 'black'}}> </Typography>*/}
                            {/*    <SimpleForm validate={validateContactCreation}>*/}
                            {/*        <TextInput label="First Name:*" source="firstName"/>*/}
                            {/*        <TextInput label="Last Name:*" source="lastName"/>*/}
                            {/*        <SelectInput label="Role" source="role" choices={[*/}
                            {/*            { id: 'programming', name: 'Programming' },*/}
                            {/*            { id: 'lifestyle', name: 'Lifestyle' },*/}
                            {/*            { id: 'photography', name: 'Photography' },*/}
                            {/*        ]} translateChoice={false}/>*/}

                            {/*        <TextInput label="Phone:*" source="phone" type="tel" />*/}
                            {/*        <TextInput label="Email Address" source="email" type="email" />*/}

                            {/*        <SelectInput label="Timezone:*" source="role" choices={[*/}
                            {/*            { id: 'programming', name: 'Programming' },*/}
                            {/*            { id: 'lifestyle', name: 'Lifestyle' },*/}
                            {/*            { id: 'photography', name: 'Photography' },*/}
                            {/*        ]} translateChoice={false}/>*/}
                            {/*    </SimpleForm>*/}
                            {/*</Grid>*/}
                            {/*@todo: add new contact */}

                            <Grid container direction="row"
                                  className={selectedButtonTabValue in [0, 1] ? classes.gridRowStyleHidden : classes.gridRowStyle}>
                                <Typography style={{'minWidth': '27%', color: 'black'}}>Note: </Typography>
                                <TextInput source="note" label={''}
                                           className={classes.optionTextFields}
                                           variant="outlined"
                                           multiline
                                           rows={4}
                                />
                            </Grid>

                            <Grid container direction="row"
                                  className={selectedButtonTabValue in [0, 1] ? classes.gridRowStyle : classes.gridRowStyleHidden}>
                                <Typography style={{'minWidth': '27%', color: 'black'}}>Approx No. Kegs:* </Typography>
                                <NumberInput source="approxQuantity" label={''}
                                             className={classes.optionTextFields}
                                             variant="outlined"
                                             defaultValue={0}
                                             min={0}/>

                            </Grid>

                            <Grid container direction="row"
                                  className={selectedButtonTabValue in [0, 1] ? classes.gridRowStyle : classes.gridRowStyleHidden}>

                                <Typography style={{'minWidth': '27%', color: 'black'}} varient={'subtitle1'}> Collection
                                    Notes <br/>
                                    <Typography varient={'caption'}>
                                        (Persistent Notes)
                                    </Typography>
                                </Typography>
                                <TextInput source="collectionNote" label={''}
                                           className={classes.optionTextFields}
                                           variant="outlined"
                                           multiline
                                           rows={3}
                                />
                            </Grid>

                            <Grid container direction="row"
                                  className={selectedButtonTabValue in [0, 1] ? classes.gridRowStyle : classes.gridRowStyleHidden}
                                  style={{
                                      alignItems: 'baseline !important'
                                  }}>
                                <Typography style={{'minWidth': '27%', color: 'black'}} varient={'subtitle1'}> Deliver To
                                    Notes:</Typography>
                                <TextInput source="deliverToNote" label={''}
                                           className={classes.optionTextFields}
                                           variant="outlined"
                                           multiline
                                           rows={3}
                                />
                            </Grid>

                            <Grid container direction="row"
                                  className={selectedButtonTabValue in [0, 1] ? classes.gridRowStyle : classes.gridRowStyleHidden}>
                                <Typography style={{'minWidth': '27%', color: 'black'}} varient={'subtitle1'}> Venue Open
                                    Days:</Typography>
                                <FormControl
                                    required
                                    error={venueOpenDaysError}
                                    component="fieldset"
                                    sx={{m: 3}}
                                    variant="standard"
                                >
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={mon} onChange={toggleVenueOpenDays} name="mon"/>
                                            }
                                            label="MON"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={tue} onChange={toggleVenueOpenDays} name="tue"/>
                                            }
                                            label="TUE"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={wed} onChange={toggleVenueOpenDays} name="wed"/>
                                            }
                                            label="WED"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={thu} onChange={toggleVenueOpenDays} name="thu"/>
                                            }
                                            label="THU"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={fri} onChange={toggleVenueOpenDays} name="fri"/>
                                            }
                                            label="FRI"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>

                            <Grid container direction="row"
                                  className={selectedButtonTabValue in [0, 1] ? classes.gridRowStyle : classes.gridRowStyleHidden}
                                  style={{marginTop: '20px'}}>
                                <Typography style={{'minWidth': '27%', color: 'black'}} varient={'subtitle1'}> Opening Hours
                                    :</Typography>
                                <Box sx={{width: 500}}>
                                    <Slider
                                        value={value}
                                        onChange={handleSliderChange}
                                        valueLabelDisplay="auto"
                                        getAriaValueText={valuetext}
                                        marks={sliderTimeMarks}
                                        min={7}
                                        max={19}
                                        step={1}
                                        valueLabelFormat={valueLabelFormat}
                                        disableSwap
                                    />
                                </Box>
                            </Grid>

                            <Grid container direction="row"
                                  className={selectedButtonTabValue in [0, 1] ? classes.gridRowStyle : classes.gridRowStyleHidden}
                                  style={{marginTop: '20px'}}>
                                <Typography style={{'minWidth': '27%', color: 'black'}}>Collector: </Typography>
                                <ReferenceInput source="fromOrganisationUuid" reference="organisations"
                                                defaultValue={preferredCollectorLocationUuid}
                                                filter={{isCollector: 1, isWarehouse: 1}}
                                                sort={{field: 'name', order: 'asc'}}
                                                sortDefaultValues={{field: 'name', order: 'asc'}} label={''}
                                                className={classes.optionTextFields} alwaysOn resettable
                                >
                                    <AutocompleteInput optionText="name" variant="outlined"/>
                                </ReferenceInput>
                            </Grid>

                            <Grid container direction="row"
                                  className={selectedButtonTabValue in [0, 1] ? classes.gridRowStyle : classes.gridRowStyleHidden}>
                                <Typography style={{'minWidth': '27%', color: 'black'}}>Is preferred collector for
                                    location: </Typography>
                                <Checkbox checked={isPreferredCollector}
                                          onChange={() => setIsPreferredCollector(!isPreferredCollector)} size="small"/>
                            </Grid>

                            <Grid container direction="row"
                                  className={selectedButtonTabValue in [0, 1] ? classes.gridRowStyle : classes.gridRowStyleHidden}>
                                <Typography style={{'minWidth': '27%', color: 'black'}}>Location:* </Typography>
                                <Select
                                    value={toLocation}
                                    onChange={(e) => setToLocation(e.target.value)}
                                    label=""
                                    className={classes.optionTextFields}
                                >
                                    { toLocationChoices && toLocationChoices.map((item) => {
                                        return (
                                            <MenuItem value={item.id}>{item.name} <br/> {item.address}
                                            </MenuItem>
                                        );
                                    })}

                                </Select>

                            </Grid>

                            <Grid container direction="row"
                                  className={selectedButtonTabValue in [0, 1] ? classes.gridRowStyle : classes.gridRowStyleHidden}>
                                <Typography style={{'minWidth': '27%', color: 'black'}}>Notify:</Typography>
                                <FormControlLabel control={<Checkbox checked={sendNotificationEnabled}
                                                                     onChange={toggleNotificationEnabled} size="small"/>}
                                                  label="Do no send notification"/>
                            </Grid>

                            <Grid container direction="row"
                                  className={selectedButtonTabValue in [0, 1] ? classes.gridRowStyle : classes.gridRowStyleHidden}>
                                {
                                    notificationChoices && notificationChoices.map((item) => (
                                            <FormControlLabel style={{'marginLeft': '26%', minWidth: '100%'}}
                                                              control={<Checkbox checked={item.isChecked}
                                                                                 onChange={() => handleNotificationChoices(item.id)}
                                                                                 disabled={sendNotificationEnabled}
                                                                                 size="small"/>}
                                                              label={<Typography varient={'subtitle1'}> {item.name} <br/>
                                                                  <Typography style={{'color': 'blue'}}
                                                                              varient={'colorTextSecondary'}>
                                                                      {item.email}
                                                                  </Typography>
                                                              </Typography>}/>
                                        )
                                    )
                                }

                            </Grid>

                        </Grid>
                    </SimpleForm>
                </Box>
            }
        </Container>
    );
};

export default VenuesCollectPage;